import './ConsultHistoryLinesPopup.css'

import { useCallback, useEffect, useState } from "react";
import { useClosePopup, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { EmptyState } from "../../../../../lib/components/emptystates/EmptyState";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { InventoryItemHistory } from "../../../components/InventoryItemHistory/InventoryItemHistory";
import { InventoryItemHistoryDetails } from "../../../models/domain/inventory-item-history/InventoryItemHistoryDetails";
import { InventoryItemHistoryService } from "../../../services/InventoryItemHistoryService";
import { Loader } from "../../../../../lib/components/loader/Loader";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useServiceCallPro2 } from "../../../../../lib/hooks/useServiceCall";

var inventoryItemHistorySvc = new InventoryItemHistoryService();

export interface ConsultHistoryLinesProps {
    locationID: string;
    inventoryID: string;
    inventoryItemID: string;
    onCompletedOperations: () => void;
}

export function ConsultHistoryLinesPopup(props: ConsultHistoryLinesProps){
    const openPopup = usePopup();

    const [inventoryItemHistoryLines, setInventoryItemHistoryLines] = useState<InventoryItemHistoryDetails[]>([]);
    const [totalItems, setTotalItems] = useState<number>();



    /*****************
     * SERVICE CALLS 
    ****************/



    const [getInventoryItemHistoryLinesCall, getInventoryItemHistoryLinesCallIsLoading] = useServiceCallPro2(inventoryItemHistorySvc, inventoryItemHistorySvc.getInventoryItemHistoryLines);



    /****************************
    * DATA REQUESTS
    *****************************/






    const getInventoryItemHistoryLines = useCallback(() => {

        if (!props.locationID || !props.inventoryID || !props.inventoryItemID) return;


        getInventoryItemHistoryLinesCall(props.locationID, props.inventoryID, props.inventoryItemID)
            .then((data) => {
                setInventoryItemHistoryLines(data.historyLines);
                setTotalItems(data.totalItems);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationID, props.inventoryID, props.inventoryItemID, getInventoryItemHistoryLinesCall, setInventoryItemHistoryLines, setTotalItems, openPopup]);





    /****************************
     * DATA MANIPULATION EFFECTS
    *****************************/



    useEffect(() => {
        getInventoryItemHistoryLines();
    }, [props.locationID, props.inventoryID, props.inventoryItemID]);



    /****************************
     * USER ACTIONS
    *****************************/







    /****************************
     * CSS & HTML
    *****************************/

    return (<PopupContainer className="popup-history-lines">
        <PopupHeader title={translate("INVENTORY.POPUP.History")} />
        <PopupContent>
            {getInventoryItemHistoryLinesCallIsLoading
                ? <Loader />
                : inventoryItemHistoryLines && inventoryItemHistoryLines.length > 0
                    ? <InventoryItemHistory locationId={props.locationID} inventoryID={props.inventoryID} inventoryItemID={props.inventoryItemID} historyLines={inventoryItemHistoryLines}></InventoryItemHistory>
                    : <div className="table-empty-loader-container">
                        <EmptyState hideSubtitle title={translate("INVENTORY.POPUP.History")} subtitleMessage={translate("INVENTORY.POPUP.NoHistoryLines")} />
                    </div>}
        </PopupContent>
    </PopupContainer>)
}
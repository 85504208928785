export enum LocationGroupType {
    ACCESS = 1,
    ADMIN = 2,
    FINANCE = 3,
    TEAM_LEADER = 4,

}


export const LocationGroupTypeTranslationTerms: { [key: number]: string } = {
    1: "INVENTORY.ROLES.BaseAccess",
    2: "INVENTORY.ROLES.Admin",
    3: "INVENTORY.ROLES.Finances",
    4: "INVENTORY.ROLES.TeamLeader",
}

import "./PdaSimulatorPage.css";

import { useCallback, useState } from 'react';

import { Button } from "../../../../lib/components/buttons/Button";
import { CardContainer } from '../../../../lib/layouts/containers/card/CardContainer';
import { ConditionalRender } from '../../../../lib/functional/ConditionalRender';
import { CreateInventoryItemPopup } from './PdaSimulatorCreateMaterialPopup';
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../../lib/components/form/section/FormSection";
import { InfoBlock } from '../../../../lib/components/info-block/InfoBlock';
import { InventoryItemDetailsDTO } from '../../models/dtos/InventoryItemDTOs/InventoryItemDetailsDTO';
import { InventoryItemDetailsHelper } from '../../models/domain/inventory-items/InventoryItemDetails';
import { InventoryItemStatusTranslationTerms } from '../../models/domain/inventory-items/InventoryItemStatusEnum';
import { InventoryItemSummaryDTO } from "../../models/dtos/InventoryItemDTOs/InventoryItemSummaryDTO";
import { PdaSimulatorQuantityStep } from './PdaSimulatorQuantityStep';
import { Spacer } from '../../../../lib/components/separator/Spacer';
import { Tag } from '../../../../lib/components/tag/Tag';
import { getInventoryItemStatusCss } from '../../../../common/helpers/GetInventoryItemStatusCss';
import { translate } from '../../../../lib/infrastructure/i18n/InternationalizationService';
import { useFormControl } from "../../../../lib/components/form/Form";
import { usePopup } from '../../../../lib/infrastructure/ui/UIServices';
import { validateEmptyStrings } from '../../../../lib/validators/ValidateEmptyStrings';

interface IPdaSimulatorStep01Props {

    locationId?: string;
    inventoryId?: string;
    storageCode?: string;
    items?: InventoryItemSummaryDTO[];
    onCountSuccess: () => void;
    onCreateMaterialSuccess: () => void;

}

function verifyString(s1?: string, s2?: string) {
    const a = s1?.trim().toLowerCase() || "";
    const b = s2?.trim().toLowerCase() || "";
    return a === b;
}


export function PdaSimulatorMaterialStep(props: IPdaSimulatorStep01Props) {

    const { locationId, inventoryId, storageCode, items, onCountSuccess, onCreateMaterialSuccess } = props;

    const openPopup = usePopup();

    const materialNumFormControl = useFormControl<string>({
        validators: [validateEmptyStrings()],
    });
    const batchFormControl = useFormControl<string>({});
    const binFormControl = useFormControl<string>({});


    const [selectedItem, setSelectedItem] = useState<InventoryItemSummaryDTO>();
    const [errorMessage, setErrorMessage] = useState<string>();

    const [showCreateMaterial, setShowCreateMaterial] = useState(false);

    const handleIdentifyMaterial = useCallback(() => {
        const res = items?.filter(i => verifyString(i.materialNumber, materialNumFormControl.value)
            && verifyString(i.batch, batchFormControl.value)
            && verifyString(i.bin, binFormControl.value));

        setErrorMessage(undefined);
        setShowCreateMaterial(false);
        if (res && res.length && res.length < 2) {
            setSelectedItem(res.at(0));
        } else if (res && res.length > 1) {
            setSelectedItem(undefined);


            const hasSpecial = res.filter(s => s.specialSAP);
            if(hasSpecial.length){
                setSelectedItem(res.at(0));
            } else {
                const firstIdType = res.sort((a,b)  => (a.typeSAP || 0) - (b.typeSAP || 0))
                if(firstIdType.length){
                    setSelectedItem(firstIdType.at(0));
                }else {
                    setErrorMessage("Mais do que um item encontrado. Cenário não previsto, necessita análise");
                }

            }

            //
        } else {
            setSelectedItem(undefined);
            setShowCreateMaterial(true);
            setErrorMessage("Nenhum item encontrado, criar novo?");
        }
    }, [materialNumFormControl.value, batchFormControl.value, binFormControl.value, items, setErrorMessage]);


    const handleCreateMaterial = useCallback(() => {

        if (!locationId || !inventoryId || !materialNumFormControl.value || !props.storageCode) return;

        const materialNumber = materialNumFormControl.value;
        const materialDescription = materialNumFormControl.value; //BUSCAR TODOS OS ITEMS PARA RESOLVER O NOME

        openPopup(<CreateInventoryItemPopup
            locationID={locationId}
            inventoryID={inventoryId}
            materialNumber={materialNumber}
            materialDesc={materialDescription}
            storageCode={props.storageCode}
            batch={batchFormControl.value}
            bin={binFormControl.value}
            onCompletedOperations={(res: InventoryItemDetailsDTO) => {
                setShowCreateMaterial(false);
                setErrorMessage(undefined);
                setSelectedItem({
                    id: res.id,
                    materialNumber: res.materialNumber,
                    materialDescription: res.materialDescription,
                    batch: res.batch || "",
                    storage: res.storage,
                    storageName: null, //TODO: check if is not fucking something
                    bin: res.bin || "",
                    units: res.units,
                    quantitySAP: res.quantitySAP,
                    quantityCounted: res.quantityCounted,
                    status: res.state,
                    isNew: res.isNew,
                    isProblematic: false,
                });
                onCreateMaterialSuccess();

            }} />)

    }, [locationId, inventoryId, materialNumFormControl.value, props.storageCode, batchFormControl.value, binFormControl.value]);

    return (
        <div>
            <FormFieldTextInput formControl={materialNumFormControl} label="Material Number" />
            <Spacer mode="vertical" px={16} />
            <FormFieldTextInput formControl={batchFormControl} label="Batch" />
            <Spacer mode="vertical" px={16} />
            <FormFieldTextInput formControl={binFormControl} label="Bin" />
            <Spacer mode="vertical" px={30} />

            <div className="simulator-actions">
                <Button text="Identificar" type="secondary" onClick={handleIdentifyMaterial} />

                <ConditionalRender if={showCreateMaterial} >
                    <Button text="Criar Novo Item" type="primary" onClick={handleCreateMaterial} />
                </ConditionalRender>
            </div>
            <Spacer mode="vertical" px={16} />
            <div className="error-message">{errorMessage}</div>

            <Spacer mode="vertical" px={20} />

            <ConditionalRender if={!!selectedItem} >
                <div className="item-info">
                    <CardContainer className="item-data">
                        <div className="item-details-header">
                            <div className="title">{translate("INVENTORY.MaterialData")}</div>
                        </div>
                        <Spacer mode="vertical" px="16" />
                        <div className="item-data-content-info">
                            <FormSection unsetFlexGrow>
                                <InfoBlock
                                    label={translate("INVENTORY.ITEM.INFOANDFORM.Material")}
                                    value={selectedItem?.materialNumber ? selectedItem?.materialNumber : "-"}
                                />
                                <InfoBlock
                                    label={translate("INVENTORY.ITEM.INFOANDFORM.Description")}
                                    value={selectedItem?.materialDescription ? selectedItem.materialDescription : "-"}
                                />
                                <InfoBlock
                                    label={translate("INVENTORY.ITEM.INFOANDFORM.Batch")}
                                    value={selectedItem?.batch ? selectedItem.batch : "-"}
                                />

                                <InfoBlock
                                    label={translate("INVENTORY.ITEM.INFOANDFORM.Storage")}
                                    value={selectedItem?.storage ? selectedItem.storage + (selectedItem.bin ? " - " + selectedItem.bin : "") : "-"}
                                />
                                <InfoBlock
                                    label={translate("INVENTORY.ITEM.INFOANDFORM.Unit")}
                                    value={selectedItem?.units ? selectedItem.units : "-"}
                                />
                            </FormSection>
                        </div>
                    </CardContainer>
                </div>
                <Spacer mode="vertical" px={20} />
                <div className="subtitle">Contar Item</div>
                <Spacer mode="vertical" px={16} />
                <PdaSimulatorQuantityStep locationId={locationId} inventoryId={inventoryId} storageCode={storageCode} item={selectedItem} onCountSuccess={onCountSuccess} />
            </ConditionalRender>
        </div>
    )
}

import { useCallback, useEffect } from "react";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";
import { FormContainer } from "../../../../../lib/layouts/containers/form/FormContainer";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { validateEmptyStrings } from "../../../../../lib/validators/ValidateEmptyStrings";
import { InventoryItemDetails } from "../../../models/domain/inventory-items/InventoryItemDetails";
import { InventoryItemsService } from "../../../services/InventoryItemsService";
import { useServiceCallPro } from "../../../../../services/UseServiceCall";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
var svc = new InventoryItemsService();

export interface EditInventoryDocumentItemIDProps {
    locationID: string;
    inventoryID: string;
    inventoryItemID: string;
    onCompletedOperations: () => void;
}

export function EditInventoryDocumentItemIDPopup(props: EditInventoryDocumentItemIDProps) {
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();

    const getInventoryItemCall = useServiceCallPro(svc.getInventoryItemById);
    const putDocumentSapIdCall = useServiceCallPro(svc.updateInventoryItem);

    const documentItemSapIdFormControl = useFormControl<string>({
        validators: [validateEmptyStrings()],
        enableAutoValidate: true,
    });


    useEffect(() => {
        if (!props.locationID || !props.inventoryID || !props.inventoryItemID) return;

        getInventoryItemCall.invoke(props.locationID, props.inventoryID, props.inventoryItemID)
            .then((r) => documentItemSapIdFormControl.setValue(r.sapInventoryDocId ?? undefined))
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, []);



    const handleEditBtnClicked = useCallback(() => {

        if (!documentItemSapIdFormControl.validate() || !props.inventoryItemID) return;

        putDocumentSapIdCall.invoke(props.locationID, props.inventoryID, `${props.inventoryItemID}`, { sAPInventoryDocId: documentItemSapIdFormControl.value ?? "-" })
            .then(() => {
                props.onCompletedOperations();
                closePopup();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("INVENTORY.POPUP.DocumentSapIdSuccessMessage")}
                    ></Notification>
                );
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [documentItemSapIdFormControl.validate, putDocumentSapIdCall, props.onCompletedOperations, openPopup,
        createNotification, closePopup, props.inventoryItemID,
    props.locationID, props.inventoryID, documentItemSapIdFormControl.value]);



    return (
        <PopupContainer className="popup-edit-sapDocumentId">
            {putDocumentSapIdCall.isLoading ? <FullScreenLoader /> : null}
            <PopupHeader
                title={translate("INVENTORY.POPUP.EditDocumentSapId")}
            />
            <PopupContent isLoading={getInventoryItemCall.isLoading}>
                <FormContainer>
                    <FormFieldTextInput
                        formControl={documentItemSapIdFormControl}
                        label={translate("INVENTORY.POPUP.DocumentSapId")}
                        placeholder={translate("INVENTORY.POPUP.DocumentSapIdPlaceholder")}
                    />
                </FormContainer>
                <Spacer mode={"vertical"} px="30"></Spacer>
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Edit"),
                            type: "primary",
                            onClick: handleEditBtnClicked
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
import { useNavigate, useParams } from "react-router-dom";
import { useClosePopup, useCreateNotification, usePopup } from "../../../lib/infrastructure/ui/UIServices";
import "./StorageManagerDetailsPage.css";
import { useUpdatePageData } from "../../../lib/infrastructure/navigation/hooks/useUpdatePageData";
import { StorageManagersService } from "./services/StorageManagersService";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useServiceCallPro2 } from "../../../lib/hooks/useServiceCall";
import { StorageManagerSummary } from "./models/StorageManagerSummary";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { InfoBlock } from "../../../lib/components/info-block/InfoBlock";
import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { StorageManagerStorageSummary } from "./models/StorageManagerStorageSummary";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { TitledCardContainer } from "../../../lib/layouts/containers/card/TitledCardContainer";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { Button } from "../../../lib/components/buttons/Button";
import { EditStorageManagerStoragesPopup } from "./popup/EditStorageManagerStoragesPopup";
import { ScalableIconButton } from "../../../lib/components/buttons/ScalableIconButton";
import { WarningPopup } from "../../../lib/components/popup/WarningPopup";

import { ReactComponent as DeleteIcon } from "../../../lib/assets/icons/remover.svg";
import { ConditionalRender } from "../../../lib/functional/ConditionalRender";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { Notification } from "../../../lib/components/notifications/Notification";

var svc = new StorageManagersService();

export function StorageManagerDetailsPage() {

    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const navigate = useNavigate();
    const createNotification = useCreateNotification();
    const { id: locationID, email } = useParams();
    const updateRouteData = useUpdatePageData();


    const [getStorageManagerCall, getStorageManagerCallIsLoading] = useServiceCallPro2(svc, svc.getStorageManagerByEmail);
    const [deleteStorageManagerStorageCall, deleteStorageManagerStorageCallIsLoading] = useServiceCallPro2(svc, svc.deleteStorageManagerStorage);

    const [storageManagerInfo, setStorageManagerInfo] = useState<StorageManagerSummary>();


    useEffect(() => {
        getStorageManagerSummary();
    }, [locationID, email]);





    const getStorageManagerSummary = useCallback(() => {

        if (!locationID || !email) return;

        getStorageManagerCall(locationID, email)
            .then((data) => {
                if (data) {
                    setStorageManagerInfo(data);
                    /* updateRouteData(["##STORAGE##", data.locSap && data.description ? data.locSap + " <" + data.description + ">" : data.locSap ?? "-"]); */
                }
            })
            .catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationID, email, setStorageManagerInfo, openPopup, updateRouteData, getStorageManagerCall]);







    const renderCardContainerInfo = useMemo(() => {
        return <>
            <InfoBlock
                label={"E-mail"}
                value={storageManagerInfo?.email || "-"}
            />
            <InfoBlock
                label={translate("GLOBALADMIN.STORAGES.Info.Name")}
                value={storageManagerInfo?.name || "-"} />
        </>
    }, [storageManagerInfo]);





    const handleDeleteStorageClicked = useCallback((storageCode: string) => {

        if (!locationID || !email) return;

        deleteStorageManagerStorageCall(locationID, `${email}`, storageCode)
            .then((_) => {
                closePopup();
                getStorageManagerSummary(); 
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("GLOBALADMIN.STORAGES.Messages.DeleteStorageSuccess")}
                    ></Notification>
                );
            })
            .catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationID, email, closePopup, getStorageManagerSummary, createNotification, openPopup, deleteStorageManagerStorageCall]);



    const columns: ColumnDefinition<StorageManagerStorageSummary>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => v.storageCode,
                width: "15%",
                headerRender: (<>{translate("INVENTORY.STORAGE.Description")}</>),
                columnKey: "description",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => v.storageName,
                width: "30%",
                headerRender: (<>{translate("GLOBALADMIN.STORAGES.Info.Name")}</>),
                columnKey: "description",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => {
                    return <>{v.bins?.map(bin => `${bin.code}`).join("   -   ")}</>
                },
                width: "30%",
                headerRender: (<>{translate("INVENTORY.ITEM.INFOANDFORM.BinPlural")}</>),
                columnKey: "bin",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => (
                    <ScalableIconButton
                        icon={<DeleteIcon />}
                        onClick={() => {
                            if(!v.storageCode) return; 
                            openPopup(
                                <WarningPopup
                                    className="button-red"
                                    onDismissClicked={() => { closePopup(); }}
                                    onConfirmClicked={() => { handleDeleteStorageClicked(v.storageCode); }}
                                    dismissText={translate("COMMON.Cancel")}
                                    confirmText={translate("COMMON.Remove")}
                                >
                                    {translate("GLOBALADMIN.STORAGEMANAGERS.Messages.RemoveStorageConfirmText", v?.storageCode + " - " + v?.storageName, storageManagerInfo?.name ?? "-")}
                                </WarningPopup>
                            );
                        }}
                        size={30}
                    />
                ),
                width: "3%",
                columnKey: "stats",
                isSortable: false,
            },

        ], [openPopup, closePopup, handleDeleteStorageClicked, storageManagerInfo?.name]);





    return (
        <PageLayout
            tabTitle={`${translate("INVENTORY.Inventory")}  + ${translate("GLOBALADMIN.STORAGEMANAGERS.StorageManagers")}`}
            pageTitle={translate("GLOBALADMIN.STORAGEMANAGERS.StorageLocationsOf", storageManagerInfo?.name ?? (email ? email : "-"))}
            className="storage-manager-details-page"
            showBreadcrumb
            actions={<>
                <Button text={translate("GLOBALADMIN.STORAGEMANAGERS.EditStorages")} type="secondary" onClick={() => {
                    if (!locationID) return;
                    openPopup(<EditStorageManagerStoragesPopup
                        locationID={"" + locationID} managerEmail={"" + email} onCompletedOperations={getStorageManagerSummary} />)
                }
                }
                ></Button>
            </>}
        >

            <ConditionalRender if={deleteStorageManagerStorageCallIsLoading} >
                <FullScreenLoader />
            </ConditionalRender>

            <div className="manager-data-info">
                <TitledCardContainer
                    title={translate("GLOBALADMIN.STORAGEMANAGERS.ManagerData")}
                    className="manager-info-container"
                    isLoading={getStorageManagerCallIsLoading} >
                    <Spacer mode="vertical" px="16" />
                    <div className="manager-data-content-info">
                        {renderCardContainerInfo}
                    </div>
                </TitledCardContainer >
            </div>

            <Spacer mode="vertical" px="20" />
            <div className="subtitle">{translate("GLOBALADMIN.STORAGES.Storages")}</div>
            <Spacer mode="vertical" px="12" />

            <ResponsiveDataTable
                totalitems={storageManagerInfo?.storages?.length || 0}
                columnDefinitions={columns}
                items={storageManagerInfo?.storages || []}
                isLoading={getStorageManagerCallIsLoading}


            ></ResponsiveDataTable>
        </PageLayout >
    );
}

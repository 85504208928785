import { ILanguage } from "./ILanguage";

export const languageEN: ILanguage = {
  COMMON: {
    ProjectName: "Inventory",
    Cancel: "Cancel",
    Create: "Create",
    Add: "Add",
    Activate: "Activate",
    Import: "Import",
    Change: "Change",
    Suspend: "Suspend",
    Save: "Save",
    Edit: "Edit",
    Remove: "Remove",
    Delete: "Delete",
    Deactivate: "Deactivate",
    Logout: "Logout",
    Optional: "Optional",
    Reset: "Reset",
    Set: "Set",
    Registar: "Create",
    PrepositionForDateTime: " on ",
    CreatedOn: "Registered on",
    All: "All",
    Clear: "Clear",
    Deliver: "Deliver",
    OtherActions: "Other actions",
    CloneAction: "Clone",
    ViewAll: "View All",
    Update: "Update",
    Close: "Close",
    Back: "Back",
    TestEnvironment: "Test Environment",

    FORM: {
      SelectManyOptions: "Select one or more options",
      Search: "Search",
      SearchPlaceholder: "Search",
      InitialDate: "Initial Date",
      InitialDatePlaceholder: "Initial Date",
      InitialCreationDatePlaceholder: "Initial Creation Date",
      FinalDate: "Final Date",
      FinalDatePlaceholder: "Final Date",
      FinalCreationDatePlaceholder: "Final Creation Date",
      RequestDatePlaceholder: "Select a date",
      DefaultPlaceholder: "Type Here",
      SelectDefaultPlaceholder: "Type to search",
      SelectStatusPlaceholder: "Select status",
      Filters: "Filters",
      CleanFilters: "Reset Filters",
      ClearSelection: "Clear selection",
      Sunday: "Su",
      Monday: "Mo",
      Tuesday: "Tu",
      Wednesday: "We",
      Thursday: "Th",
      Friday: "Fr",
      Saturday: "Sa",


      VALIDATIONS: {
        CannotBeEmpty: "This field cannot be empty.",
        RequiredField: "This field is required.",
        GuidValidator: "Invalid GUID format.",
        SelectOneOption: "Select an option.",
        BatchFormatError: "Must contain the following format: ####### or #######/# or #######/##",
        FillMandatoryFields: "Please fill the mandatory fields",
        InvalidLocationId: "Location ID is invalid.",
        PositiveNumber: "Insert an integer number",
        SelectDate: "Please select a date",
        PositiveNumberOrDecimal: "Type an integer number or decimal.",
        OnlyPositiveNumberOrDecimal: "Type an positive integer number or decimal.",
      }
    },

    ERRORPAGES: {
      SomethingWrong: "Something Went Wrong",
      BackToHome: "Back to Home",
      AccessDenied: "Access Denied",
      AccessDeniedText1: "You do not have permission to view this page!",
      AccessDeniedText2: "Please check your credentials and try again.",
      PageNotFound: "Page Not Found",
      PageNotFoundText: "We can't seem to find the page you are looking for.",
      SessionExpired: "Session Expired",
      SessionExpiredText: "Your session has expired due to your inactivity.",
      Login: "Login",
    },

    SYSTEMPOPUPS: {
      Error: "Error!",
      ErrorText: "",
      Success: "Success!",
      SuccessText: "",
      Warning: "Warning",
      WarningText: "",
    },

    POPUPS: {
      ImportOptions: "Import Options",
      ExcelTemplate: "Excel Template",
      ExcelTemplateText: "Click the button below to download an Excel template file. This template contains the correct structure for data import.",
      Import: "Import",
      ImportText: "Click the button below to select an Excel file and start the import process. Make sure you select the correct file.",
      ImportSapStockText: "Click the button below to select an Excel with the exported SAP stock Make sure you select the correct file.",
      UpdateItemsText: "Through exporting the Excel file, does this import serve to update the columns of manually and bulk imported states (Item state and SAP Document ID)"

    },

    TABLE: {
      NoResults: "No results found",
      NoResultsText: "We couldn't find what you're looking for, please search again",
      RowsPerPage: "Rows per page",
      Of: "of",
    }
  },

  INVENTORY: {

    ROLES: {
      Admin: "Administrator",
      BasicAccess: "Basic Access",
      Finances: "Finance",
      TeamLeader: "Team Leader"
    },

    Inventory: "Inventory",
    InventoryPlural: "Inventories",
    NewInventory: "New inventory",
    InventoryData: "Inventory Data",
    Storage: "Storage",
    StoragePlural: "Storages",
    StorageData: "Storage Data",
    Status: "Status",
    MaterialData: "Inventory Item Data",
    Export: "Export",
    Import: "Import",
    SearchPlaceholder: "Search",
    Name: "Name",
    NamePlaceholder: "Inventory Name",
    Type: "Type",
    InitialDate: "Initial Date",
    FinalDate: "Final Date",
    SummaryStorage: "Storage Summary",
    ReopenInventory: "Reopen",
    CloseInventory: "Close",
    AtivateInventory: "Ativate",
    CancelInventory: "Cancel",
    PDASimulator: "Counting Simulator",
    ExportAccesses: "Export Access Cards",
    Batch: "Batch",
    Description: "Description",
    Material: "Material",

    STATUS: {
      Unknown: "Unknown",
      Counting: "Counting",
      Validated: "Validated",
      Closed: "Closed",
      OnGoing: "Ongoing",
      ConfirmedPlural: "Confirmed",
      Registered: "Registered",
      Canceled: "Canceled",
    },
    POPUP: {
      DocumentSapId: "Document SAP ID",
      DocumentSapIdPlaceholder: "Ex: 100000001",
      DocumentSapIdSuccessMessage: "Document SAP ID Edited",
      EditDocumentSapId: "Edit SAP Document ID",
      RemoveInventory: "Remove Inventory",
      RemoveInventoryConfirmText: "Are you sure you pretend to remove '{0}'?",
      RemoveInventorySuccessMessage: "Inventory removed.",
      CloseInventoryConfirmMessage: "Are you sure you pretend to close '{0}'?",
      ReopenInventoryConfirmMessage: "Are you sure you pretend to reopen '{0}'?",
      AtivateInventoryConfirmMessage: "Are you sure you pretend to ativate '{0}'?",
      CancelInventory: "Cancel Inventory",
      CancelInventoryConfirmMessage: "Are you sure you pretend to cancel '{0}'?",
      History: "History",
      NoHistoryLines: "There are no history records yet for this inventory item.",
      InvalidItemsCount: "{0} inventory items were not added due to invalid properties (e.g. non-existent material, invalid quantities)."
    },

    STORAGE: {
      StorageLocations: "Storage Locations",
      CreateItem: "Create Item",
      InventoryItems: "Inventory Items",
      AutoValidate: "Auto Validate",
      AutoClose: "Auto Close",
      Description: "Description",

      STATUS: {
        Closed: "Closed",
        ClosedPlural: "Closed",
        OnGoing: "Ongoing",
        Registered: "Registered",
      },
      INFOANDFORM: {
        ValidatedCumulative: "Validated (Cumulative)",
        NotStarted: "Not Started",
        Started: "Started",
        TotalClosed: "Total Closed",
        TotalInCounting: "Total In Counting",
        TotalItems: "Total Items",
        TotalReadyToBeValidated: "Total To Be Validated",
        TotalValidated: "Total Validated",
        SAPLoc: "SAP Location",
        NoOfMaterials: "Nº. Materials",
        Released: "Released",
        Blocked: "Blocked",
        Closed: "Closed",
        Status: "Status",
      }
    },

    DEVIATION: {
      SetDeviation: "Set Deviation",
      ViewDeviation: "Consult Deviation",
      SetInventoryDeviation: "Set Inventory Deviation",
      EditInventoryDeviation: "Edit Inventory Deviation",
      SetDeviationSuccessMessage: "Deviation Definition Saved",
      INFOANDFORM: {

        MonetaryDeviationIntervalErrorMessage: "Maximum monetary value needs to be superior than minimum monetary value",
        QtdDeviationIntervalErrorMessage: "Maximum quantity difference value needs to be superior than minimum quantity value",

        Quantity: "Qty",
        Monetary: "Value",
        PercentageOfMinimumQuantityDifference: "Percentage Of Minimum Quantity Difference (%)",
        PercentageOfMinimumQuantityDifferencePlaceholder: "Ex: 10",
        PercentageOfMaximumQuantityDifference: "Percentage Of Maximum Quantity Difference (%)",
        PercentageOfMaximumQuantityDifferencePlaceholder: "Ex: 15",
        MinimumMonetaryDifference: "Minimum Monetary Difference (€)",
        MinimumMonetaryDifferencePlacholder: "Ex: 200",
        MaximunMonetaryDifference: "Maximum Monetary Difference (€)",
        MaximunMonetaryDifferencePlaceholder: "Ex: 350"
      }
    },


    ITEMCOUNTS: {
      ItemCountMovedSuccessMessage: "Item Count moved",
      SelectTarget: "Select the desired Inventory Item Target to move",
      InventoryItemTarget: "Possible Inventory Item Targets",
      ItemCount: "Inventory Item Count",
      MoveCount: "Move Count",
      Move: "Move",
      RegistedDate: "Registered Date"
    },


    ITEM: {
      NewItems: "New Items",
      CreateInventoryItem: "Create Inventory Item",
      InventoryItemCounts: "Inventory Item Counts",
      TotalItems: "Total Items",
      CreateItem: "Create Item",
      InventoryItem: "Inventory Item",
      InventoryItemsPlural: "Inventory Items",
      Item: "Item",
      ImportItems: "Import Items",
      UpdateItems: "Update Items",
      ImportLoading: "Imported items are being loaded. This might take a while.",
      ValidateCounts: "Validate Counts",
      ValidateCountsWarningText: "You are about to validate an Item that deviates, please provide a justification in the field below.",
      ValidateCountsZeroQtyWarningText: "You are about to validate an Item where counted quantity is zero (0) , please provide a justification in the field below.",
      AddComment: "Add Comment",
      ReopenCounts: "Reopen Counts",
      CloseItem: "Close Item",
      Remove: "Remove",
      Reopen: "Reopen",
      Validate: "Validate",
      Close: "Close",
      ItemWithComments: "Comments have been added",

      TYPESAP: {
        StockBlocked: "Blocked Stock",
        StockFree: "Free Stock",
        StockQuality: "Quality Stock",
      },

      MESSAGES: {
        InexistingStorageBin: "Inexisting Storage Bin",
        InexistingStorage: "No Storage with this code was found",
        InexistingMaterial: "No material with this code was found.",
        CloseItemSuccessMessage: "Inventory Item closed",
        CloseItemConfirmText: "Are you sure you pretend to close this Inventory Item?",
        ValidateItemSuccessMessage: "Inventory Item validated",
        ValidateItemConfirmText: "Are you sure you pretend to validate this Inventory Item?",
        AutoValidateItemsConfirmText: "Are you about to auto-validate the inventory items in this warehouse? Do you want to continue?",
        AutoValidateItemsSuccessMessage: "Validated Items",
        ReopenItemSuccessMessage: "Inventory Item reopened",
        ReopenItemConfirmText: "Are you sure you pretend to reopen this Inventory Item?",
        RemoveItemCountConfirmText: "Are you sure you pretend to remove this Item Count?",
        RemoveItemCountSuccessMessage: "Item Count Removed",
        AddCommentCountSuccessMessage: "Comment added.",
        ImportItemsSuccess: "Items successfully imported!",
        AtivatePeriodSucess: "Period activated",
        WarningMoveItems: "Attention: it's not possible to move counts to items that are Validated or Closed"
      },

      STATUS: {
        Counting: "Counting",
        Unknown: "Unknown",
        Validated: "Validated",
        ValidatedPlural: "Validated",
        Closed: "Closed",
        ClosedPlural: "Closed",
        OnGoing: "Ongoing Count",
        Valid: "Validated Count",
        ValidPlural: "Validated Counts",
        Problematic: "Problematic",
        ReadyToBeValidated: "Ready to be Validated",
        Registered: "Not started",
      },

      INFOANDFORM: {
        Attributes: "Attributes",
        MonetaryValue: "Value (€)",
        SapValue: "SAP Value (€)",
        Problematic: "Problematic",
        MonetaryDeviationPercentage: "Monetary Deviation (%)",
        QuantityDeviationPercentage: "Quantity Deviation (%)",
        MonetaryDeviation: "Monetary Deviation",
        QuantityDeviation: "Quantity Deviation",
        TypeSAP: "Type SAP",
        Counts: "Counts",
        Rack: "Rack",
        Shelf: "Shelf",
        Operator: "Operator",
        SapInventoryDocId: "SAP Inventory Document ID",
        SapInventoryDocIdPlaceholder: "Type SAP Inventory Document ID",
        MaterialDescription: "Material description",
        MaterialDescriptionPlaceholder: "Type material description",
        MaterialNumber: "Material Number",
        MaterialNumberPlaceholder: "Type material number",
        Storage: "Storage",
        StoragePlaceholder: "Type storage",
        Description: "Description",
        Material: "Material",
        Batch: "Batch",
        BatchPlaceholder: "Type batch",
        SeeComments: "Consult comments",
        StorageBin: "Storage Bin",
        StorageBinPlural: "Storage Bins",
        Bin: "Bin",
        BinPlural: "Bins",
        BinPlaceholder: "Type bin",
        Explanation: "Explanation",
        Comments: "Comments",
        CommentsPlaceholder: "Type your comments",
        LastComment: "Last Comment",
        ExplantionComments: "Explanation/Comments",
        Unit: "Unit",
        UnitPlaceholder: "Type Unit",
        Type: "Type",
        Quantity: "Quantity",
        QuantityPlaceholder: "Type quantity",
        CountedQuantity: "Counted Qty.",
        SAPQuantity: "SAP Qty.",
        SAPQuantityExtended: "SAP Quantity",
        SAPQuantityPlaceholder: "Type SAP quantity",
        Deviation: "Deviation",
        ItemCounts: "Item Counts",
        Status: "Status",
        ItemCountsStatus: "Item Counts/Status",
        Justification: "Justification",
        JustificationPlaceholder: "Type a justification for this decision",
        SingularComment: "Comment",
        SingularCommentPlaceholder: "Type your comment",
        SapTypeId: "ID Type",
        SpecialSap: "Special SAP",
        UnitPriceMaterialShort: "U.P. Material (€)",
        Length: "Length",
        LengthPlaceholder: "Type length",
        CalcQuantityM2: "Calculate quantity (m2)",
        LengthShort: "L",
        Width: "Width",
        WidthPlaceholder: "Type width",
        WidthShort: "W",
        Measures: "Measures"
      },
      POPUP: {
        EditQuantity: "Edit Quantity",
        EditQuantitySuccessMessage: "Quantity Edited",
      }
    }
  },

  GLOBALADMIN: {
    GlobalAdmin: "Global Administration",

    STORAGEMANAGERS: {
      StorageManagerStoragesEdited: "Manager Storages updated.",
      EditStorages: "Edit Storages",
      EditManagersStorage: "Edit Manager Storages",
      ManagerData: "Manager Data",
      StorageLocationsOf: "Storage Locations of {0}",
      DeleteManagerConfirmText: "Are you sure you pretend to remove this Storage Manager '{0}'?",
      DeleteManagerSuccess: "Storage Manager removed.",
      SelectOneStorageWarningMessage: "Select at least one Storage Location",
      AddStorageManager: "Add Storage Manager",
      StorageManagers: "Storage Managers",
      ImportLoading: "Imported managers are being loaded. This might take a while.",
      ImportStorageManagersSuccess: "Managers successfully imported!",
      ImportStorageManagerSingleSuccess: "Manager added!",

    Messages:{
      RemoveStorageConfirmText: "Are you sure you pretend to remove the storage '{0}' to Manager '{1}'?"
    } 

    },

    STORAGES: {
      Storages: "Storage Locations",
      ImportStorageBins: "Import Storage Bins",
      ImportStorages: "Import Storages",
      AddStorageLocation: "Add Storage Location",
      EditStorageLocation: "Edit Storage Locations",
      ImportStoragesLoading: "Imported storages are being loaded. This might take a while.",
      ImportStorageBinsLoading: "Imported storage bins are being loaded. This might take a while.",


      BINS: {
        AddBin: "Add Storage Bin",
        AddBinShort: "Add Bin",
        BinPlaceholder: "Type Bin name",

        Messages:{
          AddBinSuccess: "Storage Bin added",
        }
      },

      Messages: {
        EditStorageSuccess: "Storage Location Edited.",
        AddStorageSuccess: "Storage Location Added.",
        ImportStoragesSuccess: "Storages successfully imported!",
        ImportBinsSuccessfully: "Storage bins successfully imported!",
        DeleteStorageConfirmText: "Are you sure you pretend to remove this Storage Location '{0}' - '{1}'?",
        DeleteStorageSuccess: "Storage Location Removed.",
        EnableStorageSuccess: "Storage Location Enabled.",
        DisableStorageSuccess: "Storage Location Disabled.",

        RemoveBinConfirmText: "Are you sure you pretend to remove the Bin '{0} from the Storage Location '{1} - {2}'?",
        RemoveBinSuccess: "Bin Removed"
      },

      Info: {
        Name: "Name",
        NamePlaceholder: "Type Storage name",
        Code: "SAP Location",
      },

      POPUP: {
        Activate: "Activate",
        ActivateConfirmText: "Are you sure you want to activate the storage?",
        ActivateStorageSuccess: "Storage activated",
        ActivateStorageError: "Error activating Storage!",
        CreateStorageSuccess: "Storage created!",
        EditStorage: "Edit Storage",
        EditStorageError: "Error editing storage!",
        EditStorageSuccess: "Storage edited!",
        DeactivateStorageError: "Error deactivating storage!",
        Deactivate: "Disable",
        DeactivateConfirmText: "Are you sure you want to disable the storage?",
        DeactivateStorageSuccess: "Storage deactivated!"
      },
    },

    LOCATIONS: {
      Locations: "Locations",
      CreateLocation: "Create Location",
      AccessGroups: "Access Groups",

      INFO: {
        Name: "Name",
        NumGroups: "No. Groups",
        Type: "Type",
      },

      POPUP: {
        Activate: "Activate",
        ActivateConfirmText: "Are you sure you want to activate the location?",
        ActivateLocationSuccess: "Location activated",
        ActivateLocationError: "Error activating location!",
        CreateLocationSuccess: "Location created!",
        EditLocation: "Edit Location",
        EditLocationError: "Error editing location!",
        EditLocationSuccess: "Location edited!",
        DeactivateLocationError: "Error deactivating location!",
        Deactivate: "Disable",
        DeactivateConfirmText: "Are you sure you want to disable the location?",
        DeactivateLocationSuccess: "Location deactivated!"
      },

      ACCESSGROUPS: {
        Type: "Type",
        Name: "Name",
      },
    },

    FACILITIES: {
      CreateFacility: "Create Facility",
      Facilities: "Facilities",
      INFOANDFORM: {
        Name: "Name",
      },
      POPUP: {
        ActivateFacilityConfirmText: "Are you sure you want to activate the facility?",
        ActivateFacilityError: "Error activating facility!",
        ActivateFacilitySuccess: "Facility activated!",
        CreateFacilitySuccess: "Facility created!",
        DeactivateFacilityConfirmText: "Are you sure you want to disable the facility?",
        DeactivateFacilityError: "Error deactivating facility!",
        DeactivateFacilitySuccess: "Facility deactivated!",
        Deactivate: "Disable",
        EditFacilityError: "Error while updating facility!",
        EditFacility: "Edit Facility",
        EditFacilitySuccess: "Facility edited!",
        LoadingFacilityError: "Error while loading facility information."
      }
    },

    OPERATORS: {
      PdaOperatorInfo: "PDA Operator information",
      PdaOperators: "PDA Operators",
      ImportLoading: "Imported operators are being loaded. This might take a while.",
      ImportPdaOperatorsSuccess: "Operators successfully imported!",
      AssociateStorageManager: "Associate Storage Manager",
      Associate: "Associate",
      INFOANDFORM: {
        StorageManagerOperator: "PDA Operator Storage Manager",
        OperatorName: "Operator Name",
        Name: "Name",
        Pass: "Password",
        LastPasswordReset: "Last Password Reset",
        AddPdaOperator: "Create PDA Operator",
        StorageManager: "Storage Manager",
        RemovePdaOperatorTooltip: "Remove PDA Operator",
        RemoveManagerAssociationTooltip: "Remove Manager from PDA Operator",
        ResetPdaOperatorPasswordTooltip: "Reset PDA Operator password",
        AssociateStorageManagerTooltip: "Associate Storage Manager to the PDA Operator",
        SelectOneManager: "Select one manager"
      },

      NOTIFICATIONS: {
        DeleteOperatorConfirmText: "Are you sure you pretend to remove operator '{0}'?",
        DeleteOperatorSuccessMessage: "Operator Removed",
        ResetPasswordConfirmText: "Are you sure you pretend to Reset {0}'s Password?",
        ResetPasswordSuccessMessage: "Password Reseted",
        PdaOperatorAddedSuccessMessage: "New PDA Operator added.",
        RemoveManagerAssociationSuccess: "{0}'s manager removed.",
        RemoveManagerAssociationConfirmText: "Are you sure you pretend to remove {0}'s manager?",
        ManagerAssociatedSuccess: "Storage Manager associated."
      }
    },


    MATERIALS: {
      Materials: "Materials",
      ImportLoading: "Imported materials are being loaded. This might take a while.",
      ImportMaterialsSuccess: "Materials successfully imported!",

      INFO: {
        Plant: "Plant",
        SAPNumber: "SAP Number",
        Description: "Description",
        Units: "Units",
        Value: "Unit value"
      },
      POPUP: {
        CreateMaterial: "Create Material",
        CreateMaterialSuccess: "Material created!",
        EditMaterialSuccess: "Material edited!",
        EditMaterial: "Edit Material",
        ActivateMaterialConfirmText: "Are you sure you want to activate this material?",
        DeleteMaterialConfirmText: "Are you sure you want to delete this material?",
        DeactivateMaterialConfirmText: "Are you sure you want to deactivate this material?",
        Deactivate: "Disable",
        DisableMaterialSuccess: "Material deactivated!",
        ActivateMaterialSuccess: "Material activated!",
      }
    },

    ACCESSGROUPS:
    {
      EditedGroupSuccess: "Access groups edited!",
      AccessGroups: "Access Groups",
      SubmitError: "Please, verify errors in the form!",
      TYPES: {
        ACCESS: "Consult",
        REGISTER_SAMPLES: "Register Sample",
        ADMIN: "Administrator",
        ANALISTS: "Analyst",
        ANALISYS_REQUEST_GLOBAL: "Consult requests",
        AR: "AR",
        DF: "DF",
        DRYER: "Dryer",
        PP: "PP",
        WF: "WF"
      }
    },
  },
};

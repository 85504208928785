import "./InfoBlockComments.css";

import { ReactComponent as ArrowRightIcon } from "../../../../../lib/assets/icons/arrowRight.svg";
import { TextButton } from "../../../../../lib/components/buttons/TextButton";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";

interface IInfoBlockCommentsProps {
  label: string | "";
  value: string | "";
  onClick: () => void;
}

export function InfoBlockComments(props: IInfoBlockCommentsProps) {
  return (
    <div className="info-block info-block-comments">
      <div className="info-block-label">{props.label}</div>
      <div className="info-block-text" onClick={props.onClick}>
        <div className="info-block-value">{props.value}</div>
        <TextButton className="small-copy" text={translate("COMMON.ViewAll")} type="primary" icon={<ArrowRightIcon />} onClick={props.onClick} />
      </div>
    </div>
  );
}

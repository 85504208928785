import "./AdminStorageLocationsPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../lib/infrastructure/ui/UIServices";
import { useNavigate, useParams } from "react-router-dom";

import { AddStorageLocationPopup } from "./popups/AddStorageLocationPopup";
import { AdminStorageLocationsService } from "../../inventory-mock/services/AdminStorageLocationsService";
import { Button } from "../../../lib/components/buttons/Button";
import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { ImportLoader } from "../../../lib/components/loader/ImportLoader";
import { ImportPopup } from "../../../lib/components/popup/ImportPopup";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { QueryOptions } from "../../../models/domain/materials/QueryOptions";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../lib/components/buttons/ScalableIconButton";
import { StorageLocationSummary } from "../../inventory-mock/models/domain/admin-storage-locations/StorageLocationSummary";
import { StorageLocationUpdateStatusRequestDTO } from "../../inventory-mock/models/dtos/StorageLocationDTOs/StorageLocationUpdateStatusRequestDTO";
import { TextButton } from "../../../lib/components/buttons/TextButton";
import { Toggle } from "../../../lib/components/form/toggle/Toggle";
import { WarningPopup } from "../../../lib/components/popup/WarningPopup";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../lib/components/form/Form";
import { useServiceCallPro2 } from "../../../lib/hooks/useServiceCall";
import { Tooltip } from "../../../lib/components/tooltip/Tooltip";
import { AddStorageLocationBinPopup } from "./popups/AddStorageLocationBinPopup";

/* Page Icons*/

import { ReactComponent as ArrowRightIcon } from "../../../lib/assets/icons/arrowRight.svg";
import { ReactComponent as DeleteIcon } from "../../../lib/assets/icons/remover.svg";
import { ReactComponent as SearchIcon } from "../../../lib/assets/icons/search-icon.svg";
import { ReactComponent as AddIcon } from "../../../lib/assets/icons/add-round.svg";





const svc = new AdminStorageLocationsService();

export function AdminStorageLocationsPage() {

    const { id: locationID } = useParams();
    const navigate = useNavigate();

    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();

    const [uploadFileCall, uploadFileCallIsLoading] = useServiceCallPro2(svc, svc.uploadStorageLocationsFromXLSX);
    const [uploadFileBinsCall, uploadFileBinsCallIsLoading] = useServiceCallPro2(svc, svc.uploadIStorageBinsFromXLSX);
    const [getStorageLocationsCall, getStorageLocationsCallIsLoading] = useServiceCallPro2(svc, svc.getAdminStorageLocations);
    const [deleteStorageLocationsCall, deleteStorageLocationsCallIsLoading] = useServiceCallPro2(svc, svc.deleteStorageLocation);
    const [updateStorageLocationStatusCall, updateStorageLocationStatusIsLoading] = useServiceCallPro2(svc, svc.updateStorageLocationStatus);

    const [storageLocations, setStorageLocations] = useState<StorageLocationSummary[]>([]);
    const [totalItems, setTotalItems] = useState<number>();

    var searchFormControl = useFormControl<string>({});

    const [searchWord, setSearchWord] = useState<string>();
    const [timer, setTimer] = useState<NodeJS.Timeout>();

    const [currentpage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const [isLoadingStoragesImport, setIsLoadingStoragesImport] = useState(false);
    const [isLoadingStorageBinsImport, setIsLoadingStorageBinsImport] = useState(false);

    useEffect(() => {
        getStorageLocations();
    }, [currentpage, itemsPerPage, searchWord]);



    useEffect(() => {
        if (timer) {
            clearTimeout(timer);
        }
        var responseTimeOut = setTimeout(() => {
            if (timer) {
                setCurrentPage(0);
                setSearchWord(searchFormControl.value);
            }
        }, 1000);
        setTimer(responseTimeOut);
    }, [searchFormControl.value]);




    const getStorageLocations = useCallback(() => {

        if (!locationID) return;

        var queryOptions: QueryOptions = {
            page: currentpage,
            pageLength: itemsPerPage,
            searchWord: searchWord
        };

        getStorageLocationsCall(locationID, queryOptions)
            .then((data) => {
                setStorageLocations(data.items);
                setTotalItems(data.totalItems);
            })
            .catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [getStorageLocationsCall, locationID, openPopup, setStorageLocations, setTotalItems,
        currentpage, itemsPerPage, searchWord]);


    const updateStorageLocationStatus = useCallback(
        (locationId: string, storageLocationId: number, isDisabled: boolean) => {

            console.log("updateStorageLocationStatus", locationId, storageLocationId, isDisabled)
            var request: StorageLocationUpdateStatusRequestDTO = {
                isDisabled: isDisabled
            };
            updateStorageLocationStatusCall(locationId, storageLocationId, request)
                .then((_) => {
                    getStorageLocations();
                    createNotification(
                        <Notification
                            type="success" title={translate("COMMON.SYSTEMPOPUPS.Success")}
                            text={!isDisabled ? translate("GLOBALADMIN.STORAGES.Messages.EnableStorageSuccess") : translate("GLOBALADMIN.STORAGES.Messages.DisableStorageSuccess")}
                        ></Notification>
                    );
                })
                .catch((error) => {
                    if (!error) return;
                    openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
                });
        },
        [getStorageLocations, openPopup, createNotification, updateStorageLocationStatusCall]
    );



    const handleFileImport = useCallback((file: File) => {
        if (!locationID) return;

        setIsLoadingStoragesImport(true);
        uploadFileCall(`${locationID}`, file)
            .then((_) => {
                setIsLoadingStoragesImport(false);
                getStorageLocations();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("GLOBALADMIN.STORAGES.Messages.ImportStoragesSuccess")}
                    ></Notification>
                );
            })
            .catch(error => {
                setIsLoadingStoragesImport(false);
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationID, uploadFileCall, openPopup, getStorageLocations, createNotification]);



    const handleFileStorageBinsImport = useCallback((file: File) => {
        if (!locationID) return;

        setIsLoadingStorageBinsImport(true);
        uploadFileBinsCall(`${locationID}`, file)
            .then((_) => {
                setIsLoadingStorageBinsImport(false);
                getStorageLocations();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("GLOBALADMIN.STORAGES.Messages.ImportBinsSuccessfully")}
                    ></Notification>
                );
            })
            .catch(error => {
                setIsLoadingStorageBinsImport(false);
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationID, uploadFileBinsCall, openPopup, getStorageLocations, createNotification]);


    const handleDeleteStorageButtonClicked = useCallback((storageLocId: number) => {

        if (!locationID) return;

        deleteStorageLocationsCall(locationID, storageLocId)
            .then((_) => {
                closePopup();
                getStorageLocations();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("GLOBALADMIN.STORAGES.Messages.DeleteStorageSuccess")}
                    ></Notification>
                );
            })
            .catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationID, openPopup, createNotification, closePopup, deleteStorageLocationsCall, getStorageLocations]);




    const actionsButtons = useMemo(() => {
        return (
            <>

                <Button
                    text={translate("GLOBALADMIN.STORAGES.ImportStorages")}
                    type="secondary"
                    onClick={() => openPopup(<ImportPopup onClickDownloadTemplateBtn={() =>

                        window.open(ConfigurationProvider.getConfiguration().App.BackendUrl + "/templates/VLMT_Inventory_Storages_Template.xlsx", "_blank")}

                        onClickImportBtn={handleFileImport} />)}
                    className="import-storages"
                />
                <Button
                    text={translate("GLOBALADMIN.STORAGES.ImportStorageBins")}
                    type="secondary"
                    onClick={() => openPopup(<ImportPopup onClickDownloadTemplateBtn={() =>

                        window.open(ConfigurationProvider.getConfiguration().App.BackendUrl + "/templates/VLMT_Inventory_StoragesBins_Template.xlsx", "_blank")}

                        onClickImportBtn={handleFileStorageBinsImport} />)}
                    className="import-storages-bins"
                />
                <Button
                    text={translate("COMMON.Create")}
                    type="primary"
                    onClick={() => {
                        if (locationID) {
                            openPopup(<AddStorageLocationPopup locationID={locationID} onCompletedOperations={getStorageLocations} />);
                        }
                    }}
                    className="create-storage-location"
                ></Button>
            </>);
    }, [handleFileImport, openPopup, locationID, getStorageLocations, handleFileStorageBinsImport]);




    const handleOnPageAndItemsChanged = useCallback(
        (items: number, currentPage: number) => {
            setItemsPerPage(items);
            setCurrentPage(currentPage);
        },
        [setItemsPerPage, setCurrentPage]
    );



    const columns: ColumnDefinition<StorageLocationSummary>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => v.locSap,
                width: "15%",
                headerRender: (<>{translate("INVENTORY.STORAGE.INFOANDFORM.SAPLoc")}</>),
                columnKey: "loc-sap",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => v.description,
                width: "30%",
                headerRender: (<>{translate("INVENTORY.STORAGE.Description")}</>),
                columnKey: "description",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => {

                    if (v.storageBinSummary && v.storageBinSummary.length > 2) {
                        const firstTwoStorageBins = v.storageBinSummary.slice(0, 2);
                        return (
                            <div className="view-more">
                                <div className="first-two-elements">
                                    {firstTwoStorageBins.map(bin => `${bin?.code}`).join(", ")}
                                </div>
                                <TextButton
                                    className="small-copy"
                                    text={translate("COMMON.ViewAll")}
                                    type="primary"
                                    icon={<ArrowRightIcon />}
                                    onClick={() => navigate(`${v.locSap}`)}
                                />
                            </div>
                        );
                    }

                    if (!v?.storageBinSummary) {
                        return (
                            <Tooltip text={translate("GLOBALADMIN.STORAGES.BINS.AddBinShort")}>
                                <ScalableIconButton
                                    size={30}
                                    icon={<AddIcon />}
                                    onClick={() => openPopup(
                                        <AddStorageLocationBinPopup
                                            locationID={`${locationID}`}
                                            storageID={`${v.id}`}
                                            storageSapCode={`${v.locSap}`}
                                            storageDescription={`${v.description}`}
                                            onCompletedOperations={getStorageLocations}
                                        />
                                    )}
                                />
                            </Tooltip>
                        );
                    }

                    return <>{v?.storageBinSummary && v.storageBinSummary.map(bin => `${bin.code}`).join(", ")} </>;

                },
                width: "30%",
                headerRender: (<>{translate("INVENTORY.ITEM.INFOANDFORM.BinPlural")}</>),
                columnKey: "bin",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => {
                    if (locationID)
                        return (<div className="toggle-div">
                            <Toggle value={!v.isDisabled} />
                            <div
                                className="toggle-div-overlay"
                                onClick={(ev) => {
                                    ev.stopPropagation();
                                    if (!v.isDisabled) {
                                        openPopup(
                                            <WarningPopup
                                                className="disable-storage-location-popup"
                                                onDismissClicked={() => {
                                                    closePopup();
                                                }}
                                                onConfirmClicked={() => {
                                                    updateStorageLocationStatus(locationID, v.id, true);
                                                }}
                                                dismissText={translate("COMMON.Cancel")}
                                                confirmText={translate(
                                                    "GLOBALADMIN.STORAGES.POPUP.Deactivate"
                                                )}
                                            >
                                                {translate(
                                                    "GLOBALADMIN.STORAGES.POPUP.DeactivateConfirmText"
                                                )}
                                            </WarningPopup>
                                        );
                                    }
                                    else {
                                        openPopup(
                                            <WarningPopup
                                                onDismissClicked={() => {
                                                    closePopup();
                                                }}
                                                onConfirmClicked={() => {
                                                    updateStorageLocationStatus(locationID, v.id, false);
                                                }}
                                                dismissText={translate("COMMON.Cancel")}
                                                confirmText={translate("GLOBALADMIN.STORAGES.POPUP.Activate")}
                                            >
                                                {translate("GLOBALADMIN.STORAGES.POPUP.ActivateConfirmText")}
                                            </WarningPopup>
                                        );
                                    }

                                }}
                            />
                        </div>)
                },
                width: "5%",
                columnKey: "stats",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => (
                    <ScalableIconButton
                        icon={<DeleteIcon />}
                        onClick={() => {
                            openPopup(
                                <WarningPopup
                                    className="button-red"
                                    onDismissClicked={() => { closePopup(); }}
                                    onConfirmClicked={() => { handleDeleteStorageButtonClicked(v.id); }}
                                    dismissText={translate("COMMON.Cancel")}
                                    confirmText={translate("COMMON.Remove")}
                                >
                                    {translate("GLOBALADMIN.STORAGES.Messages.DeleteStorageConfirmText", v.locSap ?? "", v.description ?? "")}
                                </WarningPopup>
                            );
                        }}
                        size={30}
                    />
                ),
                width: "3%",
                columnKey: "stats",
                isSortable: false,
            },

        ], [openPopup, closePopup, handleDeleteStorageButtonClicked,
        navigate, locationID, updateStorageLocationStatus, getStorageLocations]);



    return (
        <PageLayout
            tabTitle={`${translate("INVENTORY.Inventory")} - ${translate("INVENTORY.StoragePlural")}`}
            pageTitle={translate("INVENTORY.StoragePlural")}
            className="admin-storage-locations"
            showBreadcrumb
            actions={<>{actionsButtons}</>}
        >

            {uploadFileCallIsLoading || deleteStorageLocationsCallIsLoading || uploadFileBinsCallIsLoading || updateStorageLocationStatusIsLoading ? <FullScreenLoader /> : null}

            <div className="command-bar">
                <FormFieldTextInput
                    formControl={searchFormControl}
                    icon={<SearchIcon />}
                    placeholder={translate("COMMON.FORM.SearchPlaceholder")}
                    className="search-box"
                />
                {isLoadingStoragesImport ? <ImportLoader text={translate("GLOBALADMIN.STORAGES.ImportStoragesLoading")} /> : null}
                {isLoadingStorageBinsImport ? <ImportLoader text={translate("GLOBALADMIN.STORAGES.ImportStorageBinsLoading")} /> : null}
            </div>

            <ResponsiveDataTable
                columnDefinitions={columns}
                items={storageLocations || []}
                paginationOptions={{
                    itemsPerPage: itemsPerPage,
                    itemsPerPageOptions: [10, 20, 30, 40, 50],
                }}

                onClickRow={(item) => navigate(`${item.locSap}`)}

                currentpage={currentpage}
                onPageAndItemsChanged={handleOnPageAndItemsChanged}
                totalitems={totalItems || 0}
                isLoading={getStorageLocationsCallIsLoading}
            ></ResponsiveDataTable>
        </PageLayout >
    );
}

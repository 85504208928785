import { FormField } from "./FormField";
import { IFormControl } from "../Form";
import { SelectMultiple } from "../select-multiple/SelectMultiple";

interface IFormFieldSelectMultipleProps<T> {
  label?: string;
  formControl: IFormControl<T[]>;
  icon?: React.ReactNode;
  placeholder?: string;
  options: T[];
  labelSelector: (value: T) => string;
  idSelector: (value: T) => string | number;
  maxHeightOptions?: string;
  alternativeStyle?: boolean;
}

export function FormFieldSelectMultiple<T>(
  props: IFormFieldSelectMultipleProps<T>
) {
  return (
    <FormField label={props.label} formControl={props.formControl}>
      <SelectMultiple
        options={props.options}
        labelSelector={props.labelSelector}
        idSelector={props.idSelector}
        placeholder={props.placeholder}
        hasError={props.formControl.hasErrors}
        isDisabled={props.formControl.isDisabled}
        onChange={props.formControl.setValue}
        value={props.formControl.value}
        maxHeightOptions={props.maxHeightOptions}
        alternativeStyle={props.alternativeStyle}
      />
    </FormField>
  );
}

import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";

import { InventoryStats } from "../models/domain/inventory/InventoryStats";
import { StatusCard } from "../../../lib/components/cards/stats-card/StatusCard";
import { StorageService } from "../services/InventoryStorageLocationsService";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useServiceCallPro2 } from "../../../lib/hooks/useServiceCall";

const storageSvc = new StorageService();

export interface IInventoryStorageLocationStatsIntrinsicBehavior {
    refresh: () => void;
}

export interface IInventoryStorageLocationStatsSectionProps {
    locationId?: string;
    inventoryId?: string;
    storageLocationCode?: string;
}

export const InventoryStorageLocationStatsSection = forwardRef(
    function InventoryStatInventoryStorageLocationStatsSectionsSection(props: IInventoryStorageLocationStatsSectionProps,  ref: React.ForwardedRef<IInventoryStorageLocationStatsIntrinsicBehavior>) {

    const {locationId, inventoryId, storageLocationCode} = props;
    const [inventoryStats, setInventoryStats] = useState<InventoryStats>();

    const [getInventoryStatsCall] = useServiceCallPro2(storageSvc, storageSvc.getStorageLocationStats);


    const getInventoryStats = useCallback(() => {
        if (!locationId || !inventoryId || !storageLocationCode) return;
        getInventoryStatsCall(locationId, inventoryId, storageLocationCode).then((data) => setInventoryStats(data))
    }, [locationId, inventoryId,storageLocationCode, setInventoryStats, getInventoryStatsCall]);

    useEffect(() => {
        console.log("!")
        getInventoryStats();
    }, [/* locationId,  */inventoryId, storageLocationCode]);

    
    useImperativeHandle(ref, () => {
        return {
          refresh() {
            getInventoryStats();
          }
        }
    }, [getInventoryStats]);

    return (<>
        <StatusCard
            title={translate("INVENTORY.ITEM.TotalItems")}
            value={inventoryStats?.totalItens?.toString() ?? "-"}
            backgroundColor="status-dark-grey"

        />
        <StatusCard
            title={translate("INVENTORY.ITEM.STATUS.Counting")}
            value={inventoryStats?.totalInCounting?.toString() ?? "-"}
            backgroundColor="status-yellow"

        />
        <StatusCard
            title={translate("INVENTORY.ITEM.STATUS.ReadyToBeValidated")}
            value={inventoryStats?.totalReadyToBeValidated?.toString() ?? "-"}
            backgroundColor="status-sky-blue"
        />
        <StatusCard
            title={translate("INVENTORY.ITEM.STATUS.ValidatedPlural")}
            value={inventoryStats?.totalValidated?.toString() ?? "-"}
            backgroundColor="status-deep-blue"
        />
        <StatusCard
            title={translate("INVENTORY.ITEM.STATUS.ClosedPlural")}
            value={inventoryStats?.totalClosed?.toString() ?? "-"}
            backgroundColor="status-green"
        />
    </>);

});

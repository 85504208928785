import { useNavigate, useParams } from "react-router-dom";
import { useGlobalLocation } from "../location/LocationServices";
import { useEffect, useState } from "react";
import { InventoriesService } from "../../../features/inventory-mock/services/InventoriesService";
import { InventoryStatusEnum } from "../../../features/inventory-mock/models/dtos/InventoryDTOs/InventoryStatusEnum";

var svc = new InventoriesService();

export function useInventoryStatus() {

    const { id: inventoryID } = useParams();

    const globalLocationID = useGlobalLocation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [isClosed, setIsClosed] = useState(true);


    useEffect(() => {
        setIsLoading(true);

        if (!globalLocationID || !inventoryID)
            setIsLoading(true);

        svc.getInventoryByID("" + globalLocationID, "" + inventoryID)
            .then((data) => {
                if (data?.status && (data.status === InventoryStatusEnum.CLOSED || data.status === InventoryStatusEnum.REGISTERED || data.status === InventoryStatusEnum.CANCELED))
                    setIsClosed(true);
                else
                    setIsClosed(false);
                    setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                if (!error) return;
                navigate("/unavailable")
            });

    }, [globalLocationID, inventoryID]);


    return { isLoadingInventoryStatus: isLoading, isInventoryClosed: isClosed };
}
import "./InventoryContainer.css";

import { useEffect, useMemo } from "react";

import { ReactComponent as AllItemsIcon } from "../../../../lib/assets/icons/all-items.svg";
import { InventoriesService } from "../../services/InventoriesService";
import { MobileSubSidebar } from "../../../../lib/components/side-bar/MobileSubSidebar";
import { ReactComponent as PDASimulatorIcon } from "../../../../lib/assets/icons/pda-simulator.svg";
import { ReactComponent as StoragesIcon } from "../../../../lib/assets/icons/storages.svg";
import { SubSidebar } from "../../../../lib/components/side-bar/SubSidebar";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useParams } from "react-router-dom";
import { useUpdatePageData } from "../../../../lib/infrastructure/navigation/hooks/useUpdatePageData";
import { useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";

interface IInventoryContainerProps {
    children: React.ReactNode;
}


var svc = new InventoriesService();


export function InventoryContainer(props: IInventoryContainerProps) {
    const windowResize = useWindowResize();


    const updateRouteData = useUpdatePageData();
    const locationID = useGlobalLocation();
    const { id } = useParams();


    useEffect(() => {
        svc.getInventoryByID("" + locationID, "" + id)
            .then((r) => {
                updateRouteData(["##INVENTORY##", r?.name ?? "#"]);
            }).catch((_) => { })
    }, [locationID, id]);


    const subSideBarButtons = useMemo(() => [
        {
            icon: <StoragesIcon />,
            navigateTo: "storages",
            name: translate("INVENTORY.Storage"),
        },
        {
            icon: <AllItemsIcon />,
            navigateTo: "items",
            name: translate("INVENTORY.STORAGE.InventoryItems"),
        },
        /*         {
                    icon: <PDASimulatorIcon />,
                    navigateTo: "simulator",
                    name: translate("INVENTORY.PDASimulator"),
                }, */
    ], []);



    const renderSubSideBar = useMemo(() => {
        return windowResize > 425 ? <SubSidebar buttons={subSideBarButtons} /> : <MobileSubSidebar buttons={subSideBarButtons} />;
    }, [windowResize, subSideBarButtons]);

    return (
        <div className="inventory-content-container">
            {renderSubSideBar}
            {props.children}
        </div>
    );
}

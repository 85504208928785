import {
  ValidatorFunction,
  useFormControl,
} from "../../../lib/components/form/Form";
import { useCallback, useState } from "react";
import {
  useClosePopup,
  useCreateNotification,
  usePopup,
} from "../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../common/validators/ValidateFormControls";
import { CreateFacilityRequestDTO } from "../../../models/api/facilities/CreateFacilityRequestDTO";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FacilityService } from "../../../services/FacilityService";
import { FormContainer } from "../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../lib/utils/TrimString";
import { validateEmptyStrings } from "../../../lib/validators/ValidateEmptyStrings";

var facilityService = new FacilityService();

interface IProps {
  onCompleted: () => void;
  locationId: number;
}


export function AddFacilityPopup(props: IProps) {
  const closePopup = useClosePopup();
  const openPopup = usePopup();

  const createNotification = useCreateNotification();

  const [onClickSubmit, setOnClickSubmit] = useState<boolean>(false);

  const nameFormControl = useFormControl<string>({
    validators: [validateEmptyStrings()],
    enableAutoValidate: true,
  });

  const addFacility = useCallback(() => {
    if (!props.locationId || !AllValid(nameFormControl.validate())) return;
    setOnClickSubmit(true);


    if (!AllValid(nameFormControl.validate())) return;

    let request: CreateFacilityRequestDTO = {
      name: trimString(nameFormControl.value),
      locationId: props.locationId,
    };


    facilityService
      .addFacility(request)
      .then((_) => {
        setOnClickSubmit(false);
        props.onCompleted();
        closePopup();
        createNotification(
          <Notification
            type="success"
            title={translate("COMMON.SYSTEMPOPUPS.Success")}
            text={translate(
              "GLOBALADMIN.FACILITIES.POPUP.CreateFacilitySuccess"
            )}
          ></Notification>
        );
      })
      .catch((error) => {
        if (!error) return;
        setOnClickSubmit(false);
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });

  }, [
    nameFormControl,
    props.locationId,
    nameFormControl.value,
    nameFormControl.validate,
    closePopup,
    props.onCompleted,
    setOnClickSubmit,
    openPopup,
    createNotification,
  ]);



  return (
    <PopupContainer className="popup-add-facility">
      {onClickSubmit ? <FullScreenLoader /> : null}

      <PopupHeader title={translate("GLOBALADMIN.FACILITIES.CreateFacility")} />
      <PopupContent>
        <FormContainer>
          <FormFieldTextInput
            formControl={nameFormControl}
            label={translate("GLOBALADMIN.FACILITIES.INFOANDFORM.Name")}
          />
        </FormContainer>
        <Spacer mode={"vertical"} px="30"></Spacer>
        <PopupActionButtons
          buttons={[
            {
              text: translate("COMMON.Cancel"),
              type: "tertiary",
              onClick: () => closePopup(),
            },
            {
              text: translate("COMMON.Create"),
              type: "primary",
              onClick: addFacility,
            },
          ]}
        />
      </PopupContent>
    </PopupContainer>
  );
}

import "./Button.css";

import { useCallback, useMemo } from "react";

import { CssClassnameBuilder } from "../../utils/CssClassnameBuilder";

export interface IButtonProps {
  text: string;
  type: "primary" | "secondary" | "tertiary" | "status-blue";
  icon?: React.ReactNode;
  isDisabled?: boolean;
  className?: string;
  onClick?: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export function Button(props: IButtonProps) {
  const buttonCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("button")
      .addConditional(props.type, props.type)
      .addConditional(props.icon, "has-icon")
      .addConditional(props.isDisabled, "disabled")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className, props.type, props.isDisabled, props.icon]);

  const handleClicked = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ev.stopPropagation();
      if (props.isDisabled) return;
      props.onClick && props.onClick(ev);
    },
    [props.onClick, props.isDisabled]
  );

  return (
    <div className={buttonCss} onClick={handleClicked}>
      {props.icon ? <div className="button-icon">{props.icon}</div> : null}
      {props.text}
    </div>
  );
}

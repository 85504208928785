import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { InventoryItemSummary } from "../models/domain/inventory-items/InventoryItemSummary";
import { InventoryItemsResponse } from "../models/domain/responses/InventoryItemsResponse";
import { InventoryItemsResponseDTO } from "../models/dtos/InventoryItemDTOs/InventoryItemsResponseDTO";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
  };


  export interface InventoryItemCountCreateDTO {
    rackShelf: string | null;
    quantity: number;
    comment: string | null;
}


export class MobileInventoryService {




    getStorageLocationItemsByCode(
        locationId: string,
        inventoryID: string,
        storageLocationCode: string,
        abortSignal: AbortSignal | undefined
      ): Promise<InventoryItemsResponse> {
        return HttpClient.sessionRequest<InventoryItemsResponseDTO>({
          method: "GET",
          url: Route( `/api/mobile/v1/Locations/${locationId}/Inventories/${inventoryID}/StorageLocations/${storageLocationCode}/Items`),
          signal: abortSignal
        })
          .then((response) => {
            var mappedItems: InventoryItemSummary[] = response.data.items.map(
              (ob) => ({
                id: ob.id,
                materialNumber: ob.materialNumber,
                materialDescription: ob.materialDescription,
                batch: ob.batch,
                storage: ob.storage,
                storageName: null,
                units: ob.units,
                quantitySAP: ob.quantitySAP,
                quantityCounted: ob.quantityCounted,
                status: ob.status,
                isNew: ob.isNew,
                bin: ob.bin,
                monetarySAP: ob.monetarySAP,
                typeSAP: ob.typeSAP,
                specialSAP: ob.specialSAP,

                quantityDeviationPercentage: ob.quantityDeviationPercentage,
                monetaryDeviationValue: ob.monetaryDeviationValue,


                isProblematic: ob.isProblematic,

                permissions: ob.permissions,
              })
            );

            var resp : InventoryItemsResponse = {
              items: mappedItems,
              totalItems: response.data.totalItems
            }

            return resp;
          })
          .catch((error) => {
            throw error;
          });
      }


      createInventoryItemCount(locationId: string, inventoryId: string, itemId: string, request: InventoryItemCountCreateDTO): Promise<void> {
        return HttpClient.sessionRequest<InventoryItemsResponseDTO>({
            method: "POST",
            url: Route(`/api/mobile/v1/Locations/${locationId}/Inventories/${inventoryId}/Items/${itemId}/Counts`),
            data: request
        })
            .then((response) => {

               return ;
            })
            .catch((error) => { throw error; });
    }


    createInventoryItem(locationId: string, inventoryId: string, request: InventoryItemMobileCreateDTO): Promise<InventoryItemDetailsDTO> {
      return HttpClient.sessionRequest<InventoryItemDetailsDTO>({
          method: "POST",
          url: Route(`/api/mobile/v1/Locations/${locationId}/Inventories/${inventoryId}/Items`),
          data: request
      })
          .then((response) => response.data)
          .catch((error) => { throw error; });
  }

}
export interface InventoryItemMobileCreateDTO {
  materialNumber: string;
  batch: string;
  storage: string;
  bin: string | null;
}

export interface InventoryItemDetailsDTO {
  id: number;
  sAPInventoryDocId: string | null;
  materialNumber: string;
  materialDescription: string;
  batch: string | null;
  storage: string;
  storageName: string | null;
  units: string;
  quantitySAP: number;
  monetarySAP: number | null;
  typeSAP: number | null;
  specialSAP: string | null;
  bin: string | null;
  isNew: boolean;
  quantityCounted: number;
  monetaryValueCounted: number | null;
  quantityDeviationValue: number | null;
  quantityDeviationPercentage: number | null;
  monetaryDeviationValue: number | null;
  monetaryDeviationPercentage: number | null;
  isProblematic: boolean;
}

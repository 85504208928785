import "./EditStorageManagerStoragesPopup.css";

import { useServiceCallPro2 } from "../../../../lib/hooks/useServiceCall";
import { AdminStorageLocationsService } from "../../../inventory-mock/services/AdminStorageLocationsService";
import { useCallback, useEffect, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { Optional, ValidatorFunction, useFormControl } from "../../../../lib/components/form/Form";
import { StorageManagersService } from "../services/StorageManagersService";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { StorageManagerSummary } from "../models/StorageManagerSummary";
import { CardContainer } from "../../../../lib/layouts/containers/card/CardContainer";
import { InfoBlock } from "../../../../lib/components/info-block/InfoBlock";
import { EditStorageManagerStoragesRequestDTO } from "../models/EditStorageManagerStoragesRequestDTO";
import { FormFieldSelectMultiple } from "../../../../lib/components/form/form-field/FormFieldSelectMultiple";

const svc = new AdminStorageLocationsService();
const storageManagersSvc = new StorageManagersService();

export interface EditStorageManagerStoragesProps {
    locationID: string;
    managerEmail: string;
    onCompletedOperations: () => void;
}

export interface SelectOption {
    id: string;
    description: string;
}


const labelSampleTypeSelector = (item: SelectOption) => item.description;
const idSampleTypeSelector = (item: SelectOption) => item.id;

function hasAtLeastOneElement<A>(): ValidatorFunction<Optional<A[]>> {
    return (value: A[] | undefined) => {
        if (!value || value.length < 1) {
            return translate("GLOBALADMIN.STORAGEMANAGERS.SelectOneStorageWarningMessage");
        }
        return null;
    };
}



export function EditStorageManagerStoragesPopup(props: EditStorageManagerStoragesProps) {

    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();

    const [getStorageLocationsCall, getStorageLocationsCallIsLoading] = useServiceCallPro2(svc, svc.getAdminStorageLocations);
    const [getStorageManagerCall, getStorageManagerIsLoading] = useServiceCallPro2(storageManagersSvc, storageManagersSvc.getStorageManagerStoragesByEmail);
    const [updateManagerStoragesCall, updateManagerStoragesIsLoading] = useServiceCallPro2(storageManagersSvc, storageManagersSvc.updatedStorageManagerStorages);


    const [storageLocationOptions, setStorageLocationOptions] = useState<SelectOption[]>([]);

    const [storageManagerSummary, setStorageManagerSummary] = useState<StorageManagerSummary | null>();

    const storagesFormControl = useFormControl<SelectOption[]>({
        validators: [hasAtLeastOneElement()]
    });




    useEffect(() => {
        getStorageLocations();
        getStorageManagerStorages();
    }, []);


    useEffect(() => {
        if (!storageManagerSummary || !storageLocationOptions) return;

        var matchStoragesSelected = storageLocationOptions.filter(opt => storageManagerSummary.storages.some(s => s.storageCode === opt.id));

        storagesFormControl.setValue(matchStoragesSelected);
        storagesFormControl.validate();

    }, [storageManagerSummary]);




    const getStorageManagerStorages = useCallback(() => {

        if (!props.locationID || !props.managerEmail) return;

        getStorageManagerCall(props.locationID, props.managerEmail)
            .then((data) => {
                setStorageManagerSummary(data);
            })
            .catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [getStorageManagerCall, props.locationID, props.managerEmail, openPopup, setStorageManagerSummary]);




    const getStorageLocations = useCallback(() => {

        if (!props.locationID) return;

        getStorageLocationsCall(props.locationID, {})
            .then((data) => {

                const mappedOptions: SelectOption[] = data.items.map(s => ({
                    id: s.locSap,
                    description: s.description ? s.locSap + " - " + s.description : s.locSap
                }));

                setStorageLocationOptions(mappedOptions);

            })
            .catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [getStorageLocationsCall, props.locationID, openPopup, setStorageLocationOptions]);





    const handleUpdateStoragesClicked = useCallback(() => {

        if (!props.locationID || !props.managerEmail || !storagesFormControl.validate()) return;


        var request: EditStorageManagerStoragesRequestDTO = {
            storageCodes: storagesFormControl.value?.map((sl) => sl.id) || []
        }

        console.log("Selected storages", storagesFormControl.value?.map((sl) => sl.id) || []);

        updateManagerStoragesCall(props.locationID, props.managerEmail, request)
            .then((_) => {

                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("GLOBALADMIN.STORAGEMANAGERS.StorageManagerStoragesEdited")}
                    ></Notification>
                );
                props.onCompletedOperations();
                closePopup();
            })
            .catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [props.locationID, props.managerEmail, storagesFormControl.validate, 
        props.onCompletedOperations, closePopup, storagesFormControl.value, 
        createNotification, updateManagerStoragesCall, openPopup]);





    return (
        <PopupContainer className="popup-edit-storage-manager-storages">
            <PopupHeader title={translate("GLOBALADMIN.STORAGEMANAGERS.EditManagersStorage")} />

            { updateManagerStoragesIsLoading ? <FullScreenLoader /> : null}

            <PopupContent isLoading={getStorageLocationsCallIsLoading || getStorageManagerIsLoading}>

                <CardContainer className="edit-storage-manager-storages">
                    <InfoBlock
                        label={"E-mail"}
                        value={storageManagerSummary?.email || "-"}
                    />
                    <InfoBlock
                        label={translate("GLOBALADMIN.STORAGES.Info.Name")}
                        value={storageManagerSummary?.name || "-"} />
                </CardContainer>

                <Spacer mode="vertical" px={20} />

                <FormContainer>

                    <FormFieldSelectMultiple<SelectOption>
                        label={translate("GLOBALADMIN.STORAGES.Storages")}
                        placeholder={translate("COMMON.FORM.SelectManyOptions")}
                        options={storageLocationOptions || []}
                        labelSelector={labelSampleTypeSelector}
                        idSelector={idSampleTypeSelector}
                        formControl={storagesFormControl}
                        maxHeightOptions={"16rem"}
                    />
                </FormContainer>
                <Spacer mode={"vertical"} px="30"></Spacer>
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Update"),
                            type: "primary",
                            onClick: handleUpdateStoragesClicked
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}

import "./AddStorageLocationBinPopup.css";

import { useCallback } from "react";
import { useFormControl } from "../../../../lib/components/form/Form";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { validateEmptyStrings } from "../../../../lib/validators/ValidateEmptyStrings";
import { useServiceCallPro2 } from "../../../../lib/hooks/useServiceCall";
import { AdminStorageLocationsService } from "../../../inventory-mock/services/AdminStorageLocationsService";
import { CardContainer } from "../../../../lib/layouts/containers/card/CardContainer";
import { InfoBlock } from "../../../../lib/components/info-block/InfoBlock";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { Notification } from "../../../../lib/components/notifications/Notification";

var svc = new AdminStorageLocationsService();

export interface AddStorageLocationBinPopupProps {
    locationID: string;
    storageID: string;
    storageSapCode: string;
    storageDescription: string;
    onCompletedOperations: () => void;
}

export function AddStorageLocationBinPopup(props: AddStorageLocationBinPopupProps) {

    const createNotification = useCreateNotification();
    const closePopup = useClosePopup();
    const openPopup = usePopup();


    const [addStorageLocationBinCall, addStorageLocationBinCallIsLoading] = useServiceCallPro2(svc, svc.addStorageLocationBin);


    const storageBinFormControl = useFormControl<string>({
        validators: [validateEmptyStrings()],
        enableAutoValidate: true,
    });



    const handleAddButtonClicked = useCallback(() => {

        if (!storageBinFormControl.validate()) return;
        if(!props.locationID || !props.storageID) return;

        addStorageLocationBinCall(props.locationID, props.storageID, { code: storageBinFormControl.value ?? "" })
            .then((_) => {

                props.onCompletedOperations();
                closePopup();

                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("GLOBALADMIN.STORAGES.BINS.Messages.AddBinSuccess")}
                    ></Notification>
                );
            })
            .catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [storageBinFormControl.validate, storageBinFormControl.value, closePopup,
    props.onCompletedOperations, createNotification, addStorageLocationBinCall, openPopup,
    props.locationID, props.storageID])




    return (
        <PopupContainer className="popup-add-storage-location-bin">
            {addStorageLocationBinCallIsLoading ? <FullScreenLoader /> : null}

            <PopupHeader title={translate("GLOBALADMIN.STORAGES.BINS.AddBin")} />
            <PopupContent>

                <CardContainer className="edit-storage-manager-storages">
                    <InfoBlock
                        label={translate("INVENTORY.STORAGE.INFOANDFORM.SAPLoc")}
                        value={props.storageSapCode || "-"}
                    />
                    <InfoBlock
                        label={translate("INVENTORY.STORAGE.Description")}
                        value={props.storageDescription || "-"} />
                </CardContainer>

                <Spacer mode="vertical" px={20} />

                <FormContainer>
                    <FormFieldTextInput
                        formControl={storageBinFormControl}
                        label={translate("GLOBALADMIN.STORAGES.Info.Name")}
                        placeholder={translate("GLOBALADMIN.STORAGES.BINS.BinPlaceholder")}
                    />

                </FormContainer>
                <Spacer mode={"vertical"} px="30"></Spacer>
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Add"),
                            type: "primary",
                            onClick: handleAddButtonClicked
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}

import { useCallback, useState } from 'react';
import { useClosePopup, usePopup } from '../../../../../lib/infrastructure/ui/UIServices';

import { AllValid } from '../../../../../common/validators/ValidateFormControls';
import { DateTime } from 'luxon';
import { ErrorPopup } from '../../../../../lib/components/popup/ErrorPopup';
import { FormContainer } from '../../../../../lib/layouts/containers/form/FormContainer';
import { FormFieldDatePicker } from '../../../../../lib/components/form/form-field/FormFieldDatePicker';
import { FormFieldTextInput } from '../../../../../lib/components/form/form-field/FormFieldTextInput';
import { FormSection } from '../../../../../lib/components/form/section/FormSection';
import { FullScreenLoader } from '../../../../../lib/components/loader/FullScreenLoader';
import { InventoriesService } from '../../../services/InventoriesService';
import { PopupActionButtons } from '../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons';
import { PopupContainer } from '../../../../../lib/layouts/containers/popup-container/PopupContainer';
import { PopupContent } from '../../../../../lib/layouts/containers/popup-content/PopupContent';
import { PopupHeader } from '../../../../../lib/layouts/containers/popup-header/PopupHeader';
import { SetInventoryDeviationPopup } from "../../deviation/SetInventoryDeviationPopup";
import { Spacer } from '../../../../../lib/components/separator/Spacer';
import { translate } from '../../../../../lib/infrastructure/i18n/InternationalizationService';
import { useFormControl } from '../../../../../lib/components/form/Form';
import { validateDateTime } from '../../../../../lib/validators/ValidateDateTime';
import { validateEmptyStrings } from '../../../../../lib/validators/ValidateEmptyStrings';

var inventoriesSvc = new InventoriesService();

interface INewInventoryPopupProps {
    locationID: string;
    onCompletedOperations: () => void;
}

const dateNow = DateTime.now();

export function NewInventoryPopup(props: INewInventoryPopupProps) {

    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const nameFormControl = useFormControl<string>({
        validators: [validateEmptyStrings()],
        enableAutoValidate: true,
    });

    const initialDatePickerControl = useFormControl<DateTime>({
        validators: [validateDateTime()],
        enableAutoValidate: false,
        isDisabled: false,
    });

    const finalDatePickerControl = useFormControl<DateTime>({
        validators: [validateDateTime()],
        enableAutoValidate: false,
        isDisabled: false,
    });



    const handleCreateInventoryClicked = useCallback(() => {

        if (!props.locationID) return;
        if (!AllValid(nameFormControl.validate(), initialDatePickerControl.validate(), finalDatePickerControl.validate())) return;

        setIsLoading(true);

        inventoriesSvc.addInventory(props.locationID, nameFormControl.value ?? "",
            initialDatePickerControl.value, finalDatePickerControl.value)
            .then((freshInventoryDetails) => {
                setIsLoading(false);
                props.onCompletedOperations();
                openPopup(<SetInventoryDeviationPopup
                    locationID={props.locationID}
                    inventoryName={freshInventoryDetails.name}
                    inventoryID={"" + freshInventoryDetails.id}
                    onCompletedOperations={() => { }} />)
                /* closePopup(); */
            })
            .catch((error) => {
                setIsLoading(false);
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })

    }, [props.locationID, nameFormControl.validate, openPopup,
    initialDatePickerControl.validate, finalDatePickerControl.validate,
    nameFormControl.value, initialDatePickerControl.value,
    finalDatePickerControl.value, setIsLoading,
    props.onCompletedOperations, closePopup]);



    return <div className="add-test-type-wrapper">
        <PopupContainer className="popup-create-inventory">
            {isLoading ? <FullScreenLoader /> : null}
            <PopupHeader
                title={translate("INVENTORY.NewInventory")}
            />
            <PopupContent>
                <FormContainer>
                    <FormFieldTextInput
                        formControl={nameFormControl}
                        label={translate("INVENTORY.Name")}
                        placeholder={translate("INVENTORY.NamePlaceholder")}
                    />
                    <FormSection isInputGap>
                        <FormFieldDatePicker
                            label={translate("COMMON.FORM.InitialDate")}
                            formControl={initialDatePickerControl}
                            placeholder={translate("COMMON.FORM.InitialDatePlaceholder")}
                            maxDate={finalDatePickerControl.value}
                            minDate={dateNow.minus({day: 1})}
                        />
                        <FormFieldDatePicker
                            label={translate("COMMON.FORM.FinalDate")}
                            formControl={finalDatePickerControl}
                            placeholder={translate("COMMON.FORM.FinalDatePlaceholder")}
                            minDate={initialDatePickerControl.value}
                        />
                    </FormSection>
                </FormContainer>
                <Spacer mode={"vertical"} px="30"></Spacer>
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Create"),
                            type: "primary",
                            onClick: handleCreateInventoryClicked
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    </div>;
}
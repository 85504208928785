import { User } from "../../domain/User";
import { UserDTO } from "./UserDTO";

export function APIUserToDomainMapper(apiDto: UserDTO): User|null {
    if(!apiDto) return null;
    return {
        id: apiDto.userId,
        name: apiDto.userDisplayName,
        email: apiDto.userEmail,
    }
}

import { Optional, ValidatorFunction } from "../components/form/Form";

import { translate } from "../infrastructure/i18n/InternationalizationService";

export function validateEmptyStrings(): ValidatorFunction<Optional<string>> {
    return (value: string | undefined) => {
        if (!value || value?.trim() === "") {
            return translate("COMMON.FORM.VALIDATIONS.CannotBeEmpty");
        }
        return null;
    };
}
import "./MoveItemCountPopup.css";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../../../lib/infrastructure/ui/UIServices";

import { ReactComponent as ArrowDown } from "../../../../../lib/assets/icons/arrowDown.svg";
import { ReactComponent as ArrowUp } from "../../../../../lib/assets/icons/arrowUp.svg";
import { ReactComponent as CleanIcon } from "../../../../../lib/assets/icons/clean.svg";
import { ColumnDefinition } from "../../../../../lib/components/table/TableInterfaces";
import { ConditionalRender } from "../../../../../lib/functional/ConditionalRender";
import { DateTimeHelper } from "../../../../../lib/helpers/datetime/DateTime";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { IconButton } from "../../../../../lib/components/buttons/IconButton";
import { InfoBlock } from "../../../../../lib/components/info-block/InfoBlock";
import { InventoryItemCountSummary } from "../../../models/domain/inventory-item-counts/InventoryItemCountSummary";
import { InventoryItemCountsService } from "../../../services/InventoryItemCountsService";
import { InventoryItemDetails } from "../../../models/domain/inventory-items/InventoryItemDetails";
import { InventoryItemsService } from "../../../services/InventoryItemsService";
import { MoveItemsQueryOptions } from "../../../models/dtos/InventoryStorageLocationDTOs/MoveItemsQueryOptions";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { ResponsiveDataTable } from "../../../../../lib/components/table/ResponsiveDataTable";
import { ReactComponent as SearchIcon } from "../../../../../lib/assets/icons/search-icon.svg";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { TitledCardContainer } from "../../../../../lib/layouts/containers/card/TitledCardContainer";
import { Tooltip } from "../../../../../lib/components/tooltip/Tooltip";
import { roundNumber } from "../../../../../common/helpers/RoundNumber";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useServiceCallPro2 } from "../../../../../lib/hooks/useServiceCall";
import { Tag } from "../../../../../lib/components/tag/Tag";
import { InventoryItemStatusEnum, InventoryItemStatusTranslationTerms } from "../../../models/domain/inventory-items/InventoryItemStatusEnum";
import { getInventoryItemStatusCss } from "../../../../../common/helpers/GetInventoryItemStatusCss";
import { ReactComponent as WarningIcon } from "../../../../../lib/assets/icons/warning-icon-v2.svg";

var inventoryItemCountsSvc = new InventoryItemCountsService();
var inventoryItemsSvc = new InventoryItemsService();

export interface MoveItemCountsPopupProps {
    locationID: string;
    inventoryID: string;
    itemID: string;
    itemCountID: string;
    onCompletedOperations: () => void;
}

export function MoveItemCountPopup(props: MoveItemCountsPopupProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const createNotification = useCreateNotification();
    const windowResize = useWindowResize();
    const upperRowRef = useRef<HTMLDivElement>(null);
    const bottomRowRef = useRef<HTMLDivElement>(null);

    const [getItemCountCall, getItemCountCallIsLoading] = useServiceCallPro2(inventoryItemCountsSvc, inventoryItemCountsSvc.getInventoryItemCountByID);
    const [getItemSiblingsCall, getItemSiblingsCallIsLoading] = useServiceCallPro2(inventoryItemsSvc, inventoryItemsSvc.getInventoryItemSiblings);
    const [moveItemCountCall, moveItemCountCallIsLoading] = useServiceCallPro2(inventoryItemCountsSvc, inventoryItemCountsSvc.moveItemCount);

    const [isFiltersPanelExpanded, setIsFiltersPanelExpanded] = useState<boolean>(false);
    const [isFiltersWrapped, setIsFiltersWrapped] = useState<boolean>(false);
    const [inventoryItemSiblings, setInventoryItemSiblings] = useState<InventoryItemDetails[]>();
    const [totalSiblings, setTotalSiblings] = useState<number>();
    const [itemCountSummary, setItemCountSummary] = useState<InventoryItemCountSummary>();
    const [selectedSiblingID, setSelectedSiblingID] = useState<number | undefined>();

    const [tableQueryOptions, setTableQueryOptions] = useState<MoveItemsQueryOptions>({ page: 0, pageLength: 3 });

    const storageFormControl = useFormControl<string>({});
    const binFormControl = useFormControl<string>({});
    const [tableRenderId, setTableRenderID] = useState<number>(1);


    const [timer, setTimer] = useState<NodeJS.Timeout>();


    useEffect(() => {
        if (timer) {
            clearTimeout(timer);
        }
        var responseTimeOut = setTimeout(() => {
            if (timer) {
                setTableQueryOptions({
                    ...tableQueryOptions,
                    page: 0,
                    storage: storageFormControl.value,
                    bin: binFormControl.value,
                });
            }
        }, 750);

        setTimer(responseTimeOut);
    }, [storageFormControl.value, binFormControl.value]);


    useEffect(() => {
        getInventoryItemCountSummary();
    }, []);


    useEffect(() => {
        setSelectedSiblingID(undefined)
        setTableRenderID(tableRenderId + 1);
        getInventoryItemSiblings();

    }, [tableQueryOptions]);


    const getInventoryItemCountSummary = useCallback(() => {

        if (!props.locationID || !props.inventoryID || !props.itemID || !props.itemCountID) return;


        getItemCountCall(`${props.locationID}`, props.inventoryID, props.itemID, props.itemCountID)
            .then((data) => {
                setItemCountSummary(data);
            }).catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationID, props.inventoryID, props.itemID, getItemCountCall, props.itemCountID, setItemCountSummary, openPopup]);


    const getInventoryItemSiblings = useCallback(() => {

        if (!props.locationID || !props.inventoryID || !props.itemID) return;

        getItemSiblingsCall(`${props.locationID}`, props.inventoryID, props.itemID, tableQueryOptions)
            .then((data) => {
                setInventoryItemSiblings(data.items);
                setTotalSiblings(data.totalItems);
            }).catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationID, props.inventoryID, props.itemID, setTotalSiblings,
        getItemSiblingsCall, setInventoryItemSiblings, openPopup, tableQueryOptions]);


    const handleMoveCountButtonClicked = useCallback(() => {

        if (!props.locationID || !props.inventoryID || !props.itemID || !props.itemCountID || !selectedSiblingID) return;

        moveItemCountCall(`${props.locationID}`, props.inventoryID, props.itemID, props.itemCountID, { newInventoryItemParentId: selectedSiblingID })
            .then((_) => {
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("INVENTORY.ITEMCOUNTS.ItemCountMovedSuccessMessage")}
                    />
                );
                props.onCompletedOperations();
                closePopup();
            }).catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [props.locationID, props.inventoryID, props.itemID, selectedSiblingID,
        createNotification, props.onCompletedOperations, openPopup, moveItemCountCall, closePopup]);


    const handlePageAndItemsChanged = useCallback((rowsPerPage: number, newPage: number) => {
        setTableQueryOptions({
            page: newPage,
            pageLength: rowsPerPage
        });
    }, [setTableQueryOptions]);


    const handleClearFiltersButtonClicked = useCallback(() => {
        storageFormControl.setValue("");
        binFormControl.setValue("");
    }, [binFormControl.setValue, storageFormControl.setValue]);


    const columns: ColumnDefinition<InventoryItemDetails>[] = useMemo(() => [
        {
            cellRenderProp: (v) => <div className="info-box">
                <div className="info">SAP Doc. ID: <span>{v.id}</span></div>
                <div className="info">{translate("INVENTORY.ITEM.INFOANDFORM.Material") + ": "}<span>{v.materialNumber}</span></div>
                <div className="info">{translate("INVENTORY.ITEM.INFOANDFORM.Description") + ": "}<span>{v.materialDescription}</span></div>
                <div className="info">{translate("INVENTORY.ITEM.INFOANDFORM.Storage") + ": "}<span>{v.storage}<ConditionalRender if={!!v.bin}><small>/{v.bin}</small></ConditionalRender></span></div>
            </div>,
            headerRender: "Info",
            width: "30%",
            isMobilePrimaryCell: true
        },
        {
            cellRenderProp: (v: InventoryItemDetails) => v.specialSAP || v.typeSAP ?
                <div className="special-attributes">
                    <div className="special-sap">
                        <div className="label">Special:</div>
                        <div className="value"> {v.specialSAP ? v.specialSAP : "-"} </div>
                    </div>
                    <div className="sap-type">
                        <div className="label"> {translate("INVENTORY.ITEM.INFOANDFORM.SapTypeId") + ":"} </div>
                        <div className="value"> {v.typeSAP ? v.typeSAP : "-"} </div>
                    </div>
                </div> : null,
            headerRender: translate("INVENTORY.ITEM.INFOANDFORM.Attributes"),
            width: "8%"
        },
        {
            cellRenderProp: (v: InventoryItemDetails) => v.quantitySAP,
            headerRender: translate("INVENTORY.ITEM.INFOANDFORM.SAPQuantity"),
            width: "8%"
        },
        {
            cellRenderProp: (v: InventoryItemDetails) => v.quantityCounted,
            headerRender: translate("INVENTORY.ITEM.INFOANDFORM.CountedQuantity"),
            width: "8%"
        },
        {
            cellRenderProp: (v: InventoryItemDetails) => v.units,
            headerRender: translate("INVENTORY.ITEM.INFOANDFORM.Unit"),
            width: "5%"
        },
        {
            cellRenderProp: (v) => v.quantityDeviationPercentage || v.monetaryDeviationValue ?
                <div className="deviation">
                    <div className="qty-difference-block">
                        <div className="label">{translate("INVENTORY.DEVIATION.INFOANDFORM.Quantity") + ":"}</div>
                        <div className={v.isProblematic ? "value-red" : undefined}>
                            {v.quantityDeviationPercentage ? roundNumber(v.quantityDeviationPercentage, 2) + "%" : "-"}
                        </div>
                    </div>
                    <div className="monetary-block">
                        <div className="label">{translate("INVENTORY.DEVIATION.INFOANDFORM.Monetary") + ":"}</div>
                        <div className={v.isProblematic ? "value-red" : undefined}>
                            {v.monetaryDeviationValue ? roundNumber(v.monetaryDeviationValue, 2) + "€" : "-"}
                        </div>
                    </div>
                </div> : null,
            headerRender: translate("INVENTORY.ITEM.INFOANDFORM.Deviation"),
            width: "10%"
        },
        {
            cellRenderProp: (v: InventoryItemDetails) => {
                if (v.state) {
                    return <Tag
                        text={v?.state ? translate(InventoryItemStatusTranslationTerms[v?.state]) : ""
                        }
                        backgroundColor={v?.state ? getInventoryItemStatusCss(v?.state) : undefined}
                        isTiny={windowResize < 1024}
                    />
                }
                return null;
            },
            headerRender: translate("INVENTORY.Status"),
            width: "8%",
        }
    ], [windowResize]);



    const renderCardContainerInfo = useMemo(() => {
        return <FormSection unsetFlexGrow>
            <InfoBlock
                label={translate("INVENTORY.ITEM.INFOANDFORM.Quantity")}
                value={itemCountSummary?.quantity ? "" + itemCountSummary?.quantity : "-"}
            />
            <InfoBlock
                label={translate("INVENTORY.ITEM.INFOANDFORM.Rack") + "/" + translate("INVENTORY.ITEM.INFOANDFORM.Shelf")}
                value={itemCountSummary?.rackShelf ? "" + itemCountSummary?.rackShelf : "-"}
            />
            <InfoBlock
                label={translate("INVENTORY.ITEMCOUNTS.RegistedDate")}
                value={itemCountSummary?.createdDate ? "" + DateTimeHelper.formatDateTime(itemCountSummary.createdDate) : "-"}
            />
        </FormSection>
    }, [itemCountSummary]);


    useEffect(() => {
        const upperRowNode = upperRowRef.current;
        const bottomRowNode = bottomRowRef.current;

        if (upperRowNode && bottomRowNode) {
            let hasWrapOccurred = Array.from(upperRowNode.children, child => child as HTMLElement).some((child, i, arr) => {
                return i > 0 && child.offsetTop > arr[i - 1].offsetTop;
            });

            while (hasWrapOccurred) {
                const lastChild = upperRowNode.lastElementChild;
                setIsFiltersWrapped(true);
                if (lastChild) {
                    upperRowNode.removeChild(lastChild);
                    bottomRowNode.prepend(lastChild);
                }
                hasWrapOccurred = Array.from(upperRowNode.children, child => child as HTMLElement).some((child, i, arr) => {
                    return i > 0 && child.offsetTop > arr[i - 1].offsetTop;
                });
            }
        }
    }, [windowResize]);


    const renderFilters = useMemo(() => {
        return <div className="filters">
            <div className="upper-row-filters" ref={upperRowRef}>
                <FormFieldTextInput
                    formControl={storageFormControl}
                    placeholder={translate("INVENTORY.Storage")}
                    icon={<SearchIcon />}
                    className="search-box"
                />
                <FormFieldTextInput
                    formControl={binFormControl}
                    placeholder={translate("INVENTORY.ITEM.INFOANDFORM.Bin")}
                    icon={<SearchIcon />}
                    className="search-box"
                />
            </div>
            <div className="action-button-filters">
                {isFiltersWrapped ? <IconButton
                    icon={isFiltersPanelExpanded ? <ArrowUp /> : <ArrowDown />}
                    type="tertiary"
                    onClick={() => setIsFiltersPanelExpanded(!isFiltersPanelExpanded)}
                    className="view-more-filters-btn"
                /> : null}
                <Tooltip text={translate("COMMON.FORM.CleanFilters")}>
                    <IconButton
                        icon={<CleanIcon />}
                        type="secondary"
                        onClick={handleClearFiltersButtonClicked}
                        className="reset-filters-btn"
                    />
                </Tooltip>
            </div>
            <div className={"bottom-row-filters" + (isFiltersPanelExpanded ? " expanded" : "")} ref={bottomRowRef}>
            </div>
        </div>
    }, [storageFormControl, binFormControl, handleClearFiltersButtonClicked, isFiltersPanelExpanded, isFiltersWrapped]);


    return (
        <PopupContainer className="popup-move-item-count">
            {moveItemCountCallIsLoading ? <FullScreenLoader /> : null}
            <PopupHeader title={translate("INVENTORY.ITEMCOUNTS.MoveCount")}
            />
            <PopupContent isLoading={getItemCountCallIsLoading || getItemSiblingsCallIsLoading}>

                <TitledCardContainer className="selected-item-count-container" title={translate("INVENTORY.ITEMCOUNTS.ItemCount")}>
                    {renderCardContainerInfo}
                </TitledCardContainer>
                <Spacer mode="vertical" px={20} />

                <div className="target-inventory-item-title">
                    <div className="subtitle">{translate("INVENTORY.ITEMCOUNTS.InventoryItemTarget")}</div>
                    <div>{translate("INVENTORY.ITEMCOUNTS.SelectTarget")}</div>
                </div>

                <Spacer mode="vertical" px={16} />
                {renderFilters}
                <Spacer mode="vertical" px={12} />
                {windowResize < 768 ? null :
                    <Tag text={translate("INVENTORY.ITEM.MESSAGES.WarningMoveItems")} backgroundColor="status-yellow" isTiny icon={<WarningIcon />} />
                }
                <ResponsiveDataTable
                    key={tableRenderId}
                    className="move-counts-table"
                    columnDefinitions={columns}
                    items={inventoryItemSiblings || []}
                    totalitems={totalSiblings || 0}
                    currentpage={tableQueryOptions.page}
                    onPageAndItemsChanged={handlePageAndItemsChanged}
                    paginationOptions={{ itemsPerPage: tableQueryOptions.pageLength || 0, itemsPerPageOptions: [3, 5] }}
                    showSelectedRowHighlighted
                    onClickRow={(sibling) => {
                        if (sibling.state === InventoryItemStatusEnum.CLOSED || sibling.state === InventoryItemStatusEnum.VALIDATED) {
                            setTableRenderID(tableRenderId + 1);
                            setSelectedSiblingID(undefined);

                        } else if (sibling.id === selectedSiblingID)
                            setSelectedSiblingID(undefined);
                        else
                            setSelectedSiblingID(sibling.id)
                    }}
                />

                <Spacer mode={"vertical"} px="30" />

                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("INVENTORY.ITEMCOUNTS.Move"),
                            type: "primary",
                            onClick: handleMoveCountButtonClicked
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
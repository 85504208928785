import { InventoryStorageSummary } from "../../models/domain/storage/InventoryStorageSummary";
import { ResponsiveBullet } from "@nivo/bullet";

const rangeColors = ["#b9b8b8", "#fded94", "#8fcae7", "#008ABA", "#51af4c"]
const nullTooltip = () => <></>

export const BulletGraph = (v: InventoryStorageSummary) => {

    const notCounted = v.totalNotStarted || 0.00001;
    const inCounting = v.totalInCounting || 0.00001;
    const readyValidated = v.totalReadyToBeValidated || 0.00001;
    const validated = v.totalValidated || 0.00001;
    const closed = v.totalClosed || 0.00001;

    
    const cumulativeInCounting = notCounted + (inCounting - readyValidated);
    const cumulativeReadyValidated = cumulativeInCounting + readyValidated;
    const cumulativeValidated = cumulativeReadyValidated + validated;
    const cumulativeClosed = cumulativeValidated + closed;

    console.log({storage: v.code, notCounted, inCounting, readyValidated, validated, closed})

    return (<div className="bullet-graph">
        <ResponsiveBullet
            data={[
                {
                    "id": "",
                    "ranges": [
                        notCounted,
                        cumulativeInCounting,
                        cumulativeReadyValidated,
                        cumulativeValidated,
                        cumulativeClosed,
                    ],
                    "measures": [0],
                }
            ]}
            rangeColors={rangeColors}
            tooltip={nullTooltip}

        />
    </div>)
}
import { InventoryItemHistoryDetails } from "../domain/inventory-item-history/InventoryItemHistoryDetails";
import { InventoryItemHistoryDetailsDTO } from "../dtos/InventoryItemHistoryDTOs/InventoryItemHistoryDetailsDTO";

export function APIInventoryItemHistoryDetailsToDomainMapper(apiDto: InventoryItemHistoryDetailsDTO): InventoryItemHistoryDetails {
    return {
        inventoryItemHistoryId: apiDto.inventoryItemHistoryId,
        operationAt: apiDto.operationAt,
        userName: apiDto.userName,
        userEmail: apiDto.userEmail,
        historyLineContent: apiDto.historyLineContent,
        parentInventoryItemId: apiDto.parentInventoryItemId,
    }
}
export enum InventoryStatusEnum {
    UNKNOWN = 0,
    REGISTERED = 1,
    ONGOING = 2,
    CLOSED = 3,
    CANCELED = 4,
}

export const InventoryStatusTranslationTerms: { [key: string | number]: string } = {
    0: "INVENTORY.STATUS.Unknown",
    1: "INVENTORY.STATUS.Registered",
    2: "INVENTORY.STATUS.OnGoing",
    3: "INVENTORY.STATUS.Closed",
    4: "INVENTORY.STATUS.Canceled",
}
import "./MaterialsHomePage.css";

import { ColumnDefinition, IOrderInfo } from "../../../lib/components/table/TableInterfaces";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCreateNotification, usePopup } from "../../../lib/infrastructure/ui/UIServices";

import { AddMaterialPopup } from "./AddMaterialPopup";
import { Button } from "../../../lib/components/buttons/Button";
import { ReactComponent as EditIcon } from "../../../lib/assets/icons/editar.svg";
import { EditMaterialPopup } from "./EditMaterialPopup";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { ImportLoader } from "../../../lib/components/loader/ImportLoader";
import { ImportPopup } from "../../../lib/components/popup/ImportPopup";
import { Material } from '../../../models/domain/materials/Material';
import { MaterialsQueryOptions } from "../../../models/api/materials/MaterialsQueryOptions";
import { MaterialsService } from "../../../services/MaterialsService";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../lib/components/buttons/ScalableIconButton";
/***** ICONS ******/
import { ReactComponent as SearchIcon } from "../../../lib/assets/icons/search-icon.svg";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../lib/components/form/Form";
import { useParams } from "react-router-dom";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { formatNumber, replacePointByComma } from "../../../common/helpers/RoundNumber";

var materialService = new MaterialsService();

export function MaterialsHomePage() {
    const { id: locationId } = useParams();

    const openPopup = usePopup();
    const createNotification = useCreateNotification();


    const [materials, setMaterials] = useState<Material[]>();
    const [totalMaterials, setTotalMaterials] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [searchWord, setSearchWord] = useState<string>();

    const [currentpage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);


    const [isLoadingImport, setIsLoadingImport] = useState(false);

    const [timer, setTimer] = useState<NodeJS.Timeout>();

    var searchBoxFormControl = useFormControl<string>({
        isDisabled: false,
        enableAutoValidate: false,
    });



    useEffect(() => {
        getMaterials();
    }, [currentpage, itemsPerPage, searchWord]);



    useEffect(() => {
        if (timer) {
            clearTimeout(timer);
        }
        var responseTimeOut = setTimeout(() => {
            if (timer) {
                setCurrentPage(0);
                setSearchWord(searchBoxFormControl.value);
            }
        }, 1000);

        setTimer(responseTimeOut);
    }, [searchBoxFormControl.value]);



    const getMaterials = useCallback(() => {
        if (locationId) {
            setIsLoading(true);

            var queryOptions: MaterialsQueryOptions = {
                page: currentpage,
                pageLength: itemsPerPage,
                searchWord: searchWord,
                filterByIsActive: false,
            };

            if (locationId) {
                materialService
                    .getMaterials(locationId, queryOptions)
                    .then((response) => {
                        setMaterials(response.materials);
                        setTotalMaterials(response.totalMaterials);
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        if (!error) return;
                        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
                    });
            }
        }
    }, [
        locationId,
        openPopup,
        setMaterials,
        setTotalMaterials,
        setIsLoading,
        currentpage,
        itemsPerPage,
        searchWord,
    ]);



    const handleFileImport = useCallback((file: File) => {
        if (!locationId) return;

        setIsLoadingImport(true);
        materialService.uploadMaterialFile(locationId, file).then(res => {
            setIsLoadingImport(false);
            getMaterials();
            createNotification(
                <Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate(
                        "GLOBALADMIN.MATERIALS.ImportMaterialsSuccess"
                    )}
                />
            );
        }).catch(error => {
            setIsLoadingImport(false)
            openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });

    }, [locationId, createNotification, openPopup, setIsLoadingImport, getMaterials]);



    const handleOnOrderChanged = useCallback((orderedPriorities: IOrderInfo[]) => {
    }, []);



    const handleOnPageAndItemsChanged = useCallback(
        (items: number, currentPage: number) => {
            setItemsPerPage(items);
            setCurrentPage(currentPage);
        },
        [setItemsPerPage, setCurrentPage]
    );



    const renderEditMaterialPopup = useCallback((material: Material) => {
        if (locationId && material.materialId)
            return openPopup(
                <EditMaterialPopup
                    onChangesMade={getMaterials}
                    locationId={"" + locationId}
                    materialId={material.materialId}
                ></EditMaterialPopup>
            );
        return null;
    }, [openPopup, getMaterials, locationId]);



    const materialColumnsList: ColumnDefinition<Material>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => v.name,
                width: "15%",
                headerRender: <>{translate("GLOBALADMIN.MATERIALS.INFO.SAPNumber")}</>,
                columnKey: "name",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => v.description,
                width: "40%",
                headerRender: (
                    <>{translate("GLOBALADMIN.MATERIALS.INFO.Description")}</>
                ),
                columnKey: "description",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => v.units,
                width: "20%",
                headerRender: (<>{translate("GLOBALADMIN.MATERIALS.INFO.Units")}</>),
                columnKey: "units",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => v.unitValue ? replacePointByComma(v.unitValue) + " €" : "-",
                width: "20%",
                headerRender: (<>{translate("GLOBALADMIN.MATERIALS.INFO.Value")}</>),
                columnKey: "units",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => (
                    <ScalableIconButton
                        icon={<EditIcon />}
                        onClick={() => {
                            renderEditMaterialPopup(v);
                        }}
                        size={30}
                    />
                ),
                width: "3%",
                columnKey: "stats",
                isSortable: false,
            },
        ],
        [renderEditMaterialPopup]);



    return (
        <PageLayout
            tabTitle={`${translate("INVENTORY.Inventory")} - ${translate("GLOBALADMIN.MATERIALS.Materials")}`}
            pageTitle={translate("GLOBALADMIN.MATERIALS.Materials")}
            className="materials-home"
            showBreadcrumb
            actions={
                <>
                    <Button
                        text={translate("COMMON.Import")}
                        type="secondary"
                        onClick={() => openPopup(<ImportPopup onClickDownloadTemplateBtn={() =>

                            window.open(ConfigurationProvider.getConfiguration().App.BackendUrl + "/templates/VLMT_Inventory_Materials_Template.xlsx", "_blank")}
  
                            onClickImportBtn={handleFileImport} />)}
                        className="import-material"
                    />
                    <Button
                        text={translate("COMMON.Create")}
                        type="primary"
                        onClick={() => {
                            if (locationId) {
                                openPopup(
                                    <AddMaterialPopup
                                        locationId={"" + locationId}
                                        onChangesMade={getMaterials}
                                    />
                                );
                            }
                        }}
                        className="create-material"
                    />
                </>
            }
        >
            <div className="command-bar">
                <FormFieldTextInput
                    formControl={searchBoxFormControl}
                    icon={<SearchIcon />}
                    placeholder={translate("COMMON.FORM.SearchPlaceholder")}
                    className="search-box"
                />
                {isLoadingImport ? <ImportLoader text={translate("GLOBALADMIN.MATERIALS.ImportLoading")} /> : null}
            </div>
            <ResponsiveDataTable
                columnDefinitions={materialColumnsList}
                items={materials || []}
                totalitems={totalMaterials || 0}
                paginationOptions={{
                    itemsPerPage: itemsPerPage,
                    itemsPerPageOptions: [10, 20, 30, 40, 50],
                }}
                currentpage={currentpage}
                isLoading={isLoading}
/*                 onOrderChanged={handleOnOrderChanged} */
                onPageAndItemsChanged={handleOnPageAndItemsChanged}
                onClickRow={renderEditMaterialPopup}
            ></ResponsiveDataTable>

            {/* <ConditionalRender if={isLoadingImport} >
                <FullScreenLoader />
            </ConditionalRender> */}
        </PageLayout>
    );
}

import './InventoryItemHistory.css';

import { Avatar } from "../../../../lib/components/avatar/Avatar";
import { DateTimeHelper } from "../../../../lib/helpers/datetime/DateTime";
import { InventoryItemHistoryDetails } from "../../models/domain/inventory-item-history/InventoryItemHistoryDetails";
import { Spacer } from "../../../../lib/components/separator/Spacer";

export interface IHistoryLineProps {
    locationId: string;
    inventoryID: string;
    inventoryItemID: string;
    historyLines: InventoryItemHistoryDetails[];
}



export function InventoryItemHistory(props: IHistoryLineProps) {


    /****************************
     * CSS & HTML
    *****************************/



    return <>
        {props.historyLines?.map((historyLine) =>
            <div className="history">
                <Avatar className="history-avatar" name={historyLine.userName} />
                <div className="history-info">
                    <div className="small-copy operated-by">{`${historyLine.userName} ${DateTimeHelper.formatDateTime(historyLine.operationAt)}`}</div>
                    <div className="history-line-text">{historyLine.historyLineContent}</div>
                    <div className="separator"></div>
                    <Spacer mode={"vertical"} px="16" />
                </div>
            </div>
        )}</>
}
import "./AddPdaOperatorPopup.css"; 

import { useCallback, useEffect, useState } from "react";
import { Optional, ValidatorFunction, useFormControl } from "../../../../lib/components/form/Form";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { validateEmptyStrings } from "../../../../lib/validators/ValidateEmptyStrings";

import { AllValid } from "../../../../common/validators/ValidateFormControls";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { PdaOperatorService } from "../services/PdaOperatorService";
import { useServiceCallPro2 } from "../../../../lib/hooks/useServiceCall";
import { StorageManagersService } from "../../storage-managers/services/StorageManagersService";
import { StorageManagerBasicInfo } from "../../storage-managers/models/StorageManagerBasicInfo";
import { FormFieldSelectSingle } from "../../../../lib/components/form/form-field/FormFieldSelectSingle";

var svc = new PdaOperatorService();
var storageManagersSvc = new StorageManagersService();

const labelManagerNameSelector = (item: StorageManagerBasicInfo) => item.name ?? "-";
const idManagerIdSelector = (item: StorageManagerBasicInfo) => item.id;

export interface AddPdaOperatorPopupProps {
    locationID: string;
    onCompletedOperations: () => void;
}

export function AddPdaOperatorPopup(props: AddPdaOperatorPopupProps) {

    const createNotification = useCreateNotification();
    const closePopup = useClosePopup();
    const openPopup = usePopup();

    const [addPdaOperatorCall, addPdaOperatorCallIsLoading] = useServiceCallPro2(svc, svc.createPdaOperator);
    const [getStorageManagersCall, getStorageManagersCallIsLoading] = useServiceCallPro2(storageManagersSvc, storageManagersSvc.getAllStorageManagerStoragesByLocation);

    const [storageManagers, setStorageManagers] = useState<StorageManagerBasicInfo[]>([]);

    const nameFormControl = useFormControl<string>({
        validators: [validateEmptyStrings()],
        enableAutoValidate: true,
    });

    const storageManagersControl = useFormControl<StorageManagerBasicInfo>({});


    useEffect(() => {
        if (!props.locationID) return;

        getStorageManagersCall(props.locationID)
            .then((response) => {
                if (!response) return;
                setStorageManagers(response);
            })
            .catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });


    }, []);



    const handleAddButtonClicked = useCallback(() => {

        if (!AllValid(nameFormControl.validate())) return;

        addPdaOperatorCall(props.locationID, { name: nameFormControl.value ?? "", managerEmail: storageManagers.find(a => a.id === storageManagersControl.value?.id)?.email ?? undefined })
            .then((_) => {

                props.onCompletedOperations();
                closePopup();

                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("GLOBALADMIN.OPERATORS.NOTIFICATIONS.PdaOperatorAddedSuccessMessage")}
                    ></Notification>
                );
            })
            .catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [nameFormControl.validate, nameFormControl.value, closePopup, storageManagersControl.value?.id, storageManagers,
    props.onCompletedOperations, createNotification, props.locationID, addPdaOperatorCall, openPopup])





    return (
        <PopupContainer className="popup-add-pda-operator">
            {addPdaOperatorCallIsLoading ? <FullScreenLoader /> : null}

            <PopupHeader
                title={translate("GLOBALADMIN.OPERATORS.INFOANDFORM.AddPdaOperator")}
            />
            <PopupContent isLoading={getStorageManagersCallIsLoading}>
                <FormContainer>
                    <FormFieldTextInput
                        formControl={nameFormControl}
                        label={translate("GLOBALADMIN.OPERATORS.INFOANDFORM.OperatorName")}
                        placeholder="Ex: John Doe"
                    />

                    <FormFieldSelectSingle<StorageManagerBasicInfo>
                        label={translate("GLOBALADMIN.OPERATORS.INFOANDFORM.StorageManagerOperator")}
                        placeholder={translate("GLOBALADMIN.OPERATORS.INFOANDFORM.SelectOneManager")}
                        options={storageManagers || []}
                        labelSelector={labelManagerNameSelector}
                        idSelector={idManagerIdSelector}
                        formControl={storageManagersControl}
                        maxHeightOptions={"16rem"}
                        isClearSelectionAvailable
                    />

                </FormContainer>
                <Spacer mode={"vertical"} px="30"></Spacer>
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Create"),
                            type: "primary",
                            onClick: handleAddButtonClicked
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}

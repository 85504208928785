import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { AdminContainer } from "../common/layouts/content/AdminContainer";
import { AdminMainLayout } from "../common/layouts/admin-main-layout/AdminMainLayout";
import { AdministrationModule } from "./App.RoutingModule.AdministrationModule";
import { AppMainLayout } from "../common/layouts/app-main-layout/AppMainLayout";
import { InventoriesModule } from "./App.RoutingModule.InventoriesModule";
import { MainContainer } from "../common/layouts/content/MainContainer";
import { ModuleDefinition } from "../lib/infrastructure/modules/ModuleDefinition";
import { translate } from "../lib/infrastructure/i18n/InternationalizationService";
import { useRegisterBreadcrumbRoutes } from "../lib/infrastructure/navigation/hooks/useBreadcrumb";
import { useEffect } from "react";
import { Permission } from "../models/api/gate/Permission";

const MainTemplate = () => (
  <AppMainLayout>
    <MainContainer>
      <Outlet />
    </MainContainer>
  </AppMainLayout>
);

const AdminTemplate = () => (
  <AdminMainLayout>
    <AdminContainer>
      <Outlet />
    </AdminContainer>
  </AdminMainLayout>
);




export function AppRoutingModule() {
  return (
    <Routes>
      <Route element={<MainTemplate />}>
        <Route path="/" element={<Navigate to="/inventories" />} />
        <Route path="/inventories/*" element={<InventoriesModule />} />
      </Route>
      <Route element={<AdminTemplate />}>
        <Route path="/settings/*" element={<AdministrationModule />} />
      </Route>
      <Route path="/*" element={<Navigate to="/notfound" />} />
    </Routes>

  );
}

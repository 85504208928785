import { useMemo } from "react";
import { CssClassnameBuilder } from "../../utils/CssClassnameBuilder";
import "./InfoBlock.css";

interface IInfoBlockProps {
  label: string | "";
  value: string | "";
  backgroundColor?: "status-red" | undefined;
}

export function InfoBlock(props: IInfoBlockProps) {

  const blockCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("info-block")
      .addConditional(props.backgroundColor, props.backgroundColor)
      .build();
  }, [props.backgroundColor]);


  return (
    <div className={blockCss}>
      <div className="info-block-label">{props.label}</div>
      <div className="info-block-value">{props.value}</div>
    </div>
  );
}

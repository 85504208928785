import "./InventoryStorageLocationsPage.css";

import { IInventoryStatsIntrinsicBehavior, InventoryStatsSection } from "../../components/InventoryStatsSection";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";
import { useNavigate, useParams } from "react-router-dom";

import { BulletGraph } from "./BulletGraph";
import { Button } from "../../../../lib/components/buttons/Button";
import { ReactComponent as CloseIcon } from "../../../../lib/assets/icons/close-icon.svg";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { ConfigurationProvider } from "../../../../lib/infrastructure/configuration/ConfigurationProvider";
import { ConsultInventoryDeviationPopup } from "../deviation/ConsultInventoryDeviationPopup";
import { ContextualMenu } from "../../../../lib/components/contextual-menu/ContextualMenu";
import { DateTime } from "luxon";
import { ReactComponent as EditIcon } from "../../../../lib/assets/icons/editar.svg";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { ReactComponent as ExportIcon } from "../../../../lib/assets/icons/export.svg";
import { FormSection } from "../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { ReactComponent as ImportIcon } from "../../../../lib/assets/icons/import-dark.svg";
import { ImportLoader } from "../../../../lib/components/loader/ImportLoader";
import { ImportPopup } from "../../../../lib/components/popup/ImportPopup";
import { InfoBlock } from "../../../../lib/components/info-block/InfoBlock";
import { InvalidInventoryItemsPopup } from "../item-pages/popups/InvalidInventoryItemsPopup";
import { InventoriesService } from "../../services/InventoriesService";
import { InventoryDetails } from "../../models/domain/inventory/InventoryDetails";
import { InventoryItemsService } from "../../services/InventoryItemsService";
import { InventoryStatusEnum } from "../../models/dtos/InventoryDTOs/InventoryStatusEnum";
import { InventoryStatusTranslationTerms } from "../../models/domain/inventory/InventoryStatusEnum";
import { InventoryStorageSummary } from "../../models/domain/storage/InventoryStorageSummary";
import { ReactComponent as MoreActionsIcon } from "../../../../lib/assets/icons/more-actions.svg";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { Permission } from "../../../../models/api/gate/Permission";
import { QueryOptions } from "../../../../models/domain/materials/QueryOptions";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../../lib/components/buttons/ScalableIconButton";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { StorageService } from "../../services/InventoryStorageLocationsService";
import { Tag } from "../../../../lib/components/tag/Tag";
import { TitledCardContainer } from "../../../../lib/layouts/containers/card/TitledCardContainer";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import { getInventoryStatusCss } from "../../../../common/helpers/GetInventoryStatusCss";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useServiceCallPro } from "../../../../services/UseServiceCall";
import { useServiceCallPro2 } from '../../../../lib/hooks/useServiceCall';

const inventoryItemsSvc = new InventoryItemsService();
const storageSvc = new StorageService();
const inventorySvc = new InventoriesService();


function formatPercentage(value: number, total: number) {
    const percentage = total === 0 ? 0 : (value * 100 / total);
    return "" + Math.floor(percentage) + "%";
}


export const topPermissions = [
    Permission.GLOBAL_ADMIN,
    Permission.LOCAL_ADMIN,
    Permission.FINANCES
];


export const managerPermissions = [Permission.TEAM_MANAGER];



export function InventoryStorageLocationsPage() {
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();
    const navigate = useNavigate();
    const windowResize = useWindowResize();
    const { id: inventoryID } = useParams();
    const locationID = useGlobalLocation();


    const statsSectionRef = useRef<IInventoryStatsIntrinsicBehavior>(null);

    const [inventoryDetails, setInventoryDetails] = useState<InventoryDetails>();
    const [storageItemDetails, setStorageItemDetails] = useState<InventoryStorageSummary[]>([]);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [isLoadingImport, setIsLoadingImport] = useState(false);

    const { isLoadingPermissions, hasPermission } = useHasPermissions(topPermissions);
    const { isLoadingPermissions: isLoadingManagerPermissions, hasPermission: hasManagerPermission } = useHasPermissions(managerPermissions);


    /****************************
    *   service calls     
    *****************************/
    const putInventoryStateCall = useServiceCallPro(inventorySvc.updateInventoryState);
    const uploadFileCall = useServiceCallPro(inventoryItemsSvc.uploadInventoryItemsFromXLSX);
    const getInventoryByIDCall = useServiceCallPro(inventorySvc.getInventoryByID);
    const getStorageLocationsCall = useServiceCallPro(storageSvc.getStorageLocations);
    const [getDownloadReportUrl] = useServiceCallPro2(inventorySvc, inventorySvc.downloadUrlForInventoryExportReport);
    const [getDownloadAccessesUrl] = useServiceCallPro2(inventorySvc, inventorySvc.downloadUrlForInventoryAccesses);


    /****************************
          FILTER STATES
    *****************************/
    const [tableQueryOptions, setTableQueryOptions] = useState<QueryOptions>({ page: 0, pageLength: 10 });


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        getInventoryDetails();
    }, []);


    useEffect(() => {
        getStorageLocations();
    }, [tableQueryOptions]);


    /****************************
    * DATA REQUESTS
    *****************************/

    const getInventoryDetails = useCallback(() => {
        if (!locationID || !inventoryID) return;

        getInventoryByIDCall.invoke(`${locationID}`, inventoryID)
            .then((data) => {
                setInventoryDetails(data || undefined);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })
    }, [locationID, inventoryID, setInventoryDetails, openPopup, getInventoryByIDCall]);


    const getStorageLocations = useCallback(() => {

        if (!locationID || !inventoryID) return;

        getStorageLocationsCall.invoke(`${locationID}`, inventoryID, tableQueryOptions)
            .then((data) => {
                setStorageItemDetails(data.storageLocations);
                setTotalItems(data.totalItems);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })
    }, [locationID, inventoryID, tableQueryOptions, setStorageItemDetails, openPopup, getStorageLocationsCall]);


    const closeInventory = useCallback(() => {

        if (!locationID || !inventoryID) return;

        putInventoryStateCall.invoke(`${locationID}`, inventoryID, { state: InventoryStatusEnum.CLOSED })
            .then((_) => {
                getInventoryDetails();
                getStorageLocations();
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationID, inventoryID, getInventoryDetails, getStorageLocations, openPopup, putInventoryStateCall]);



    const handleFileImport = useCallback((file: File) => {
        if (!locationID || !inventoryID) return;

        setIsLoadingImport(true);
        uploadFileCall.invoke(`${locationID}`, inventoryID, file).then(res => {
            setIsLoadingImport(false);
            getStorageLocations();
            getInventoryDetails();
            statsSectionRef.current?.refresh();
            createNotification(
                <Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("INVENTORY.ITEM.ImportItemsSuccess")}
                />
            );
            if (res.invalidItemsCount > 0)
                openPopup(<InvalidInventoryItemsPopup>{translate("INVENTORY.POPUP.InvalidItemsCount", res.invalidItemsCount.toString())}</InvalidInventoryItemsPopup>)
        }).catch(error => {
            setIsLoadingImport(false);
            if (!error) return;
            openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }, [locationID, createNotification, uploadFileCall, openPopup, inventoryID, getStorageLocations, getInventoryDetails, statsSectionRef, statsSectionRef.current]);


    /****************************
     * USER ACTIONS
     *****************************/

    const handleOnPageAndItemsChanged = useCallback((items: number, currentPage: number) => {
        setTableQueryOptions({
            page: currentPage,
            pageLength: items
        });
    }, [setTableQueryOptions]);


    const handleOnExportButtonClicked = useCallback(() => {
        if (!locationID || !inventoryID) return;
        getDownloadReportUrl(`${locationID}`, inventoryID).then(url => window.open(url, "_blank"));

    }, [getDownloadReportUrl, inventoryID, locationID]);


    const handleOnExportAccessesButtonClicked = useCallback(() => {
        if (!locationID || !inventoryID) return;
        getDownloadAccessesUrl(`${locationID}`, inventoryID).then(url => window.open(url, "_blank"));
    }, [getDownloadAccessesUrl, inventoryID, locationID]);


    /****************************
     * CSS & HTML
     *****************************/


    const columns: ColumnDefinition<InventoryStorageSummary>[] = useMemo(() => [
        {
            cellRenderProp: (v) => v.name ? v.code + " - " + v.name : v.code,
            headerRender: translate("INVENTORY.STORAGE.INFOANDFORM.SAPLoc"),
            isMobilePrimaryCell: true
        },
        {
            cellRenderProp: (v) => v.totalItens,
            headerRender: translate("INVENTORY.STORAGE.INFOANDFORM.TotalItems"),
        },
        {
            cellRenderProp: (v) => <Tag className="percentage-tag" backgroundColor="status-light-grey" text={v.totalNotStarted === 0 && v.totalStarted === 0 ? "-" : formatPercentage(v.totalNotStarted, v.totalItens)} />,
            headerRender: translate("INVENTORY.STORAGE.INFOANDFORM.NotStarted"),
        },
        {
            cellRenderProp: (v) => <Tag className="percentage-tag" backgroundColor="status-yellow" text={v.totalNotStarted === 0 && v.totalStarted === 0 ? "-" : formatPercentage(v.totalStarted, v.totalItens)} />,
            headerRender: translate("INVENTORY.STORAGE.INFOANDFORM.Started"),
        },
        {
            cellRenderProp: (v) => <Tag className="percentage-tag" backgroundColor="status-deep-blue" text={formatPercentage(v.totalValidatedCumulative, v.totalItens)} />,
            headerRender: translate("INVENTORY.STORAGE.INFOANDFORM.ValidatedCumulative"),
        },
        {
            cellRenderProp: (v) => <Tag className="percentage-tag" backgroundColor="status-green" text={formatPercentage(v.totalClosed, v.totalItens)} />,
            headerRender: translate("INVENTORY.STORAGE.INFOANDFORM.Closed"),
        },
        {
            cellRenderProp: BulletGraph,
            headerRender: translate("INVENTORY.STORAGE.INFOANDFORM.Status"),
            isStatusCell: true,
        },
    ], []);




    const renderCardContainerInfo = useMemo(() => {
        if (!inventoryDetails?.name) return <></>;
        return <FormSection unsetFlexGrow>
            <InfoBlock
                label={translate("INVENTORY.Name")}
                value={inventoryDetails?.name || ""}
            />
            <InfoBlock
                label={translate("INVENTORY.InitialDate")}
                value={inventoryDetails?.startDate ? DateTime.fromISO(inventoryDetails?.startDate).toFormat("dd/MM/yyyy") : ""}
            />
            <InfoBlock
                label={translate("INVENTORY.FinalDate")}
                value={inventoryDetails?.endDate ? DateTime.fromISO(inventoryDetails?.endDate).toFormat("dd/MM/yyyy") : ""}
            />
        </FormSection>
    }, [inventoryDetails?.name, inventoryDetails?.startDate, inventoryDetails?.endDate]);








    const renderInventoryStateTag = useMemo(() => {
        if (!inventoryDetails?.status) return <></>;
        return <Tag
            text={inventoryDetails?.status ? translate(InventoryStatusTranslationTerms[inventoryDetails?.status]) : ""}
            backgroundColor={inventoryDetails?.status ? getInventoryStatusCss(inventoryDetails.status) : undefined}
        />

    }, [inventoryDetails]);



    const renderActionButtons = useMemo(() => {

        return windowResize > 768 ?
            <>

                <ContextualMenu
                    items={[
                        {
                            label: translate("INVENTORY.DEVIATION.ViewDeviation"),
                            icon: <EditIcon />,
                            navigateTo: "",
                            onClick: () => {
                                if (!locationID || !inventoryID) return;
                                openPopup(<ConsultInventoryDeviationPopup
                                    inventoryID={"" + inventoryID}
                                    locationID={"" + locationID}
                                    inventoryName={inventoryDetails?.name}
                                    onCompletedOperations={() => { }} />)
                            }
                        },


                        {
                            label: translate("INVENTORY.ExportAccesses"),
                            icon: <ExportIcon />,
                            isVisible: !getInventoryByIDCall.isLoading && (inventoryDetails?.status === InventoryStatusEnum.REGISTERED || inventoryDetails?.status === InventoryStatusEnum.ONGOING) && hasPermission && !isLoadingPermissions,
                            navigateTo: "",
                            onClick: handleOnExportAccessesButtonClicked,
                        },

                        {
                            label: translate("INVENTORY.CloseInventory"),
                            icon: <CloseIcon />,
                            navigateTo: "",
                            isVisible: !isLoadingPermissions && hasPermission && !getInventoryByIDCall.isLoading && (inventoryDetails?.status !== InventoryStatusEnum.CLOSED && inventoryDetails?.status !== InventoryStatusEnum.CANCELED),
                            onClick: () => {
                                openPopup(
                                    <WarningPopup
                                        onDismissClicked={() => { closePopup(); }}
                                        onConfirmClicked={closeInventory}
                                        dismissText={translate("COMMON.Cancel")}
                                        confirmText={translate("INVENTORY.CloseInventory")}
                                    >
                                        {translate("INVENTORY.POPUP.CloseInventoryConfirmMessage", inventoryDetails?.name || "")}
                                    </WarningPopup>)
                            }
                        },
                    ]}
                >
                    <ScalableIconButton size={40} icon={<MoreActionsIcon />} />
                </ContextualMenu>

                {!isLoadingPermissions && hasPermission ?
                    <Button
                        text={translate("INVENTORY.Export")}
                        type="secondary"
                        onClick={handleOnExportButtonClicked}
                    />
                    : null}

                {!isLoadingManagerPermissions && hasManagerPermission && (inventoryDetails?.status === InventoryStatusEnum.REGISTERED || inventoryDetails?.status === InventoryStatusEnum.ONGOING)
                    ?
                    <>
                        <Button
                            text={translate("INVENTORY.Export")}
                            type="secondary"
                            onClick={handleOnExportButtonClicked}
                        />
                        <Button text={translate("INVENTORY.ExportAccesses")}
                            type="secondary"
                            onClick={handleOnExportAccessesButtonClicked} />

                    </>
                    : null}

            </>
            :
            <>

                <ContextualMenu
                    items={[
                        {
                            label: translate("INVENTORY.Export"),
                            icon: <ExportIcon />,
                            navigateTo: "",
                            onClick: handleOnExportButtonClicked,
                            isVisible: !isLoadingPermissions && hasPermission && inventoryDetails?.status !== InventoryStatusEnum.REGISTERED
                        },
                        {
                            label: translate("INVENTORY.DEVIATION.ViewDeviation"),
                            icon: <EditIcon />,
                            navigateTo: "",
                            onClick: () => {
                                if (!locationID || !inventoryID) return;
                                openPopup(<ConsultInventoryDeviationPopup
                                    inventoryID={"" + inventoryID}
                                    locationID={"" + locationID}
                                    inventoryName={inventoryDetails?.name}
                                    onCompletedOperations={() => { }} />)
                            }
                        },
                        {
                            label: translate("INVENTORY.ExportAccesses"),
                            isVisible: !getInventoryByIDCall.isLoading && inventoryDetails?.status !== InventoryStatusEnum.CLOSED && inventoryDetails?.status !== InventoryStatusEnum.CANCELED,
                            icon: <ExportIcon />,
                            navigateTo: "",
                            onClick: handleOnExportAccessesButtonClicked,
                        },
                        {
                            label: translate("INVENTORY.CloseInventory"),
                            isVisible: !isLoadingPermissions && hasPermission && !getInventoryByIDCall.isLoading && (inventoryDetails?.status !== InventoryStatusEnum.CLOSED && inventoryDetails?.status !== InventoryStatusEnum.CANCELED),
                            icon: <CloseIcon />,
                            navigateTo: "",
                            onClick: () => {
                                openPopup(
                                    <WarningPopup
                                        onDismissClicked={() => { closePopup(); }}
                                        onConfirmClicked={closeInventory}
                                        dismissText={translate("COMMON.Cancel")}
                                        confirmText={translate("INVENTORY.CloseInventory")}
                                    >
                                        {translate("INVENTORY.POPUP.CloseInventoryConfirmMessage", inventoryDetails?.name || "")}
                                    </WarningPopup>)
                            }
                        },
                    ]}
                >
                    <ScalableIconButton size={30} icon={<MoreActionsIcon />} />
                </ContextualMenu>
            </>
    }, [handleOnExportButtonClicked, handleFileImport, handleOnExportAccessesButtonClicked, closePopup, closeInventory, openPopup, hasPermission, isLoadingPermissions, hasManagerPermission, isLoadingManagerPermissions,
        inventoryDetails, inventoryID, locationID, windowResize, getInventoryByIDCall.isLoading, inventoryDetails?.status]);




    const renderStatsCards = useMemo(() => {
        if (windowResize > 1200)
            return <InventoryStatsSection ref={statsSectionRef} locationId={`${locationID}`} inventoryId={inventoryID} />;
    }, [windowResize, inventoryID, locationID])




    return (
        <PageLayout
            tabTitle={translate("INVENTORY.StoragePlural")}
            pageTitle={translate("INVENTORY.StoragePlural")}
            className="inventory-storage-locations"
            showBreadcrumb
            actions={renderActionButtons}
        >

            {putInventoryStateCall.isLoading || uploadFileCall.isLoading ? <FullScreenLoader /> : null}

            <div className="inventory-general-info">
                <TitledCardContainer
                    title={translate("INVENTORY.InventoryData")}
                    className="inventory-data"
                    extraTitle={renderInventoryStateTag}
                    isLoading={getInventoryByIDCall.isLoading} >
                    <Spacer mode="vertical" px="16" />
                    {renderCardContainerInfo}
                </TitledCardContainer >
                {renderStatsCards}
            </div>

            <Spacer mode="vertical" px="16" />

            {isLoadingImport ? <ImportLoader text={translate("INVENTORY.ITEM.ImportLoading")} /> : null}

            <ResponsiveDataTable
                className="storages-table"
                columnDefinitions={columns}
                items={storageItemDetails || []}
                isLoading={getStorageLocationsCall.isLoading}
                totalitems={totalItems}
                currentpage={tableQueryOptions.page}
                onClickRow={(storage) => { navigate(storage.code) }}
                paginationOptions={{ itemsPerPage: tableQueryOptions.pageLength || 0, itemsPerPageOptions: [10, 20, 30, 40, 50] }}
                onPageAndItemsChanged={handleOnPageAndItemsChanged}
            />

        </PageLayout >
    );
};
import "./AddInvItemCommentPopup.css";

import { CommentInfo, ItemComment } from "../components/ItemComment";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";
import { useCallback, useEffect, useMemo, useState } from "react";

import { FormContainer } from "../../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextArea } from "../../../../../lib/components/form/form-field/FormFieldTextArea";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useServiceCallPro2 } from "../../../../../lib/hooks/useServiceCall";
import { } from "../../../../../lib/validators/ValidateEmptyStrings";
import { validateNullStrings } from "../../../../../lib/validators/ValidateEmptyStringsN";
import { InventoryItemsService } from "../../../services/InventoryItemsService";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { DateTime } from "luxon";

var svc = new InventoryItemsService();

export interface IAddInvItemCommentPopupProps {
    locationID: string;
    inventoryID: string;
    inventoryItemID: string;
    canAddComment: boolean; 
    onCompletedOperations?: () => void;
}

export function AddInvItemCommentPopup(props: IAddInvItemCommentPopupProps) {
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();


    const [comments, setComments] = useState<CommentInfo[]>();

    const commentFormControl = useFormControl<string>({
        validators: [validateNullStrings()],
        enableAutoValidate: true,
    });

    const [getItemCommentsCall, getItemCommentsCallIsLoading] = useServiceCallPro2(svc, svc.getInventoryItemComments);
    const [addItemCommentCall, addItemCommentCallIsLoading] = useServiceCallPro2(svc, svc.addInventoryItemComment);

    useEffect(() => {

        getItemCommentsCall(props.locationID, props.inventoryID, props.inventoryItemID)
            .then((r) => {

                var mappedComments: CommentInfo[] = r.map((c) => ({
                    text: c.comment,
                    createdBy: c.createdBy,
                    createdDate: c.createdDate ? DateTime.fromISO(c.createdDate).toFormat("dd/MM/yyyy HH'h'mm") : "",
                }));

                setComments(mappedComments);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })
    }, []);



    const handleAddCommentBtnClicked = useCallback(() => {

        if (!commentFormControl.validate()) return;
        if (!props.locationID || !props.inventoryID || !props.inventoryItemID) return;

        addItemCommentCall(props.locationID, props.inventoryID, props.inventoryItemID, { comment: commentFormControl.value ?? null })
            .then((_) => {
                props.onCompletedOperations && props.onCompletedOperations();
                closePopup();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("INVENTORY.ITEM.MESSAGES.AddCommentCountSuccessMessage")}
                    ></Notification>
                );
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [props.locationID, props.inventoryID, props.inventoryItemID, props.onCompletedOperations, addItemCommentCall,
    commentFormControl.value, commentFormControl.validate, closePopup, openPopup, createNotification]);




    const renderComments = useMemo(() => {
        if (comments && comments?.length > 0)
            return <>
                <div className="comments">
                    {comments?.map((comment, idx) => <ItemComment key={idx} comment={comment} />)}
                </div>
                <Spacer mode="vertical" px={20} />
            </>
    }, [comments])


    return (
        <PopupContainer className="popup-add-item-comment">
            {addItemCommentCallIsLoading ? <FullScreenLoader /> : null}
            <PopupHeader
                title={props.canAddComment ? translate("INVENTORY.ITEM.AddComment") : translate("INVENTORY.ITEM.INFOANDFORM.SeeComments")}
            />
            <PopupContent isLoading={getItemCommentsCallIsLoading}>

                {renderComments}
                {props.canAddComment ?
                    <>
                        <FormContainer>
                            <FormFieldTextArea
                                formControl={commentFormControl}
                                label={translate("INVENTORY.ITEM.INFOANDFORM.SingularComment")}
                                placeholder={translate("INVENTORY.ITEM.INFOANDFORM.SingularCommentPlaceholder")}
                            />
                        </FormContainer>
                        <Spacer mode={"vertical"} px="30"></Spacer>
                        <PopupActionButtons
                            buttons={[
                                {
                                    text: translate("COMMON.Cancel"),
                                    type: "tertiary",
                                    onClick: () => closePopup(),
                                },
                                {
                                    text: translate("COMMON.Add"),
                                    type: "primary",
                                    onClick: handleAddCommentBtnClicked
                                },
                            ]}
                        />
                    </> : null}
            </PopupContent>
        </PopupContainer>
    );
}
import './ConsultInventoryDeviationPopup.css'

import { useCallback, useEffect, useState } from "react";
import { useClosePopup, usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { FormSection } from "../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { InventoriesService } from "../../services/InventoriesService";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../lib/components/separator/Spacer";

import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";

import { useServiceCallPro2 } from "../../../../lib/hooks/useServiceCall";
import { InfoBlock } from '../../../../lib/components/info-block/InfoBlock';

var svc = new InventoriesService();

export interface ConsultInventoryDeviationPopupProps {
    locationID: string;
    inventoryID: string;
    inventoryName?: string;
    onCompletedOperations: () => void;
}

export function ConsultInventoryDeviationPopup(props: ConsultInventoryDeviationPopupProps) {
    const openPopup = usePopup();
    const closePopup = useClosePopup();

    /****************************
     * Service Calls * 
    *****************************/



    const [getDeviationDefinitionCall, getDeviationDefinitionCallIsLoading] = useServiceCallPro2(svc, svc.getInventoryDeviationDefinition);



    /****************************
          FILTER STATES
    *****************************/

    const [qtyDifferencePercentageMinControl, setQtyDifferencePercentageMinControl] = useState<string>();
    const [qtyDifferencePercentageMaxControl, setQtyDifferencePercentageMaxControl] = useState<string>();
    const [monetaryDiferenceMinControl, setMonetaryDiferenceMinControl] = useState<string>();
    const [monetaryDiferenceMaxControl, setMonetaryDiferenceMaxControl] = useState<string>();


    /****************************
     * DATA MANIPULATION EFFECTS
    *****************************/



    useEffect(() => {
        getDeviationDefinition();
    }, []);



    /****************************
     * DATA REQUESTS * 
    *****************************/



    const getDeviationDefinition = useCallback(() => {
        if (!props.locationID || !props.inventoryID) return;

        getDeviationDefinitionCall(props.locationID, props.inventoryID)
            .then((r) => {
                if (r?.id) {
                    setQtyDifferencePercentageMinControl(r.quantityDifferencePercentageMin ?? "-");
                    setQtyDifferencePercentageMaxControl(r.quantityDifferencePercentageMax ?? "-");
                    setMonetaryDiferenceMinControl(r.monetaryDifferenceMin ?? "-");
                    setMonetaryDiferenceMaxControl(r.monetaryDifferenceMax ?? "-");
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [props.locationID, props.inventoryID, openPopup,
        setQtyDifferencePercentageMinControl, setQtyDifferencePercentageMaxControl, setMonetaryDiferenceMinControl, setMonetaryDiferenceMaxControl]);





    return (
        <PopupContainer className="popup-consult-inventory-deviation">
            {false ? <FullScreenLoader /> : null}
            <PopupHeader title={translate("INVENTORY.DEVIATION.ViewDeviation")} subtitle={props.inventoryName} />
            {getDeviationDefinitionCallIsLoading ? <FullScreenLoader /> : null}
            <PopupContent isLoading={getDeviationDefinitionCallIsLoading}>
                <FormContainer>
                    <FormSection isInputGap>
                        <InfoBlock value={qtyDifferencePercentageMinControl ?? "-"} label={translate("INVENTORY.DEVIATION.INFOANDFORM.PercentageOfMinimumQuantityDifference")} />
                        <InfoBlock value={qtyDifferencePercentageMaxControl ?? "-"} label={translate("INVENTORY.DEVIATION.INFOANDFORM.PercentageOfMaximumQuantityDifference")} />
                    </FormSection>
                    <FormSection isInputGap>
                        <InfoBlock value={monetaryDiferenceMinControl ?? "-"} label={translate("INVENTORY.DEVIATION.INFOANDFORM.MinimumMonetaryDifference")} />
                        <InfoBlock value={monetaryDiferenceMaxControl ?? "-"} label={translate("INVENTORY.DEVIATION.INFOANDFORM.MaximunMonetaryDifference")} />
                    </FormSection>
                </FormContainer>
                <Spacer mode={"vertical"} px="30"></Spacer>
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Close"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
import * as qs from "qs";
import { ConfigurationProvider } from "../../../../lib/infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../../../../lib/infrastructure/http/HttpClient";
import { PdaOperatorsQueryOptions } from "../models/api/PdaOperatorsQueryOptions";
import { PdaOperatorsResponseDTO } from "../models/api/PdaOperatorsResponseDTO";
import { PdaOperatorsResponse } from "../models/domain/PdaOperatorsResponse";
import { PdaOperator } from "../models/domain/PdaOperator";
import { PdaOperatorCreateDTO } from "../models/api/PdaOperatorCreateDTO";
import { PdaOperatorDTO } from "../models/api/PdaOperatorDTO";
import { PDAOperatorAssociateManagerDTO } from "../models/api/PDAOperatorAssociateManagerDTO";


const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};


export class PdaOperatorService {


    createPdaOperator(locationId: string, requestDto: PdaOperatorCreateDTO): Promise<void> {

        return HttpClient.sessionRequest({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/PdaOperators`),
            data: requestDto
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }


    getPdaOperatorById(locationID: string, operatorID: string): Promise<PdaOperator> {

        return HttpClient.sessionRequest<PdaOperatorDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationID}/PdaOperators/${operatorID}`),
        })
            .then((res) => {

                var mappedOperator: PdaOperator = {
                    pdaOperatorId: res.data.pdaOperatorId,
                    lastPasswordResetDate: res.data.lastPasswordResetDate,
                    name: res.data.name,
                    pass: res.data.pass,
                    storageManagerId: res.data.storageManagerId,
                    storageManagerName: res.data.storageManagerName ?? ""
                };

                return mappedOperator;

            }).catch((error) => {
                throw error;
            });
    }


    getPdaOperators(locationId: string, queryOptions?: PdaOperatorsQueryOptions): Promise<PdaOperatorsResponse> {

        return HttpClient.sessionRequest<PdaOperatorsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/PdaOperators`),
            params: queryOptions,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((res) => {
                var mappedOperators = res.data.operators.map(
                    (p): PdaOperator => ({
                        pdaOperatorId: p.pdaOperatorId,
                        name: p.name,
                        pass: p.pass,
                        lastPasswordResetDate: p.lastPasswordResetDate,
                        storageManagerId: p.storageManagerId,
                        storageManagerName: p.storageManagerName
                    })
                );
                return {
                    operators: mappedOperators,
                    total: res.data.total
                }
            }).catch((error) => {
                throw error;
            });
    }



    createOperatosAsCSV(locationId: string, file: File): Promise<void> {

        var formData = new FormData();
        formData.append("file", file)

        return HttpClient.sessionRequest({
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
            },
            url: Route(`/api/v1/Locations/${locationId}/PdaOperators/asXLSX`),
            data: formData
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }



    deleteOperator(locationId: string, pdaOperatorId: string): Promise<void> {

        return HttpClient.sessionRequest({
            method: "DELETE",
            url: Route(`/api/v1/Locations/${locationId}/PdaOperators/${pdaOperatorId}`),
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }




    resetPdaOperatorPassword(locationId: string, pdaOperatorId: string): Promise<void> {

        return HttpClient.sessionRequest({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/PdaOperators/${pdaOperatorId}/ResetPassword`),
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }


    removeManagerAssociation(locationId: string, pdaOperatorId: string): Promise<void> {

        return HttpClient.sessionRequest({
            method: "DELETE",
            url: Route(`/api/v1/Locations/${locationId}/PdaOperators/${pdaOperatorId}/StorageManager`),
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }


    associateManagerToPdaOperator(locationId: string, pdaOperatorId: string, storageManagerID: number): Promise<void> {

        var requestDto: PDAOperatorAssociateManagerDTO = {
            storageManagerID: storageManagerID
        }

        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/PdaOperators/${pdaOperatorId}/StorageManager`),
            data: requestDto
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }
}

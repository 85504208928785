import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { InventoriesHomePage } from "../features/inventory-mock/pages/inventory-pages/InventoriesHomePage";
import { InventoryContainer } from "../features/inventory-mock/pages/layout/InventoryContainer";
import { InventoryItemCountsPage } from "../features/inventory-mock/pages/item-pages/InventoryItemCountsPage";
import { InventoryItemsPage } from "../features/inventory-mock/pages/item-pages/InventoryItemsPage";
import { InventoryStorageDetailsPage } from "../features/inventory-mock/pages/storages-pages/InventoryStorageDetailsPage";
import { InventoryStorageLocationsPage } from "../features/inventory-mock/pages/storages-pages/InventoryStorageLocationsPage";
import { ModuleDefinition } from "../lib/infrastructure/modules/ModuleDefinition";
import { PdaSimulatorPage } from '../features/inventory-mock/pages/pda-simulator/PdaSimulatorPage';
import { Permission } from "../models/api/gate/Permission";
import { RouteGuard } from "../lib/infrastructure/authorization/RouteGuard";
import { translate } from "../lib/infrastructure/i18n/InternationalizationService";
import { useEffect } from "react";
import { useRegisterBreadcrumbRoutes } from "../lib/infrastructure/navigation/hooks/useBreadcrumb";

//import { InventoryItemsPageCopy } from "../features/inventory-mock/pages/item-pages/InventoryItemsPageCopy";

const moduleDefinition: ModuleDefinition = {
    basePath: "inventories/",
    routes: [
        {
            name: () => translate("INVENTORY.InventoryPlural"),
            pathPattern: "/",
        },
        {
            name: () => "##INVENTORY##",
            pathPattern: ":id",
        },
        {
            name: () => translate("INVENTORY.StoragePlural"),
            pathPattern: ":id/storages",
        },
        {
            name: () => translate("INVENTORY.STORAGE.InventoryItems"),
            pathPattern: ":id/items",
        }, {
            name: () => "##INVENTORYITEM##",
            pathPattern: ":id/items/:inventoryItemID"
        },
        {
            name: () => "##STORAGE##",
            pathPattern: ":id/storages/:storageCode",
        },
        {
            name: () => "##INVENTORYITEM##",
            pathPattern: ":id/storages/:storageCode/items/:inventoryItemID"
        }
    ],
};

export const basePermissions = [
    Permission.GLOBAL_ADMIN,
    Permission.LOCAL_ADMIN,
    Permission.FINANCES,
    Permission.TEAM_MANAGER,
    Permission.BASIC_ACCESS
];



export function InventoriesModule() {
    const registerBreadcrumbRoutes = useRegisterBreadcrumbRoutes();

    useEffect(() => {
        registerBreadcrumbRoutes(moduleDefinition);
    }, []);

    return (
        <RouteGuard permissions={basePermissions}>
            <Routes>
                <Route index element={<InventoriesHomePage />} />
                <Route
                    path=":id/*"
                    element={
                        <InventoryContainer>
                            <Outlet />
                        </InventoryContainer>}
                >
                    <Route index element={<Navigate to="storages" />} />
                    <Route path="storages" element={<InventoryStorageLocationsPage />} />
                    <Route path="storages/:storageCode" element={<InventoryStorageDetailsPage />} />
                    <Route path="storages/:storageCode/items/:inventoryItemID" element={<InventoryItemCountsPage />} />
                    <Route path="items" element={<InventoryItemsPage />} />
                    <Route path="items/:inventoryItemID" element={<InventoryItemCountsPage />} />

                    <Route path="simulator" element={<PdaSimulatorPage />} />
                </Route>
            </Routes>
        </RouteGuard>
    );
}

import {
  ValidatorFunction,
  useFormControl,
} from "../../../lib/components/form/Form";
import { useCallback, useEffect, useState } from "react";
import {
  useClosePopup,
  useCreateNotification,
  usePopup,
} from "../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../common/validators/ValidateFormControls";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FormContainer } from "../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { MaterialsService } from "../../../services/MaterialsService";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { UpdateMaterialRequestDTO } from "../../../models/api/materials/UpdateMaterialRequestDTO";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../lib/utils/TrimString";
import { validateNullStrings } from "../../../lib/validators/ValidateEmptyStringsN";
import { positiveDecimalNumber, regexDecimalNumber, validateRegex } from "../../../common/validators/ValidateRegexOrEmpty";
import { replaceCommaByPoint } from "./AddMaterialPopup";


export interface IEditMaterialProps {
  locationId: string;
  materialId: number;
  onChangesMade: () => void;
}

var materialService = new MaterialsService();

export function EditMaterialPopup(props: IEditMaterialProps) {
  const createNotification = useCreateNotification();
  const closePopup = useClosePopup();
  const openPopup = usePopup();

  const [onClickSubmit, setOnClickSubmit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  /****************************
   * FORM CONTROL
   *****************************/
  const plantFormControl = useFormControl<string>({
    validators: [validateNullStrings()],
    enableAutoValidate: true,
  });

  const sapNumberFormControl = useFormControl<string>({
    validators: [validateNullStrings()],
    enableAutoValidate: true,
    // isDisabled: true
  });

  const descriptionFormControl = useFormControl<string>({
    validators: [validateNullStrings()],
    enableAutoValidate: true,
  });

  const unitsFormControl = useFormControl<string>({
    validators: [validateNullStrings()],
    enableAutoValidate: true,
  });


  const unitValueFormControl = useFormControl<string>({
    validators: [validateRegex(positiveDecimalNumber, "COMMON.FORM.VALIDATIONS.OnlyPositiveNumberOrDecimal")],
    enableAutoValidate: true,
  });




  useEffect(() => {
    if (props.locationId && props.materialId) {
      materialService.getById(props.locationId, props.materialId)
        .then((data) => {
          plantFormControl.setValue("-");
          sapNumberFormControl.setValue(trimString(data.name));
          descriptionFormControl.setValue(trimString(data.description));
          unitsFormControl.setValue(trimString(data.units));
          unitValueFormControl.setValue(data.unitValue);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          if (!error) return;
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }
  }, []);



  const handleEditMaterialButtonClicked = useCallback(() => {
    if (!props.locationId || !props.materialId) return;


    if (!AllValid(sapNumberFormControl.validate(), unitValueFormControl.validate(), descriptionFormControl.validate(), unitsFormControl.validate())) return;

    setOnClickSubmit(true);

    var dto: UpdateMaterialRequestDTO = {
      matNumber: trimString("" + sapNumberFormControl.value),
      description: trimString("" + descriptionFormControl.value),
      plant: "-",
      units: trimString("" + unitsFormControl.value),
      unitValue: unitValueFormControl.value ? replaceCommaByPoint("" + unitValueFormControl.value) ?? "0" : "0",
    }



    materialService.updateMaterial(props.locationId, props.materialId, dto).then(() => {
      setOnClickSubmit(false);
      props.onChangesMade();
      closePopup();
      createNotification(
        <Notification
          type="success"
          title={translate("COMMON.SYSTEMPOPUPS.Success")}
          text={translate(
            "GLOBALADMIN.MATERIALS.POPUP.EditMaterialSuccess"
          )}
        ></Notification>
      );
    })
      .catch((error) => {
        setOnClickSubmit(false);
        if (!error) return;
        openPopup(
          <ErrorPopup>{error.response.data.message}</ErrorPopup>,
          false
        );
      });
  }, [
    props.locationId, props.materialId, plantFormControl.value, sapNumberFormControl.value, descriptionFormControl.value,
    plantFormControl.validate, sapNumberFormControl.validate, descriptionFormControl.validate, unitsFormControl.validate,
    unitsFormControl.value, props.onChangesMade, createNotification, setOnClickSubmit, unitValueFormControl.validate, unitValueFormControl.value,
    closePopup, usePopup, openPopup]);



  return (
    <PopupContainer className="popup-edit-material">
      {onClickSubmit ? <FullScreenLoader /> : null}
      <PopupHeader
        title={translate("GLOBALADMIN.MATERIALS.POPUP.EditMaterial")}
      />
      <PopupContent isLoading={isLoading}>
        <FormContainer>
          <FormFieldTextInput
            formControl={sapNumberFormControl}
            label={translate("GLOBALADMIN.MATERIALS.INFO.SAPNumber")}
            placeholder="Ex: L07950-3-0-100"
          />
          <FormFieldTextInput
            formControl={descriptionFormControl}
            label={translate("GLOBALADMIN.MATERIALS.INFO.Description")}
            placeholder="Ex: DF Seam wire 6x6m 1,14x1,12 PEEK"
          />
          <FormFieldTextInput
            formControl={unitsFormControl}
            label={translate("GLOBALADMIN.MATERIALS.INFO.Units")}
            placeholder="Ex: PC"
          />
          <FormFieldTextInput
            formControl={unitValueFormControl}
            label={translate("GLOBALADMIN.MATERIALS.INFO.Value")}
            placeholder="Ex: 1,20"
          />
        </FormContainer>
        <Spacer mode={"vertical"} px="30"></Spacer>
        <PopupActionButtons
          buttons={[
            {
              text: translate("COMMON.Cancel"),
              type: "tertiary",
              onClick: () => closePopup(),
            },
            {
              text: translate("COMMON.Edit"),
              type: "primary",
              onClick: handleEditMaterialButtonClicked
            },
          ]}
        />
      </PopupContent>
    </PopupContainer>
  );
}

export class LocationChangedEvent {
   static fireLocationChanged() {
      let event = new CustomEvent<string>("location-changed");
      window.dispatchEvent(event);
   }

   static attachHandler(handler: EventListener) {
      window.addEventListener("location-changed", handler);
   }

   static detachHandler(handler: EventListener) {
      window.removeEventListener("location-changed", handler);
   }
}


import { useCallback, useEffect, useState } from "react";
import {
  useClosePopup,
  useCreateNotification,
  usePopup,
} from "../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../common/validators/ValidateFormControls";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FacilityService } from "../../../services/FacilityService";
import { FormContainer } from "../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../lib/utils/TrimString";
import {
  useFormControl,
} from "../../../lib/components/form/Form";
import { validateEmptyStrings } from "../../../lib/validators/ValidateEmptyStrings";

var facilityService = new FacilityService();

interface IProps {
  facilityId: number;
  locationId: string;
  onCompleted: () => void;
}



export function EditFacilityPopup(props: IProps) {
  const closePopup = useClosePopup();
  const openPopup = usePopup();

  const createNotification = useCreateNotification();

  const [onClickSubmit, setOnClickSubmit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  //Formcontrol
  const nameFormControl = useFormControl<string>({
    validators: [validateEmptyStrings()],
    enableAutoValidate: true,
  });

  useEffect(() => {
    if (props.locationId && props.facilityId) {
      facilityService
        .getById(props.locationId, "" + props.facilityId)
        .then((response) => {
          nameFormControl.setValue(response.name);
          setIsLoading(false);
        })
        .catch((_) => {
          setIsLoading(false);
          openPopup(
            <ErrorPopup>
              {translate("GLOBALADMIN.FACILITIES.POPUP.LoadingFacilityError")}
            </ErrorPopup>
          );
        });
    }
  }, []);


  const editfacility = useCallback(() => {
    if (!props.locationId || !props.facilityId || !AllValid(nameFormControl.validate())) return;

    setOnClickSubmit(true);

    facilityService
      .updateFacility(
        props.locationId,
        "" + props.facilityId,
        trimString(nameFormControl.value)
      )
      .then((_) => {
        props.onCompleted();
        setOnClickSubmit(false);
        closePopup();
        createNotification(
          <Notification
            type="success"
            title={translate("COMMON.SYSTEMPOPUPS.Success")}
            text={translate(
              "GLOBALADMIN.FACILITIES.POPUP.EditFacilitySuccess"
            )}
          ></Notification>
        );
      })
      .catch((error) => {
        setOnClickSubmit(false);
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });

  }, [
    props.locationId,
    props.facilityId,
    props.onCompleted,
    nameFormControl.value,
    nameFormControl.validate,
    props.onCompleted,
    closePopup,
    openPopup,
    setOnClickSubmit,
    createNotification,
  ]);

  return (
    <PopupContainer className="popup-edit-facility">
      {onClickSubmit ? <FullScreenLoader /> : null}

      <PopupHeader
        title={translate("GLOBALADMIN.FACILITIES.POPUP.EditFacility")}
      />
      <PopupContent isLoading={isLoading}>
        <FormContainer>
          <FormFieldTextInput
            formControl={nameFormControl}
            label={translate("GLOBALADMIN.FACILITIES.INFOANDFORM.Name")}
          />
        </FormContainer>
        <Spacer mode={"vertical"} px="30"></Spacer>
        <PopupActionButtons
          buttons={[
            {
              text: translate("COMMON.Cancel"),
              type: "tertiary",
              onClick: () => closePopup(),
            },
            {
              text: translate("COMMON.Edit"),
              type: "primary",
              onClick: editfacility,
            },
          ]}
        />
      </PopupContent>
    </PopupContainer>
  );
}

import "./StorageManagersPage.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../lib/infrastructure/ui/UIServices";
import { useNavigate, useParams } from "react-router-dom";
import { AddStorageManagerPopup } from "./popup/AddStorageManagerPopup";
import { ReactComponent as ArrowRightIcon } from "../../../lib/assets/icons/arrowRight.svg";
import { Button } from "../../../lib/components/buttons/Button";
import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { ReactComponent as DeleteIcon } from "../../../lib/assets/icons/remover.svg";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { ImportLoader } from "../../../lib/components/loader/ImportLoader";
import { ImportPopup } from "../../../lib/components/popup/ImportPopup";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { QueryOptions } from "../../../models/domain/materials/QueryOptions";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../lib/components/buttons/ScalableIconButton";
import { ReactComponent as SearchIcon } from "../../../lib/assets/icons/search-icon.svg";
import { StorageManagerSummary } from "./models/StorageManagerSummary";
import { StorageManagersService } from "./services/StorageManagersService";
import { TextButton } from "../../../lib/components/buttons/TextButton";
import { WarningPopup } from "../../../lib/components/popup/WarningPopup";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../lib/components/form/Form";
import { useServiceCallPro2 } from "../../../lib/hooks/useServiceCall";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";

const svc = new StorageManagersService();


export function StorageManagersPage() {

    const { id: locationID } = useParams();
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();
    const navigate = useNavigate();

    const [uploadFileCall, uploadFileCallIsLoading] = useServiceCallPro2(svc, svc.uploadStorageManagersFromXLSX);
    const [getStorageManagersCall, getStorageManagersCallIsLoading] = useServiceCallPro2(svc, svc.getStorageManagers);
    const [deleteStorageManagersCall, deleteStorageManagersCallIsLoading] = useServiceCallPro2(svc, svc.deleteStorageManager);

    const [storageManagers, setStorageManagers] = useState<StorageManagerSummary[]>();
    const [totalItems, setTotalItems] = useState<number>();

    var searchFormControl = useFormControl<string>({});

    const [searchWord, setSearchWord] = useState<string>();
    const [timer, setTimer] = useState<NodeJS.Timeout>();

    const [currentpage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);


    useEffect(() => {
        getStorageManagers();
    }, [currentpage, itemsPerPage, searchWord]);


    useEffect(() => {
        console.log("C")
        if (timer) {
            clearTimeout(timer);
        }
        console.log("A")
        var responseTimeOut = setTimeout(() => {
            if (timer) {
                console.log("B")
                setCurrentPage(0);
                setSearchWord(searchFormControl.value);
            }
        }, 1000);
        setTimer(responseTimeOut);
    }, [searchFormControl.value]);




    const getStorageManagers = useCallback(() => {

        if (!locationID) return;

        var queryOptions: QueryOptions = {
            page: currentpage,
            pageLength: itemsPerPage,
            searchWord: searchWord
        };

        getStorageManagersCall("" + locationID, queryOptions)
            .then((data) => {
                setTotalItems(data.total);
                setStorageManagers(data.items);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationID, openPopup, getStorageManagersCall, setTotalItems, setStorageManagers, currentpage, itemsPerPage, searchWord]);





    const handleFileImport = useCallback((file: File) => {
        if (!locationID) return;

        uploadFileCall(`${locationID}`, file)
            .then((_) => {

                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("GLOBALADMIN.STORAGEMANAGERS.ImportStorageManagersSuccess")}
                    ></Notification>
                );
                getStorageManagers();
            })
            .catch(error => {

                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationID, uploadFileCall, openPopup, createNotification, getStorageManagers]);








    const handleDeleteStorageManager = useCallback((email: string) => {

        if (!locationID || !email) return;

        deleteStorageManagersCall(locationID, email)
            .then((_) => {
                closePopup();
                getStorageManagers();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("GLOBALADMIN.STORAGEMANAGERS.DeleteManagerSuccess")}
                    ></Notification>
                );
            })
            .catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationID, deleteStorageManagersCall, closePopup, getStorageManagers, createNotification, openPopup]);




    const handleOnPageAndItemsChanged = useCallback(
        (items: number, currentPage: number) => {
            setItemsPerPage(items);
            setCurrentPage(currentPage);
        },
        [setItemsPerPage, setCurrentPage]
    );




    const actionsButtons = useMemo(() => {

        return (
            <>
                <Button
                    text={translate("COMMON.Import")}
                    type="secondary"
                    onClick={() => openPopup(<ImportPopup onClickDownloadTemplateBtn={() =>

                        window.open(ConfigurationProvider.getConfiguration().App.BackendUrl + "/templates/VLMT_Inventory_ManagersStorages_Template.xlsx", "_blank")}

                        onClickImportBtn={handleFileImport} />)}
                    className="import-material"
                />
                <Button
                    text={translate("COMMON.Create")}
                    type="primary"
                    onClick={() => {
                        if (locationID) {
                            openPopup(<AddStorageManagerPopup locationID={locationID} onCompletedOperations={getStorageManagers} />);
                        }
                    }}
                    className="create-storage-manager"
                ></Button>
            </>);
    }, [handleFileImport, getStorageManagers, openPopup, locationID]);





    const columns: ColumnDefinition<StorageManagerSummary>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => v.name,
                width: "35%",
                headerRender: translate("GLOBALADMIN.OPERATORS.INFOANDFORM.Name"),
                columnKey: "name",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => v.email,
                width: "35%",
                headerRender: "E-mail",
                columnKey: "email",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => {
                    if (v.storages && v.storages.length > 2) {
                        const firstTwoStorages = v.storages.slice(0, 2);
                        return (<div className="view-more">
                            <div className="first-two-elements">
                                {firstTwoStorages.map(({ storageCode, storageName }) => `${storageCode} <${storageName}>`).join(', ')}
                            </div>

                            <TextButton className="small-copy" text={translate("COMMON.ViewAll")} type="primary" icon={<ArrowRightIcon />} onClick={() => navigate(`${v.email}`)} />
                        </div>)
                    }
                    return <> {v.storages?.map(({ storageCode, storageName }) => `${storageCode} <${storageName}>`).join(', ')}</>

                },
                width: "30%",
                headerRender: translate("GLOBALADMIN.STORAGES.Storages"),
                columnKey: "bin",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => (
                    <ScalableIconButton
                        icon={<DeleteIcon />}
                        onClick={() => {
                            openPopup(
                                <WarningPopup
                                    className="button-red"
                                    onDismissClicked={() => { closePopup(); }}
                                    onConfirmClicked={() => { handleDeleteStorageManager(v.email); }}
                                    dismissText={translate("COMMON.Cancel")}
                                    confirmText={translate("COMMON.Remove")}
                                >
                                    {translate("GLOBALADMIN.STORAGEMANAGERS.DeleteManagerConfirmText", v.name ? v.name : v.email)}
                                </WarningPopup>
                            );
                        }}
                        size={30}
                    />
                ),
                width: "3%",
                columnKey: "stats",
                isSortable: false,
            },
        ], [navigate, closePopup, openPopup, handleDeleteStorageManager]);



    return (
        <PageLayout

            tabTitle={`${translate("INVENTORY.Inventory")} - ${translate("GLOBALADMIN.STORAGEMANAGERS.StorageManagers")}`}
            pageTitle={translate("GLOBALADMIN.STORAGEMANAGERS.StorageManagers")}
            className="admin-storage-managers"
            showBreadcrumb
            actions={<>{actionsButtons}</>}
        >
            {deleteStorageManagersCallIsLoading ? <FullScreenLoader /> : null}

            <div className="command-bar">
            <FormFieldTextInput
                    formControl={searchFormControl}
                    icon={<SearchIcon />}
                    placeholder={translate("COMMON.FORM.SearchPlaceholder")}
                    className="search-box"
                />
                {uploadFileCallIsLoading ? <ImportLoader text={translate("GLOBALADMIN.STORAGEMANAGERS.ImportLoading")} /> : null}
            </div>


            <ResponsiveDataTable
                columnDefinitions={columns}
                items={storageManagers || []}
                isLoading={getStorageManagersCallIsLoading}
                paginationOptions={{
                    itemsPerPage: itemsPerPage,
                    itemsPerPageOptions: [10, 20, 30, 40, 50],
                }}
                currentpage={currentpage}
                onClickRow={(item) => navigate(`${item.email}`)}
                totalitems={totalItems || 0}
                onPageAndItemsChanged={handleOnPageAndItemsChanged}
            ></ResponsiveDataTable>
        </PageLayout >
    );
}

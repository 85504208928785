import { useCallback, useState } from "react";
import {
  useClosePopup,
  useCreateNotification,
  usePopup,
} from "../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../common/validators/ValidateFormControls";
import { CreateMaterialRequestDTO } from "../../../models/api/materials/CreateMaterialRequestDTO";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FormContainer } from "../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { MaterialsService } from "../../../services/MaterialsService";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../lib/utils/TrimString";
import { useFormControl } from "../../../lib/components/form/Form";
import { validateNullStrings } from "../../../lib/validators/ValidateEmptyStringsN";
import { positiveDecimalNumber, validateRegex } from "../../../common/validators/ValidateRegexOrEmpty";

export interface IAddMaterialProps {
  locationId: string;
  onChangesMade: () => void;
}

export function replaceCommaByPoint(value: string | undefined) {
  if (!value) return null;

  if (value?.includes(','))
    return trimString(value.replace(',', '.'));

  return trimString(value);
}
var materialService = new MaterialsService();

export function AddMaterialPopup(props: IAddMaterialProps) {
  const createNotification = useCreateNotification();
  const closePopup = useClosePopup();
  const openPopup = usePopup();

  const [onClickSubmit, setOnClickSubmit] = useState<boolean>(false);

  /****************************
   * FORM CONTROL
   *****************************/
  const plantFormControl = useFormControl<string>({
    validators: [validateNullStrings()],
    enableAutoValidate: true,
  });

  const nameFormControl = useFormControl<string>({
    validators: [validateNullStrings()],
    enableAutoValidate: true,
  });

  const descriptionFormControl = useFormControl<string>({
    validators: [validateNullStrings()],
    enableAutoValidate: true,
  });

  const unitsFormControl = useFormControl<string>({
    validators: [validateNullStrings()],
    enableAutoValidate: true,
  });

  const unitValueFormControl = useFormControl<string>({
    validators: [validateRegex(positiveDecimalNumber, "COMMON.FORM.VALIDATIONS.OnlyPositiveNumberOrDecimal")],
    enableAutoValidate: true,
  });




  const handleAddMaterialButtonClicked = useCallback(() => {
    if (!props.locationId) return;

    if (!AllValid(nameFormControl.validate(), descriptionFormControl.validate(), unitsFormControl.validate(), unitValueFormControl.validate())) return;

    setOnClickSubmit(true);

    var dto: CreateMaterialRequestDTO = {
      matNumber: trimString("" + nameFormControl.value),
      description: trimString("" + descriptionFormControl.value),
      plant: "-",
      units: trimString("" + unitsFormControl.value),
      unitValue: unitValueFormControl.value ? replaceCommaByPoint("" + unitValueFormControl.value) ?? "0" : "0",
    };

    materialService.addMaterial(props.locationId, dto)
      .then((_) => {
        props.onChangesMade();
        setOnClickSubmit(false);
        closePopup();
        createNotification(
          <Notification
            type="success"
            title={translate("COMMON.SYSTEMPOPUPS.Success")}
            text={translate(
              "GLOBALADMIN.MATERIALS.POPUP.CreateMaterialSuccess"
            )}
          ></Notification>
        );
      })
      .catch((error) => {
        if (!error) return;
        setOnClickSubmit(false);
        openPopup(
          <ErrorPopup>{error.response.data.message}</ErrorPopup>,
          false
        );
      });

  }, [
    nameFormControl.value, plantFormControl.value, descriptionFormControl.value, nameFormControl.validate, plantFormControl.validate,
    descriptionFormControl.validate, unitsFormControl.validate, unitsFormControl.value, createNotification,
    closePopup, openPopup, setOnClickSubmit, props.onChangesMade, unitValueFormControl.value, unitValueFormControl.validate
  ]);


  return (
    <div className="add-material-wrapper">
      <PopupContainer className="popup-add-material">
        {onClickSubmit ? <FullScreenLoader /> : null}

        <PopupHeader
          title={translate("GLOBALADMIN.MATERIALS.POPUP.CreateMaterial")}
        />
        <PopupContent>
          <FormContainer>
            {/*             <FormFieldTextInput
              formControl={plantFormControl}
              label={translate("GLOBALADMIN.MATERIALS.INFO.Plant")}
              placeholder="Ex: 4500"
            /> */}
            <FormFieldTextInput
              formControl={nameFormControl}
              label={translate("GLOBALADMIN.MATERIALS.INFO.SAPNumber")}
              placeholder="Ex: L07950-3-0-100"
            />
            <FormFieldTextInput
              formControl={descriptionFormControl}
              label={translate("GLOBALADMIN.MATERIALS.INFO.Description")}
              placeholder="Ex: DF Seam wire 6x6m 1,14x1,12 PEEK"
            />
            <FormFieldTextInput
              formControl={unitsFormControl}
              label={translate("GLOBALADMIN.MATERIALS.INFO.Units")}
              placeholder="Ex: PC"
            />
            <FormFieldTextInput
              formControl={unitValueFormControl}
              label={translate("GLOBALADMIN.MATERIALS.INFO.Value")}
              placeholder="Ex: 1,20"
            />
          </FormContainer>
          <Spacer mode={"vertical"} px="30"></Spacer>
          <PopupActionButtons
            buttons={[
              {
                text: translate("COMMON.Cancel"),
                type: "tertiary",
                onClick: () => closePopup(),
              },
              {
                text: translate("COMMON.Create"),
                type: "primary",
                onClick: handleAddMaterialButtonClicked
              },
            ]}
          />
        </PopupContent>
      </PopupContainer>
    </div>
  );
}

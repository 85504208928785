import "./AddStorageManagerPopup.css";

import { useServiceCallPro2 } from "../../../../lib/hooks/useServiceCall";
import { AdminStorageLocationsService } from "../../../inventory-mock/services/AdminStorageLocationsService";
import { QueryOptions } from "../../../../models/domain/materials/QueryOptions";
import { useCallback, useEffect, useState } from "react";
import { StorageLocationSummary } from "../../../inventory-mock/models/domain/admin-storage-locations/StorageLocationSummary";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { Optional, ValidatorFunction, useFormControl } from "../../../../lib/components/form/Form";
import { validateNullStrings } from "../../../../lib/validators/ValidateEmptyStringsN";
import { FormFieldSelectMultipleSimple } from "../../../../lib/components/form/form-field/FormFieldSelectMultipleSimple";
import { AddStorageManagerRequestDTO } from "../models/AddStorageManagerRequestDTO";
import { StorageManagersService } from "../services/StorageManagersService";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { FormFieldSelectMultiple } from "../../../../lib/components/form/form-field/FormFieldSelectMultiple";

const svc = new AdminStorageLocationsService();
const storageManagersSvc = new StorageManagersService();

export interface AddStorageManagerProps {
    locationID: string;
    onCompletedOperations: () => void;
}

export interface SelectOption {
    id: string;
    description: string;
}


const labelSampleTypeSelector = (item: SelectOption) => item.description;
const idSampleTypeSelector = (item: SelectOption) => item.id;

function hasAtLeastOneElement<A>(): ValidatorFunction<Optional<A[]>> {
    return (value: A[] | undefined) => {
        if (!value || value.length < 1) {
            return translate("GLOBALADMIN.STORAGEMANAGERS.SelectOneStorageWarningMessage");
        }
        return null;
    };
}



export function AddStorageManagerPopup(props: AddStorageManagerProps) {

    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();

    const [getStorageLocationsCall, getStorageLocationsCallIsLoading] = useServiceCallPro2(svc, svc.getAdminStorageLocations);
    const [addManagerCall, addManagerCallIsLoading] = useServiceCallPro2(storageManagersSvc, storageManagersSvc.addStorageManager);

    const [storageLocationOptions, setStorageLocationOptions] = useState<SelectOption[]>([]);

    const storagesFormControl = useFormControl<SelectOption[]>({
        validators: [hasAtLeastOneElement()]
    });

    const emailFormControl = useFormControl<string>({
        validators: [validateNullStrings()],
        enableAutoValidate: true,
    });





    useEffect(() => {
        getStorageLocations();
    }, []);




    const getStorageLocations = useCallback(() => {

        if (!props.locationID) return;

        getStorageLocationsCall(props.locationID, {})
            .then((data) => {

                const mappedOptions: SelectOption[] = data.items.map(s => ({
                    id: s.locSap,
                    description: s.description ? s.locSap + " - " + s.description : s.locSap
                }));

                setStorageLocationOptions(mappedOptions);

            })
            .catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [getStorageLocationsCall, props.locationID, openPopup, setStorageLocationOptions]);





    const handleAddStorageManagerClicked = useCallback(() => {

        if (!props.locationID || !emailFormControl.validate() || !storagesFormControl.validate()) return;

        var newStorageManager: AddStorageManagerRequestDTO = {
            email: emailFormControl.value || "",
            storageCodes: storagesFormControl.value?.map((sl) => sl.id) || []
        }

        addManagerCall(props.locationID, newStorageManager)
            .then((_) => {

                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("GLOBALADMIN.STORAGEMANAGERS.ImportStorageManagerSingleSuccess")}
                    ></Notification>
                );

                props.onCompletedOperations();
                closePopup();

            })
            .catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [props.locationID, emailFormControl.validate, storagesFormControl.validate, props.onCompletedOperations, closePopup,
    emailFormControl.value, storagesFormControl.value, createNotification, addManagerCall, openPopup]);






    return (
        <PopupContainer className="popup-add-storage-manager">
            <PopupHeader title={translate("GLOBALADMIN.STORAGEMANAGERS.AddStorageManager")} />

            {addManagerCallIsLoading ? <FullScreenLoader /> : null}

            <PopupContent isLoading={getStorageLocationsCallIsLoading}>
                <FormContainer>
                    <FormFieldTextInput
                        formControl={emailFormControl}
                        label={"E-mail"}
                        placeholder="Ex: johndoe@valmet.com"
                    />

                    <FormFieldSelectMultiple<SelectOption>
                        label={translate("GLOBALADMIN.STORAGES.Storages")}
                        placeholder={translate("COMMON.FORM.SelectManyOptions")}
                        options={storageLocationOptions || []}
                        labelSelector={labelSampleTypeSelector}
                        idSelector={idSampleTypeSelector}
                        formControl={storagesFormControl}
                        maxHeightOptions={"16rem"}
                    />


                </FormContainer>
                <Spacer mode={"vertical"} px="30"></Spacer>
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Add"),
                            type: "primary",
                            onClick: handleAddStorageManagerClicked
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}

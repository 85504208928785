export function roundNumber(value: number | undefined | null, roundTo: number) {

    if (value === undefined || value === null || Number.isNaN(value))
        return "-"

    return "" + value.toFixed(roundTo);
}



export function formatNumber(value: number | undefined | null) {

    if (value === undefined || value === null || Number.isNaN(value))
        return "-"

    return value.toString().replace(".", ",");
}



export function formatRoundNumber(value: number | undefined | null, roundTo: number) {

    if (value === undefined || value === null || Number.isNaN(value))
        return "-"

    return "" + value.toFixed(roundTo).replace(".", ",");
}


export function formatNonIntegerNumber(value: number | undefined | null, roundTo: number) {

    if (value === undefined || value === null || Number.isNaN(value))
        return "-"
    if (Number.isInteger(value)) {
        return value.toString();
    } else {
        return value.toFixed(roundTo).replace(".", ",");
    }

}



export function replacePointByComma(value: string | undefined | null) {

    if (value === undefined || value === null)
        return "-";

    return value.toString().replace(".", ",");
}



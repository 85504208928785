import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";

import { InventoriesService } from "../services/InventoriesService";
import { InventoryStats } from "../models/domain/inventory/InventoryStats";
import { StatusCard } from "../../../lib/components/cards/stats-card/StatusCard";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useServiceCallPro2 } from "../../../lib/hooks/useServiceCall";

const inventorySvc = new InventoriesService();

export interface IInventoryStatsIntrinsicBehavior {
    refresh: () => void;
}

export interface IInventoryStatsSectionProps {
    locationId?: string;
    inventoryId?: string;
}

export const InventoryStatsSection = forwardRef(
    function InventoryStatsSection(props: IInventoryStatsSectionProps,  ref: React.ForwardedRef<IInventoryStatsIntrinsicBehavior>) {

    const {locationId, inventoryId} = props;
    const [inventoryStats, setInventoryStats] = useState<InventoryStats>();

    const [getInventoryStatsCall] = useServiceCallPro2(inventorySvc, inventorySvc.getInventoryStats);


    const getInventoryStats = useCallback(() => {
        if (!locationId || !inventoryId) return;
        getInventoryStatsCall(locationId, inventoryId).then((data) => setInventoryStats(data))
    }, [locationId, inventoryId, setInventoryStats, getInventoryStatsCall]);

    useEffect(() => {
        getInventoryStats();
    }, [/* locationId, */ inventoryId]);

    useImperativeHandle(ref, () => {
        return {
          refresh() {
            getInventoryStats();
          }
        }
    }, [getInventoryStats]);

        return (<>
            <StatusCard
                title={translate("INVENTORY.ITEM.TotalItems")}
                value={inventoryStats?.totalItens?.toString() ?? "-"}
                backgroundColor="status-dark-grey"

            />
            <StatusCard
                title={translate("INVENTORY.ITEM.STATUS.Counting")}
                value={ inventoryStats ? (inventoryStats.totalInCounting + inventoryStats.totalReadyToBeValidated).toString() : "-"}
                backgroundColor="status-yellow"
            />
            <StatusCard
                title={translate("INVENTORY.ITEM.STATUS.ValidatedPlural")}
                value={(inventoryStats?.totalValidated || 0).toString() ?? "-"}
                backgroundColor="status-deep-blue"
            />
            <StatusCard
                title={translate("INVENTORY.ITEM.STATUS.ClosedPlural")}
                value={inventoryStats?.totalClosed?.toString() ?? "-"}
                backgroundColor="status-green"
            />
        </>);

});

import * as qs from "qs"

import { ConfigurationProvider } from "../../../../lib/infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../../../../lib/infrastructure/http/HttpClient";
import { AddStorageManagerRequestDTO } from "../models/AddStorageManagerRequestDTO";
import { QueryOptions } from "../../../../models/domain/materials/QueryOptions";
import { StorageManagersResponseDTO } from "../models/StorageManagersResponseDTO";
import { StorageManagersTotalItems } from "../models/StorageManagersTotalItems";
import { StorageManagerSummary } from "../models/StorageManagerSummary";
import { StorageManagerSummaryDTO } from "../models/StorageManagerSummaryDTO";
import { StorageManagerStorageSummaryDTO } from "../models/StorageManagerStorageSummaryDTO";
import { StorageManagerStorageSummary } from "../models/StorageManagerStorageSummary";
import { EditStorageManagerStoragesRequestDTO } from "../models/EditStorageManagerStoragesRequestDTO";
import { StorageManagersSummaryResponseDTO } from "../models/StorageManagersSummaryResponseDTO";
import { StorageManagerBasicInfo } from "../models/StorageManagerBasicInfo";




const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class StorageManagersService {

    uploadStorageManagersFromXLSX(locationId: string, file: File): Promise<void> {

        var formData = new FormData();
        formData.append("file", file)

        return HttpClient.sessionRequest({
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
            },
            url: Route(`/api/v1/Locations/${locationId}/StorageManagers/FromXLSX`),
            data: formData
        })
            .then((_) => {

            })
            .catch((error) => {
                throw error;
            });
    }




    addStorageManager(locationId: string, requestDto: AddStorageManagerRequestDTO): Promise<void> {

        return HttpClient.sessionRequest({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/StorageManagers`),
            data: requestDto
        })
            .then(() => { })
            .catch((error) => {
                throw error;
            });
    }






    getStorageManagers(locationId: string, requestDto: QueryOptions): Promise<StorageManagersTotalItems> {

        return HttpClient.sessionRequest<StorageManagersResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/StorageManagers`),
            params: requestDto,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {

                if (response.data) {

                    var mappedItems: StorageManagerSummary[] = response.data.storageManagers.map(ob => ({
                        id: ob.id,
                        name: ob.name,
                        email: ob.email,
                        storages: ob.storages?.map(({ id, storageCode, storageName, bins }) => ({
                            id, storageCode, storageName,
                            bins: bins?.map(({ id, code }) => ({ id, code })) ?? []

                        })) ?? []
                    }));


                    return {
                        items: mappedItems,
                        total: response.data.totalItems
                    }
                }
                return {
                    items: [],
                    total: 0
                }
            })
            .catch((error) => {
                throw error;
            });
    }



    getStorageManagerStoragesByEmail(locationId: string, email: string): Promise<StorageManagerSummary | null> {

        return HttpClient.sessionRequest<StorageManagerSummaryDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/StorageManagers/${email}/Storages`),

        })
            .then((r) => {

                if (r.data) {
                    var mappedResponse: StorageManagerSummary = {
                        id: r.data.id,
                        name: r.data.name,
                        email: r.data.email,
                        storages: r.data.storages?.map(({ id, storageCode, storageName, bins }) => ({
                            id,
                            storageCode,
                            storageName,
                            bins: bins?.map(({ id, code }) => ({ id, code })) ?? []
                        })) ?? []
                    };
                    return mappedResponse;
                }
                return null;
            })
            .catch((error) => {
                throw error;
            });
    }




    updatedStorageManagerStorages(locationId: string, email: string, requestDto: EditStorageManagerStoragesRequestDTO): Promise<void> {

        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/StorageManagers/${email}/Storages`),
            data: requestDto
        })
            .then(() => { })
            .catch((error) => {
                throw error;
            });
    }





    getStorageManagerByEmail(locationId: string, email: string): Promise<StorageManagerSummary | null> {

        return HttpClient.sessionRequest<StorageManagerSummaryDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/StorageManagers/${email}`),

        })
            .then((r) => {

                if (r.data) {

                    var mappedResult: StorageManagerSummary = {
                        id: r.data.id,
                        name: r.data.name,
                        email: r.data.email,
                        storages: r.data.storages?.map(({ id, storageCode, storageName, bins }) => ({
                            id,
                            storageCode,
                            storageName,
                            bins: bins?.map(({ id, code }) => ({ id, code })) ?? []
                        })) ?? []
                    };

                    return mappedResult;
                }
                return null;
            })
            .catch((error) => {
                throw error;
            });
    }



    deleteStorageManager(locationId: string, email: string): Promise<void> {
        return HttpClient.sessionRequest({
            method: "DELETE",
            url: Route(`/api/v1/Locations/${locationId}/StorageManagers/${email}`),
        })
            .then((_) => { })
            .catch((error) => { throw error; });
    }


    getAllStorageManagerStoragesByLocation(locationId: string): Promise<StorageManagerBasicInfo[] | null> {

        return HttpClient.sessionRequest<StorageManagersSummaryResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/StorageManagers/Summary`),
        })
            .then((r) => {

                if (r.data) {
                    var mappedManagers = r.data.storageManagers.map(
                        (p): StorageManagerBasicInfo => ({
                            id: p.id,
                            name: p.name ?? "-",
                            email: p.email ?? "-"

                        })
                    );
                    return mappedManagers;
                }
                return null;
            })
            .catch((error) => {
                throw error;
            });
    }



    deleteStorageManagerStorage(locationID: string, managerEmail: string, storageCode: string): Promise<void> {
        return HttpClient.sessionRequest({
            method: "DELETE",
            url: Route(`/api/v1/Locations/${locationID}/StorageManagers/${managerEmail}/Storages/${storageCode}`),
        })
            .then((_) => { })
            .catch((error) => { throw error; });
    }
}









import "./AssociateManagerToPdaOperatorPopup.css";

import { useCallback, useEffect, useState } from "react";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { useServiceCallPro2 } from "../../../../lib/hooks/useServiceCall";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { CardContainer } from "../../../../lib/layouts/containers/card/CardContainer";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { StorageManagerBasicInfo } from "../../storage-managers/models/StorageManagerBasicInfo";
import { StorageManagersService } from "../../storage-managers/services/StorageManagersService";
import { PdaOperatorService } from "../services/PdaOperatorService";
import { FormFieldSelectSingle } from "../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { Optional, ValidatorFunction, useFormControl } from "../../../../lib/components/form/Form";
import { InfoBlock } from "../../../../lib/components/info-block/InfoBlock";
import { PdaOperator } from "../models/domain/PdaOperator";
import { Notification } from "../../../../lib/components/notifications/Notification";

var pdaSvc = new PdaOperatorService();
var storageManagersSvc = new StorageManagersService();

export interface AssociateManagerToPdaOperatorPopupProps {
    locationID: string;
    pdaOperatorName: string;
    pdaOperatorID: number;
    onCompletedOperations: () => void;
}


const labelManagerNameSelector = (item: StorageManagerBasicInfo) => item.name ?? "-";
const idManagerIdSelector = (item: StorageManagerBasicInfo) => item.id;


export function hasOneElementSelected<A>(): ValidatorFunction<Optional<A>> {
    return (value: A | undefined) => {
        if (!value) {
            return translate("COMMON.FORM.VALIDATIONS.RequiredField");
        }
        return null;
    };
}


export function AssociateManagerToPdaOperatorPopup(props: AssociateManagerToPdaOperatorPopupProps) {

    const createNotification = useCreateNotification();
    const closePopup = useClosePopup();
    const openPopup = usePopup();

    const [postAssociationCall, postAssociationCallIsLoading] = useServiceCallPro2(pdaSvc, pdaSvc.associateManagerToPdaOperator);

    const [getStorageManagersCall, getStorageManagersCallIsLoading] = useServiceCallPro2(storageManagersSvc, storageManagersSvc.getAllStorageManagerStoragesByLocation);
    const [getPdaOperatorCall, getPdaOperatorCallIsLoading] = useServiceCallPro2(pdaSvc, pdaSvc.getPdaOperatorById);

    const [storageManagers, setStorageManagers] = useState<StorageManagerBasicInfo[]>([]);
    const [originalManager, setOriginalManager] = useState<PdaOperator>();

    const storageManagersControl = useFormControl<StorageManagerBasicInfo>({
        validators: [hasOneElementSelected()]
    });



    useEffect(() => {
        if (!props.locationID) return;

        getStorageManagersCall(props.locationID)
            .then((response) => {
                if (!response) return;
                setStorageManagers(response);
            })
            .catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });


    }, []);



    useEffect(() => {
        if (!props.locationID || !props.pdaOperatorID) return;

        getPdaOperatorCall(props.locationID, "" + props.pdaOperatorID)
            .then((response) => {

                setOriginalManager(response);
            })
            .catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, []);



    useEffect(() => {
        if (!originalManager || !storageManagers) return;

        var existingManager = storageManagers.find((a) => a.id === originalManager?.storageManagerId);
        if (existingManager)
            storageManagersControl.setValue(existingManager);

    }, [originalManager, storageManagers]);




    const handleAssociateBtnClicked = useCallback(() => {
        if (!props.locationID) return;
        if (!storageManagersControl.validate() || !storageManagersControl.value?.id) return;

        postAssociationCall("" + props.locationID, "" + props.pdaOperatorID, storageManagersControl.value?.id)
        .then((_)=>{
            props.onCompletedOperations();
            closePopup(); 

            createNotification(
                <Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("GLOBALADMIN.OPERATORS.NOTIFICATIONS.ManagerAssociatedSuccess")}
                ></Notification>
            );
        })
        .catch(error => {
            if (!error) return;
            openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }, [props.locationID, storageManagersControl.validate, storageManagersControl.value, props.onCompletedOperations, closePopup, createNotification, openPopup]);



    return (
        <PopupContainer className="popup-associate-manager-operator">
            <PopupHeader
                title={translate("GLOBALADMIN.OPERATORS.AssociateStorageManager")}
            />
            {postAssociationCallIsLoading ? <FullScreenLoader/> : null}
            <PopupContent isLoading={getStorageManagersCallIsLoading || getPdaOperatorCallIsLoading}>

                <CardContainer className="pda-operator-info">
                    <div className="pda-operator-header">
                        {translate("GLOBALADMIN.OPERATORS.PdaOperatorInfo")}
                    </div>
                    <Spacer mode="vertical" px="16" />
                    <InfoBlock value={props.pdaOperatorName} label={translate("GLOBALADMIN.OPERATORS.INFOANDFORM.Name")} />
                </CardContainer>

                <Spacer mode={"vertical"} px="30"></Spacer>


                <FormFieldSelectSingle<StorageManagerBasicInfo>
                    label={translate("GLOBALADMIN.OPERATORS.INFOANDFORM.StorageManager")}
                    placeholder={translate("GLOBALADMIN.OPERATORS.INFOANDFORM.SelectOneManager")}
                    options={storageManagers || []}
                    labelSelector={labelManagerNameSelector}
                    idSelector={idManagerIdSelector}
                    formControl={storageManagersControl}
                    maxHeightOptions={"16rem"}
                    isClearSelectionAvailable
                />

                <Spacer mode={"vertical"} px="30"></Spacer>


                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("GLOBALADMIN.OPERATORS.Associate"),
                            type: "primary",
                            onClick: handleAssociateBtnClicked
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}

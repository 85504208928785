import './ValidateInvDocItemCountPopup.css';

import { useCallback, useEffect, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { CardContainer } from "../../../../../lib/layouts/containers/card/CardContainer";
import { FormContainer } from "../../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextArea } from "../../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from '../../../../../lib/components/form/section/FormSection';
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { InventoryItemDetails } from '../../../models/domain/inventory-items/InventoryItemDetails';
import { InventoryItemSummary } from '../../../models/domain/inventory-items/InventoryItemSummary';
import { InventoryItemsService } from "../../../services/InventoryItemsService";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../../services/UseServiceCall";
import { validateEmptyStrings } from "../../../../../lib/validators/ValidateEmptyStrings";
import { ErrorPopup } from '../../../../../lib/components/popup/ErrorPopup';
import { useServiceCallPro2 } from '../../../../../lib/hooks/useServiceCall';
import { InventoryItemState } from '../../../models/dtos/InventoryItemDTOs/InventoryItemState';

var svc = new InventoryItemsService();

export interface IValidateInvDocItemCountPopupProps {
    locationID: string;
    inventoryID: string;
    inventoryItemID: string;
    onCompletedOperations: () => void;
    hasZeroQuantity: boolean;
}

var inventoryItemsSvc = new InventoryItemsService();

export function ValidateInvDocItemCountPopup(props: IValidateInvDocItemCountPopupProps) {

    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const createNotification = useCreateNotification();

    const getInventoryItemCall = useServiceCallPro(svc.getInventoryItemById);
    const [validateItem, validateItemIsLoading] = useServiceCallPro2(inventoryItemsSvc, inventoryItemsSvc.updateInventoryItemState);

    const [item, setItem] = useState<InventoryItemDetails>();

    const commentsControl = useFormControl<string>({
        validators: [validateEmptyStrings()],
        enableAutoValidate: true,
    });


    useEffect(() => {
        if (!props.locationID || !props.inventoryID || !props.inventoryItemID) return;

        getInventoryItemCall.invoke(props.locationID, props.inventoryID, props.inventoryItemID)
            .then((r) => setItem(r))
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, []);



    const handleValidateButtonClicked = useCallback(() => {

        if (!commentsControl.validate()) return;
        if (!props.locationID || !props.inventoryID || !props.inventoryItemID) return;

        validateItem(`${props.locationID}`, props.inventoryID, props.inventoryItemID, { state: InventoryItemState.VALIDATED, validateItemJustification: commentsControl.value })
            .then(_ => {
                props.onCompletedOperations();

                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate(
                            "INVENTORY.ITEM.MESSAGES.ValidateItemSuccessMessage"
                        )}
                    ></Notification>
                );
                closePopup();

                props.onCompletedOperations();
            }).catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [commentsControl.validate, commentsControl.value, props.locationID, props.inventoryID, props.inventoryItemID, props.onCompletedOperations,
        closePopup, createNotification, openPopup, validateItem]);




    return (
        <PopupContainer className="popup-validate-item-count">
            {false ? <FullScreenLoader /> : null}
            <PopupHeader
                title={translate("INVENTORY.ITEM.ValidateCounts")}
            />
            {validateItemIsLoading ? <FullScreenLoader /> : null}

            <PopupContent isLoading={getInventoryItemCall.isLoading}>
                <CardContainer className="selected-item-info">
                    <FormSection unsetFlexGrow>
                        <div className="block">
                            <div className="small-copy block-label">{translate("INVENTORY.ITEM.INFOANDFORM.MaterialNumber")}</div>
                            <div className="block-value">{item?.materialNumber || "-"}</div>
                        </div>
                        <div className="block">
                            <div className="small-copy block-label">{translate("INVENTORY.ITEM.INFOANDFORM.Deviation")}</div>
                            <div className="block-value">{item?.quantityDeviationPercentage?.toFixed(2) + "%" || "-"}</div>
                        </div>
                        <div className="block">
                            <div className="small-copy block-label">{translate("INVENTORY.ITEM.INFOANDFORM.CountedQuantity") }</div>
                            <div className="block-value">{props.hasZeroQuantity ? 0 : item?.quantityCounted?.toFixed(2) + " " + item?.units || "-"}</div>
                        </div>
                    </FormSection>
                </CardContainer>
                <Spacer mode={"vertical"} px={18} />
                <div className="warning-text">{props.hasZeroQuantity ? translate("INVENTORY.ITEM.ValidateCountsZeroQtyWarningText") : translate("INVENTORY.ITEM.ValidateCountsWarningText")}</div>
                <Spacer mode={"vertical"} px={16} />
                <FormContainer>
                    <FormFieldTextArea
                        formControl={commentsControl}
                        label={translate("INVENTORY.ITEM.INFOANDFORM.Justification")}
                        placeholder={translate("INVENTORY.ITEM.INFOANDFORM.JustificationPlaceholder")}
                    />
                </FormContainer>
                <Spacer mode={"vertical"} px="30" />
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("INVENTORY.ITEM.Validate"),
                            type: "primary",
                            onClick: handleValidateButtonClicked
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
import "./EditLocationGroupAccessPage.css";

import { AccessGroupsService, LocationGroups, UpdateLocationGroupItem, UpdateLocationGroups } from "../../../services/AccessGroupsService";
import {
  IFormControl,
  useFormControl,
} from "../../../lib/components/form/Form";
import { Table, TableBody, TableHeader, TableHeaderCell, TableRow, TableRowCell } from '../../../lib/components/table/Table';
import { useCallback, useEffect, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../lib/infrastructure/ui/UIServices";

import { Button } from "../../../lib/components/buttons/Button";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { Loader } from "../../../lib/components/loader/Loader";
import { LocationDTO } from "../../../models/api/location/LocationDTO";
import { LocationGroupType } from "../../../models/enums/LocationGroupType";
import { LocationService } from "../../../services/LocationServices";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useParams } from "react-router-dom";
import { validateGuid } from "../../../lib/validators/ValidateGuid";

var accessGroupsService = new AccessGroupsService();
var locationService = new LocationService();

interface IGroupAccessRow {
  type: string;
  control: IFormControl<string>;
  AADName: string;
}

export function GroupAccessRow(props: IGroupAccessRow) {
  const windowResize = useWindowResize();

  if (windowResize > 768)
    return <TableRow>
      <TableRowCell>{props.type}</TableRowCell>
      <TableRowCell> <FormFieldTextInput formControl={props.control} /> </TableRowCell>
      <TableRowCell>{props.AADName}</TableRowCell>
    </TableRow>;
  else
    return <TableRow>
      <TableRowCell>
        <div className="mobile-access-groups-table-cell">
          <div>
            <div className="small-copy mobile-access-groups-table-cell-label">{translate("GLOBALADMIN.ACCESSGROUPS.Type")}</div>
            <div>{props.type}</div>
          </div>
          <div>
            <div className="small-copy mobile-access-groups-table-cell-label">{translate("GLOBALADMIN.ACCESSGROUPS.ID")}</div>
            <FormFieldTextInput formControl={props.control} />
          </div>
          {props.AADName ?
            <div>
              <div className="small-copy mobile-access-groups-table-cell-label">{translate("GLOBALADMIN.ACCESSGROUPS.Name")}</div>
              <div>{props.AADName}</div>
            </div> : null}
        </div>
      </TableRowCell>

    </TableRow>;
}
export interface LocationGroup {
  type: string;
  typeId: LocationGroupType;
  id: string;
  name: string;
}

export function EditLocationGroupAccessPage() {
  const { id: locationId } = useParams();
  const openPopup = usePopup();

  const createNotification = useCreateNotification();

  const [location, setLocation] = useState<LocationDTO>();
  const [locationGroups, setLocationGroups] = useState<LocationGroups>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [onClickSubmit, setOnClickSubmit] = useState<boolean>(false);


  /****************************
      * FORM CONTROL
      *****************************/
  const basicAccessGuidFormControl = useFormControl<string>({
    validators: [validateGuid()],
    enableAutoValidate: true,
  });

  const teamLeaderGuidFormControl = useFormControl<string>({
    validators: [validateGuid()],
    enableAutoValidate: true,
  });

  const adminGuidFormControl = useFormControl<string>({
    validators: [validateGuid()],
    enableAutoValidate: true,
  });

  const financesGuidFormControl = useFormControl<string>({
    validators: [validateGuid()],
    enableAutoValidate: true,
  });




  useEffect(() => {
    if (locationId) {

      locationService.getLocationById(locationId)
        .then((data) => {
          console.log("Test 01", data);
          setLocation(data);
        })
        .catch((error) => {
          if (!error)
            return;
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }
  }, []);


  useEffect(() => {
    getLocationGroups();
  }, [locationId]);


  const getLocationGroups = useCallback(() => {
    if (locationId) {
      setIsLoading(true);

      accessGroupsService.getLocationGroups("" + locationId)
        .then((response) => {
          setLocationGroups(response);
           basicAccessGuidFormControl.setValue(response.basicAccess.objectId); 
          teamLeaderGuidFormControl.setValue(response.teamLeader.objectId);
          adminGuidFormControl.setValue(response.admin.objectId);
          financesGuidFormControl.setValue(response.finances.objectId);
          setIsLoading(false);
        }).catch((error) => {
          if (!error) return;
          setIsLoading(false);
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        })
    }
  }, [locationId, setLocationGroups, setIsLoading, openPopup,
    basicAccessGuidFormControl.setValue, teamLeaderGuidFormControl.setValue,
    adminGuidFormControl.setValue, financesGuidFormControl.setValue]);




  const handleSaveBtnClicked = useCallback(() => {
    if (locationId) {

      let formValid = /* baseAccessGuidFormControl.isValid && */
        adminGuidFormControl.isValid &&
        teamLeaderGuidFormControl.isValid &&
        financesGuidFormControl.isValid;

      if (!formValid) {
        openPopup(<ErrorPopup>
          {translate("GLOBALADMIN.ACCESSGROUPS.SubmitError")}
        </ErrorPopup>);
        return;
      }

      setOnClickSubmit(true);

      const mapGroup = (type: LocationGroupType, objectId: string | undefined): UpdateLocationGroupItem => {
        return ({ type, objectId: objectId || "" });
      }

      var lg: UpdateLocationGroups = {
        admin: mapGroup(LocationGroupType.ADMIN, adminGuidFormControl.value),
        basicAccess: mapGroup(LocationGroupType.ACCESS, basicAccessGuidFormControl.value), 
        finances: mapGroup(LocationGroupType.FINANCE, financesGuidFormControl.value),
        teamLeader: mapGroup(LocationGroupType.TEAM_LEADER, teamLeaderGuidFormControl.value)
      };

      accessGroupsService.updateLocationGroup(locationId, lg)
        .then((_) => {
          setOnClickSubmit(false);
          getLocationGroups();
          createNotification(<Notification type="success"
            title={translate("COMMON.SYSTEMPOPUPS.Success")}
            text={translate("GLOBALADMIN.ACCESSGROUPS.EditedGroupSuccess")}></Notification>);
        })
        .catch((error) => {
          setOnClickSubmit(false);
          if (!error) return;
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>)
        });
    }
  }, [openPopup, setOnClickSubmit, locationId, locationGroups, getLocationGroups, createNotification,
    adminGuidFormControl.value, basicAccessGuidFormControl.value, financesGuidFormControl.value, teamLeaderGuidFormControl.value,
    basicAccessGuidFormControl.isValid, adminGuidFormControl.isValid, teamLeaderGuidFormControl.isValid, financesGuidFormControl.isValid]);

  return (
    <PageLayout

      tabTitle={`${translate("INVENTORY.Inventory")} - ${translate("GLOBALADMIN.LOCATIONS.AccessGroups")}`}
      pageTitle={translate("GLOBALADMIN.LOCATIONS.AccessGroups")}
      className="location-groups"
      showBreadcrumb
      actions={
        <>
          <Button
            text={translate("COMMON.Save")}
            type="primary"
            onClick={handleSaveBtnClicked}
            isDisabled={isLoading}
          ></Button>
        </>
      }
    >
      {onClickSubmit ? <FullScreenLoader /> : null}
      {isLoading ? <div className="access-groups-loader">
        <Loader />
      </div> : (
        <div className={"group-access-table"}>
          <Table>
            <TableHeader>
              <TableHeaderCell key="1" >{translate("INVENTORY.Type")}</TableHeaderCell>
              <TableHeaderCell key="2">ID</TableHeaderCell>
              <TableHeaderCell key="3">{translate("INVENTORY.Name")}</TableHeaderCell>
            </TableHeader>


            <TableBody>
              <GroupAccessRow control={adminGuidFormControl} AADName={locationGroups?.admin.name || ""} type={translate("INVENTORY.ROLES.Admin")} />
              <GroupAccessRow control={financesGuidFormControl} AADName={locationGroups?.finances.name || ""} type={translate("INVENTORY.ROLES.Finances")} />
              <GroupAccessRow control={teamLeaderGuidFormControl} AADName={locationGroups?.teamLeader.name || ""} type={translate("INVENTORY.ROLES.TeamLeader")} />
              <GroupAccessRow control={basicAccessGuidFormControl} AADName={locationGroups?.basicAccess.name || ""} type={translate("INVENTORY.ROLES.BasicAccess")} />
            </TableBody>
          </Table>
        </div>)}
    </PageLayout>
  );
}

import "./AdminStorageLocationDetailsPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { InfoBlock } from "../../../lib/components/info-block/InfoBlock";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { TitledCardContainer } from "../../../lib/layouts/containers/card/TitledCardContainer";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { AdminStorageLocationsService } from "../../inventory-mock/services/AdminStorageLocationsService";
import { useParams } from "react-router-dom";
import { useServiceCallPro2 } from "../../../lib/hooks/useServiceCall";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { useClosePopup, useCreateNotification, usePopup } from "../../../lib/infrastructure/ui/UIServices";
import { StorageLocationSummary } from "../../inventory-mock/models/domain/admin-storage-locations/StorageLocationSummary";
import { useUpdatePageData } from "../../../lib/infrastructure/navigation/hooks/useUpdatePageData";
import { StorageBinSummary } from "../../inventory-mock/models/domain/admin-storage-locations/StorageBinSummary";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { ScalableIconButton } from "../../../lib/components/buttons/ScalableIconButton";
import { WarningPopup } from "../../../lib/components/popup/WarningPopup";
import { ReactComponent as DeleteIcon } from "../../../lib/assets/icons/remover.svg";
import { Notification } from "../../../lib/components/notifications/Notification";
import { ConditionalRender } from "../../../lib/functional/ConditionalRender";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { Button } from "../../../lib/components/buttons/Button";
import { AddStorageLocationBinPopup } from "./popups/AddStorageLocationBinPopup";


var storageSvc = new AdminStorageLocationsService();


export function AdminStorageLocationDetailsPage() {

    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const { id, code } = useParams();
    const updateRouteData = useUpdatePageData();
    const createNotification = useCreateNotification();



    const [storageSummary, setStorageSummary] = useState<StorageLocationSummary>();
    const [storageBins, setStorageBins] = useState<StorageBinSummary[]>();

    const [getStorageDetailsCall, getStorageDetailsCallIsLoading] = useServiceCallPro2(storageSvc, storageSvc.getAdminStorageLocationByCode);
    const [getStorageBinsCall, getStorageBinsCallIsLoading] = useServiceCallPro2(storageSvc, storageSvc.getStorageBinByStorageCode);
    const [deleteBinCall, deleteBinCallIsLoading] = useServiceCallPro2(storageSvc, storageSvc.deleteBinFromStorageLocation);


    useEffect(() => {
        getStorageLocationDetails();
        getStorageBins();
    }, [id, code]);





    const getStorageLocationDetails = useCallback(() => {

        if (!id || !code) return;

        getStorageDetailsCall(id, code)
            .then((data) => {
                setStorageSummary(data);
                updateRouteData(["##STORAGE##", data.locSap && data.description ? data.locSap + " <" + data.description + ">" : data.locSap ?? "-"]);
            })
            .catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [id, code, setStorageSummary, openPopup, updateRouteData, getStorageDetailsCall]);




    const getStorageBins = useCallback(() => {

        if (!id || !code) return;

        getStorageBinsCall(id, code)
            .then((data) => {
                setStorageBins(data);
            })
            .catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [id, code, setStorageBins, openPopup, getStorageBinsCall]);






    const renderCardContainerInfo = useMemo(() => {
        return <>
            <InfoBlock
                label={translate("INVENTORY.STORAGE.INFOANDFORM.SAPLoc")}
                value={storageSummary?.locSap || "-"}
            />
            <InfoBlock
                label={translate("GLOBALADMIN.STORAGES.Info.Name")}
                value={storageSummary?.description || "-"} />
        </>
    }, [storageSummary]);



    const handleRemoveBinButtonClicked = useCallback((binID: number) => {

        if (!id || !storageSummary?.id) return;

        deleteBinCall(id, storageSummary?.id, binID)
            .then((_) => {
                getStorageBins();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("GLOBALADMIN.STORAGES.Messages.RemoveBinSuccess")}
                    ></Notification>
                );
            })
            .catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });


    }, [id, storageSummary?.id, getStorageBins, createNotification, openPopup, deleteBinCall]);



    const columns: ColumnDefinition<StorageBinSummary>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => v.code,
                headerRender: (<>{translate("INVENTORY.STORAGE.Description")}</>),
                columnKey: "description",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => (
                    <ScalableIconButton
                        icon={<DeleteIcon />}
                        onClick={() => {
                            openPopup(
                                <WarningPopup
                                    className="button-red"
                                    onDismissClicked={() => { closePopup(); }}
                                    onConfirmClicked={() => { handleRemoveBinButtonClicked(v.id); }}
                                    dismissText={translate("COMMON.Cancel")}
                                    confirmText={translate("COMMON.Remove")}
                                >
                                    {translate("GLOBALADMIN.STORAGES.Messages.RemoveBinConfirmText", v.code, storageSummary?.locSap ?? "-", storageSummary?.description ?? "-")}
                                </WarningPopup>
                            );
                        }}
                        size={30}
                    />
                ),
                width: "3%",
                columnKey: "stats",
                isSortable: false,
            },

        ], [closePopup, handleRemoveBinButtonClicked, openPopup, storageSummary?.description, storageSummary?.locSap]);




    const actionButtons = useMemo(() => {

        if (!id || !storageSummary?.id) return;
        else
            return <Button text={translate("GLOBALADMIN.STORAGES.BINS.AddBinShort")} type="primary"
                onClick={() =>
                    openPopup(
                        <AddStorageLocationBinPopup
                            locationID={`${id}`}
                            storageID={`${storageSummary?.id}`}
                            storageSapCode={`${storageSummary?.locSap}`}
                            storageDescription={`${storageSummary?.description}`}
                            onCompletedOperations={getStorageBins}
                        />
                    )} />

    }, [id, storageSummary?.id, storageSummary?.locSap, storageSummary?.description, getStorageBins, openPopup]);




    return (
        <PageLayout
            tabTitle={`${translate("INVENTORY.Inventory")} - ${translate("INVENTORY.StoragePlural")}`}
            pageTitle={translate("INVENTORY.ITEM.INFOANDFORM.StorageBinPlural")}
            className="storage-details-page"
            showBreadcrumb
            actions={actionButtons}
        >

            <ConditionalRender if={deleteBinCallIsLoading}>
                <FullScreenLoader />
            </ConditionalRender>

            <div className="storage-general-info">
                <TitledCardContainer
                    title={translate("INVENTORY.StorageData")}
                    className="storage-data"
                    isLoading={getStorageDetailsCallIsLoading} >
                    <Spacer mode="vertical" px="16" />
                    <div className="storage-data-content-info">
                        {renderCardContainerInfo}
                    </div>
                </TitledCardContainer >
            </div>

            <Spacer mode="vertical" px="20" />
            <div className="subtitle">{translate("INVENTORY.ITEM.INFOANDFORM.BinPlural")}</div>
            <Spacer mode="vertical" px="12" />

            <ResponsiveDataTable
                totalitems={storageBins?.length || 0}
                columnDefinitions={columns}
                items={storageBins || []}
                isLoading={getStorageBinsCallIsLoading}
            ></ResponsiveDataTable>
        </PageLayout >
    );
}

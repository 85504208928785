import { Optional, useFormControl } from "../../../../../lib/components/form/Form";
import { regexIntegerOrDecimals, validateRegexOrEmpty } from "../../../../../common/validators/ValidateRegexOrEmpty";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { FormContainer } from "../../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { InventoryItemCountsService } from "../../../services/InventoryItemCountsService";
import { InventoryItemsService } from "../../../services/InventoryItemsService";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../../lib/utils/TrimString";
import { useServiceCallPro } from "../../../../../services/UseServiceCall";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { formatNumber } from "../../../../../common/helpers/RoundNumber";
import { InventoryItemCountUpdateDTO } from "../../../models/dtos/InventoryItemCountDTOs/InventoryItemCountUpdateDTO";
import { ConditionalRender } from "../../../../../lib/functional/ConditionalRender";
import { InventoryItemDetails } from "../../../models/domain/inventory-items/InventoryItemDetails";
import { parse } from "path";
import { InfoBlock } from "../../../../../lib/components/info-block/InfoBlock";
import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { Checkbox } from "../../../../../lib/components/checkbox/Checkbox";

var svc = new InventoryItemsService();
var svcCounts = new InventoryItemCountsService();
var svcInventoryItems = new InventoryItemsService();

function replaceCommaByPoint(value: string | undefined) {
    if (!value) return null;
    if (value?.includes(','))
        return trimString(value.replace(',', '.'));
    return trimString(value);
}

export interface IEditInventoryDocItemQuantityPopupProps {
    locationID: string;
    inventoryID: string;
    inventoryItemID: string;
    inventoryItemCountId: string;
    itemUnit?: string;
    onCompletedOperations: () => void;
}


export function EditInventoryDocItemQuantityPopup(props: IEditInventoryDocItemQuantityPopupProps) {
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();

    const getInventoryItemCountCall = useServiceCallPro(svcCounts.getInventoryItemCountByID);
    const putDocumentQuantity = useServiceCallPro(svcCounts.updateInventoryItemCount);

    const getInventoryItemByIdCall = useServiceCallPro(svcInventoryItems.getInventoryItemById);


    const [inventoryItemDetails, setInventoryDetails] = useState<InventoryItemDetails>();

    const [isM2CalculatorSelected, setIsM2CalculatorSelected] = useState<boolean>(false);


    const itemCountQuantity = useFormControl<string>({
        validators: [validateRegexOrEmpty(regexIntegerOrDecimals, false, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
        enableAutoValidate: true,
    });

    const itemLengthFormControl = useFormControl<string>({
        validators: [validateRegexOrEmpty(regexIntegerOrDecimals, false, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
        enableAutoValidate: true,
    });


    const itemWidthFormControl = useFormControl<string>({
        validators: [validateRegexOrEmpty(regexIntegerOrDecimals, false, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
        enableAutoValidate: true,
    });



    useEffect(() => {
        if (!props.locationID || !props.inventoryID || !props.inventoryItemID || !props.inventoryItemCountId) return;

        getInventoryItemByIdCall.invoke(props.locationID, props.inventoryID, props.inventoryItemID)
            .then((response) => {
                setInventoryDetails(response);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationID, props.inventoryID, props.inventoryItemID]);



    const isUnitM2 = useMemo(() => {
        return inventoryItemDetails?.units?.toLowerCase() === "m2";
    }, [inventoryItemDetails?.units]);



    useEffect(() => {
        if (!props.locationID || !props.inventoryID || !props.inventoryItemID || !props.inventoryItemCountId) return;

        getInventoryItemCountCall.invoke(props.locationID, props.inventoryID, props.inventoryItemID, props.inventoryItemCountId)
            .then((r) => {
                itemCountQuantity.setValue(r.quantity ? formatNumber(r?.quantity) : "0");
                itemLengthFormControl.setValue(r.length ? formatNumber(r?.length) : "0");
                itemWidthFormControl.setValue(r.width ? formatNumber(r?.width) : "0");
                if (r.length && r.width) {
                    setIsM2CalculatorSelected(true);
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationID, setIsM2CalculatorSelected, props.inventoryID, props.inventoryItemID, props.inventoryItemCountId]);








    useEffect(() => {

        if (isUnitM2 && isM2CalculatorSelected) {
            if (AllValid(itemWidthFormControl.validate(), itemLengthFormControl.validate())) {
                var area = parseFloat(replaceCommaByPoint(itemLengthFormControl.value) || "0") * parseFloat(replaceCommaByPoint(itemWidthFormControl.value) || "0");
                itemCountQuantity.setValue("" + area);
            }
            else {
                itemCountQuantity.setValue("0");
            }
        }
    }, [isUnitM2, isM2CalculatorSelected, itemWidthFormControl.value, itemLengthFormControl, inventoryItemDetails?.units])








    const handleEditBtnClicked = useCallback(() => {

        if (!props.locationID || !props.inventoryID || !props.inventoryItemID || !props.inventoryItemCountId) return;

        var request: InventoryItemCountUpdateDTO = {
            quantity: 0,
            length: undefined,
            width: undefined
        };

        if ((isUnitM2 && !isM2CalculatorSelected) || !isUnitM2) {
            if (!itemCountQuantity.validate()) {
                return;
            }

            request.quantity = parseFloat(replaceCommaByPoint(itemCountQuantity.value) || "0");
        }

        if (isUnitM2 && isM2CalculatorSelected) {
            if (!itemLengthFormControl.validate() || !itemWidthFormControl.validate()) {
                return;
            }

            request.quantity = parseFloat(replaceCommaByPoint(itemLengthFormControl.value) || "0") * parseFloat(replaceCommaByPoint(itemWidthFormControl.value) || "0");
            request.length = parseFloat(replaceCommaByPoint(itemLengthFormControl.value) || "0");
            request.width = parseFloat(replaceCommaByPoint(itemWidthFormControl.value) || "0");
        }


        putDocumentQuantity.invoke(props.locationID, props.inventoryID, `${props.inventoryItemID}`, `${props.inventoryItemCountId}`, request)
            .then(() => {
                props.onCompletedOperations();
                closePopup();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("INVENTORY.ITEM.POPUP.EditQuantitySuccessMessage")}
                    ></Notification>
                );
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationID,
    props.inventoryID,
    props.inventoryItemID,
    props.inventoryItemCountId,
    itemCountQuantity.validate,
    itemCountQuantity.value,
    itemLengthFormControl.validate,
    itemLengthFormControl.value,
    itemWidthFormControl.value,
    itemWidthFormControl.validate,
    props.onCompletedOperations,
        closePopup,
        createNotification,
        openPopup,
        closePopup,
        isM2CalculatorSelected,
        isUnitM2]);








    return (
        <PopupContainer className="popup-edit-sapDocumentQuantity">
            {putDocumentQuantity.isLoading ? <FullScreenLoader /> : null}
            <PopupHeader
                title={translate("INVENTORY.ITEM.POPUP.EditQuantity")}
            />
            <PopupContent isLoading={getInventoryItemCountCall.isLoading || getInventoryItemByIdCall.isLoading} >


                <FormContainer>

                    <ConditionalRender if={isUnitM2}>
                        <Checkbox
                            isChecked={isM2CalculatorSelected}
                            text={translate("INVENTORY.ITEM.INFOANDFORM.CalcQuantityM2")}
                            onClick={() => setIsM2CalculatorSelected(!isM2CalculatorSelected)}
                        />

                        <ConditionalRender if={isM2CalculatorSelected}>
                            <FormFieldTextInput
                                formControl={itemLengthFormControl}
                                label={`${translate("INVENTORY.ITEM.INFOANDFORM.Length")} (m)`}
                                placeholder={translate("INVENTORY.ITEM.INFOANDFORM.LengthPlaceholder")}
                            />
                            <FormFieldTextInput
                                formControl={itemWidthFormControl}
                                label={`${translate("INVENTORY.ITEM.INFOANDFORM.Width")} (m)`}
                                placeholder={translate("INVENTORY.ITEM.INFOANDFORM.WidthPlaceholder")}
                            />
                            <InfoBlock
                                label={`${translate("INVENTORY.ITEM.INFOANDFORM.Quantity")} (m2)`}
                                value={itemCountQuantity.value || "0"}
                            />
                        </ConditionalRender>

                    </ConditionalRender>

                    <ConditionalRender if={!isUnitM2 || (isUnitM2 && !isM2CalculatorSelected)}>
                        <FormFieldTextInput
                            formControl={itemCountQuantity}
                            label={translate("INVENTORY.ITEM.INFOANDFORM.Quantity") + ` (${props.itemUnit || ''})`}
                            placeholder={translate("INVENTORY.ITEM.INFOANDFORM.QuantityPlaceholder")}
                        />
                    </ConditionalRender>

                </FormContainer>
                <Spacer mode={"vertical"} px="30"></Spacer>
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Edit"),
                            type: "primary",
                            onClick: handleEditBtnClicked
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}

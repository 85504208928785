import * as qs from "qs";

import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { InventoryItemSummary } from "../models/domain/inventory-items/InventoryItemSummary";
import { InventoryItemsResponse } from "../models/domain/responses/InventoryItemsResponse";
import { InventoryItemsResponseDTO } from "../models/dtos/InventoryItemDTOs/InventoryItemsResponseDTO";
import { InventoryStats } from "../models/domain/inventory/InventoryStats";
import { InventoryStatsDTO } from "../models/dtos/InventoryDTOs/InventoryStatsDto";
import { InventoryStorageLocationDetails } from "../models/domain/storage/InventoryStorageLocationDetails";
import { InventoryStorageLocationDetailsDTO } from "../models/dtos/InventoryStorageLocationDTOs/InventoryStorageLocationDetailsDTO";
import { InventoryStorageSummary } from "../models/domain/storage/InventoryStorageSummary";
import { InventoryStoragesResponse } from "../models/domain/responses/InventoryStoragesResponse";
import { InventoryStoragesResponseDTO } from "../models/dtos/InventoryStorageLocationDTOs/InventoryStoragesResponseDTO";
import { QueryOptions } from "../../../models/domain/materials/QueryOptions";
import { StorageLocationItemsQueryOptions } from "../models/dtos/InventoryStorageLocationDTOs/StorageLocationItemsQueryOptions";

const Route = (path: string) => {
  return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class StorageService {
  getStorageLocations(locationId: string, inventoryID: string, options: QueryOptions): Promise<InventoryStoragesResponse> {
    return HttpClient.sessionRequest<InventoryStoragesResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryID}/StorageLocations`),
      params: options,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    })
      .then((response) => {
        var mappedStorages: InventoryStorageSummary[] =
          response.data.storageLocations.map((ob) => ({
            code: ob.code,
            name: ob.name,
            totalItens: ob.totalItens,
            totalInCounting: ob.totalInCounting,
            totalReadyToBeValidated: ob.totalReadyToBeValidated,
            totalValidated: ob.totalValidated,
            totalClosed: ob.totalClosed,
            totalNotStarted: ob.totalNotStarted,
            totalStarted: ob.totalStarted,
            totalValidatedCumulative: ob.totalValidatedCumulative
          }));

        var resp: InventoryStoragesResponse = {
          storageLocations: mappedStorages,
          totalItems: response.data.totalItems
        }
        return resp;
      })
      .catch((error) => {
        throw error;
      });
  }

  getStorageLocationDetails(locationId: string, inventoryID: string, storageLocationCode: string): Promise<InventoryStorageLocationDetails> {
    return HttpClient.sessionRequest<InventoryStorageLocationDetailsDTO>({
      method: "GET",
      url: Route(
        `/api/v1/Locations/${locationId}/Inventories/${inventoryID}/StorageLocations/${storageLocationCode}`
      ),
    })
      .then((response) => {
        var resp: InventoryStorageLocationDetailsDTO = {
          code: response.data.code,
          name: response.data.name,
          numberOfMaterials: response.data.numberOfMaterials,
          isCurrentUserStorageManager: response.data.isCurrentUserStorageManager
        };
        return resp;
      })
      .catch((error) => {
        throw error;
      });
  }

  getStorageLocationItemsByCode(
    locationId: string,
    inventoryID: string,
    storageLocationCode: string,
    abortSignal: AbortSignal | undefined,
    options: StorageLocationItemsQueryOptions
  ): Promise<InventoryItemsResponse> {
    return HttpClient.sessionRequest<InventoryItemsResponseDTO>({
      method: "GET",
      url: Route(
        `/api/v1/Locations/${locationId}/Inventories/${inventoryID}/StorageLocations/${storageLocationCode}/Items`
      ),
      params: options,
      signal: abortSignal,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    })
      .then((response) => {

        if (response) {
          var mappedItems: InventoryItemSummary[] = response.data.items.map(
            (ob) => ({
              id: ob.id,
              materialNumber: ob.materialNumber,
              materialDescription: ob.materialDescription,
              batch: ob.batch,
              storage: ob.storage,
              storageName: ob.storageName ? ob.storageName : null,
              units: ob.units,
              quantitySAP: ob.quantitySAP,
              quantityCounted: ob.quantityCounted,
              status: ob.status,
              isNew: ob.isNew,
              bin: ob.bin,
              typeSAP: ob.typeSAP,
              specialSAP: ob.specialSAP,
              isProblematic: ob.isProblematic ?? false,
              monetaryDeviationValue: ob.monetaryDeviationValue,
              monetarySAP: ob.monetarySAP,
              quantityDeviationPercentage: ob.quantityDeviationPercentage,
              permissions: ob.permissions,
              hasComments: ob.hasComments
            })
          );

          return {
            items: mappedItems,
            totalItems: response.data.totalItems
          }
        }

        return {
          items: [],
          totalItems: 0
        }
      })
      .catch((error) => {
        throw error;
      });
  }


  getStorageLocationStats(locationId: string, inventoryID: string, storageLocationCode: string): Promise<InventoryStats> {
    return HttpClient.sessionRequest<InventoryStatsDTO>({
      method: "GET",
      url: Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryID}/StorageLocations/${storageLocationCode}/Stats`)
    })
      .then((r) => {
        var response: InventoryStats = {
          totalItens: r.data.totalItens,
          totalInCounting: r.data.totalInCounting,
          totalReadyToBeValidated: r.data.totalReadyToBeValidated,
          totalValidated: r.data.totalValidated,
          totalClosed: r.data.totalClosed,
        };

        return response;
      })
      .catch((error) => { throw error; });
  }



  autoValidateStorageItems(locationId: string, inventoryID: string, storageLocationCode: string): Promise<void> {
    return HttpClient.sessionRequest({
      method: "PUT",
      url: Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryID}/StorageLocations/${storageLocationCode}/AutoValidate`)
    })
      .then((_) => { })
      .catch((error) => { throw error; });
  }

}

import * as qs from "qs";

import { ConfigurationProvider } from "../lib/infrastructure/configuration/ConfigurationProvider";
import { CreateMaterialRequestDTO } from "../models/api/materials/CreateMaterialRequestDTO";
import { HttpClient } from "../lib/infrastructure/http/HttpClient";
import { Material } from "../models/domain/materials/Material";
import { MaterialDTO } from "../models/api/materials/MaterialDTO";
import { MaterialsQueryOptions } from "../models/api/materials/MaterialsQueryOptions";
import { MaterialsResponseDTO } from "../models/api/materials/MaterialsResponseDTO";
import { MaterialsTotalItems } from "../models/domain/materials/MaterialsTotalItems";
import { UpdateMaterialRequestDTO } from "../models/api/materials/UpdateMaterialRequestDTO";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};


export class MaterialsService {

    getMaterials(locationId: number | string, queryOptions?: MaterialsQueryOptions): Promise<MaterialsTotalItems> {

        return HttpClient.sessionRequest<MaterialsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Materials`),
            params: queryOptions,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {
                var mappedMaterials = response.data.materials.map(
                    (p): Material => ({
                        materialId: p.materialId,
                        description: p.description,
                        name: p.matNumber,
                        plant: "-",
                        locationId: p.locationId,
                        unitValue: p.unitValue,
                        units: p.units
                    })
                );
                return {
                    materials: mappedMaterials,
                    totalMaterials: response.data.total
                }
            }).catch((error) => {
                throw error;
            });
    }



    getById(locationId: string, materialId: number): Promise<Material> {
        return HttpClient.sessionRequest<MaterialDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Materials/${materialId}`),
        })
            .then((response) => {
                var mappedMaterial: Material = {
                    materialId: response.data.materialId,
                    plant: "-",
                    name: response.data.matNumber,
                    description: response.data.description,
                    locationId: response.data.locationId,
                    unitValue: response.data.unitValue,
                    units: response.data.units,
                };
                return mappedMaterial;
            })
            .catch((error) => {
                throw error;
            });
    }


    getBySapCode(locationId: string, materialId: string): Promise<Material> {
        return HttpClient.sessionRequest<MaterialDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Materials/BySapCode/${materialId}`),
        })
            .then((response) => {
                var mappedMaterial: Material = {
                    materialId: response.data.materialId,
                    plant: "-",
                    name: response.data.matNumber,
                    description: response.data.description,
                    locationId: response.data.locationId,
                    unitValue: response.data.unitValue,
                    units: response.data.units,
                };
                return mappedMaterial;
            })
            .catch((error) => {
                throw error;
            });
    }



    addMaterial(locationId: string, requestDto: CreateMaterialRequestDTO): Promise<void> {

        return HttpClient.sessionRequest({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/Materials`),
            data: requestDto
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }


    uploadMaterialFile(locationId: string, file: File): Promise<void> {

        var formData = new FormData();
        formData.append("file", file)

        return HttpClient.sessionRequest({
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
            },
            url: Route(`/api/v1/Locations/${locationId}/Materials/asCSV`),
            data: formData
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }



    disableMaterial(locationId: string, materialId: number): Promise<void> {

        return HttpClient.sessionRequest({
            method: "DELETE",
            url: Route(`/api/v1/Locations/${locationId}/Materials/${materialId}`),
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    enableMaterial(locationId: string, materialId: number): Promise<void> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/Materials/${materialId}/EnableMaterial`),
        })
            .then((_) => {
            })
            .catch((error) => {
                throw error;
            });
    }


    updateMaterial(locationId: string, materialId: number, requestDto: UpdateMaterialRequestDTO): Promise<void> {

        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/Materials/${materialId}`),
            data: requestDto
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }
}

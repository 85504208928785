import * as qs from "qs";

import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { QueryOptions } from "../../../models/domain/materials/QueryOptions";
import { StorageBinSummary } from "../models/domain/admin-storage-locations/StorageBinSummary";
import { StorageBinsResponseDTO } from "../models/api/storage/StorageBinsResponseDTO";
import { StorageLocationCreateDTO } from "../models/dtos/StorageLocationDTOs/StorageLocationCreateDTO";
import { StorageLocationDetailsDTO } from "../models/dtos/StorageLocationDTOs/StorageLocationDetailsDTO";
import { StorageLocationSummary } from "../models/domain/admin-storage-locations/StorageLocationSummary";
import { StorageLocationTotalItems } from "../models/domain/admin-storage-locations/StorageLocationTotalItems";
import { StorageLocationUpdateStatusRequestDTO } from "../models/dtos/StorageLocationDTOs/StorageLocationUpdateStatusRequestDTO";
import { StorageLocationsResponseDTO } from "../models/dtos/StorageLocationDTOs/StorageLocationsResponseDTO";
import { CreateBinRequestDto } from "../models/dtos/StorageLocationDTOs/CreateBinRequestDto";
import { StorageLocationIncludeBins } from "../models/domain/storage/StorageLocationIncludeBins";
import { StorageLocationIncludeBinsDTO } from "../models/api/storage/StorageLocationIncludeBinsDTO";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class AdminStorageLocationsService {



    getAdminStorageLocations(locationId: string, opts: QueryOptions): Promise<StorageLocationTotalItems> {
        return HttpClient.sessionRequest<StorageLocationsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/StorageLocations`),
            params: opts,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {

                if (response.data) {

                    var mappedItems: StorageLocationSummary[] = response.data.storages.map(ob => ({
                        id: ob.id,
                        locSap: ob.code,
                        description: ob.name,
                        isDisabled: ob.isDisabled,
                        storageBinSummary: ob.bins ? ob.bins.map(({ id, code }) => ({ id, code })) : null
                    }));

                    return {
                        items: mappedItems,
                        totalItems: response.data.totalItems
                    }
                }
                return {
                    items: [], totalItems: 0
                }

            })
            .catch((error) => {
                throw error;
            });
    }



    getAdminStorageLocationByCode(locationId: string, storageCode: string): Promise<StorageLocationSummary> {
        return HttpClient.sessionRequest<StorageLocationDetailsDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/StorageLocations/${storageCode}`),
        })
            .then((response) => {
                var mappedResponse: StorageLocationSummary = {
                    id: response.data.id,
                    description: response.data.name,
                    locSap: response.data.code,
                    isDisabled: response.data.isDisabled
                };
                return mappedResponse;
            })
            .catch((error) => {
                throw error;
            });
    }



    addAdminStorageLocation(locationId: string, requestDto: StorageLocationCreateDTO): Promise<void> {

        return HttpClient.sessionRequest({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/StorageLocations`),
            data: requestDto
        })
            .then(() => { })
            .catch((error) => {
                throw error;
            });
    }




    uploadStorageLocationsFromXLSX(locationId: string, file: File): Promise<void> {

        var formData = new FormData();
        formData.append("file", file)

        return HttpClient.sessionRequest({
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
            },
            url: Route(`/api/v1/Locations/${locationId}/StorageLocations/FromXLSX`),
            data: formData
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }





    deleteStorageLocation(locationId: string, storageLocationId: number): Promise<void> {
        return HttpClient.sessionRequest({
            method: "DELETE",
            url: Route(`/api/v1/Locations/${locationId}/StorageLocations/${storageLocationId}`),
        })
            .then((_) => { })
            .catch((error) => { throw error; });
    }




    uploadIStorageBinsFromXLSX(locationId: string, file: File): Promise<void> {

        var formData = new FormData();
        formData.append("file", file)

        return HttpClient.sessionRequest({
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
            },
            url: Route(`/api/v1/Locations/${locationId}/StorageLocations/Bins/FromXLSX`),
            data: formData
        })
            .then((_) => {

            })
            .catch((error) => {
                throw error;
            });
    }




    getStorageBinByStorageCode(locationId: string, storageCode: string): Promise<StorageBinSummary[]> {
        return HttpClient.sessionRequest<StorageBinsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/StorageLocations/${storageCode}/Bins`),
        })
            .then((response) => {

                if (response.data) {
                    var mappedBins: StorageBinSummary[] = response.data.bins.map(ob => ({
                        id: ob.id,
                        code: ob.code
                    }));

                    return mappedBins;
                }
                return [];
            })
            .catch((error) => {
                throw error;
            });
    }



    updateStorageLocationStatus(locationId: string, storageLocationId: number, request: StorageLocationUpdateStatusRequestDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/StorageLocations/${storageLocationId}/Status`),
            data: request
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }



    deleteBinFromStorageLocation(locationId: string, storageLocationId: number, binId: number): Promise<void> {
        return HttpClient.sessionRequest({
            method: "DELETE",
            url: Route(`/api/v1/Locations/${locationId}/StorageLocations/${storageLocationId}/Bins/${binId}`),
        })
            .then((_) => { })
    }

    addStorageLocationBin(locationID: string, storageLocationID: string, request: CreateBinRequestDto): Promise<void> {

        return HttpClient.sessionRequest({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationID}/StorageLocations/${storageLocationID}/Bins`),
            data: request,
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    getStorageLocationIncludeBins(locationId: string, storageLocationCode: string): Promise<StorageLocationIncludeBins | null> {
    
        return HttpClient.sessionRequest<StorageLocationIncludeBinsDTO>({
        method: "GET",
        url: Route(`/api/v1/Locations/${locationId}/StorageLocations/${storageLocationCode}/IncludeBins`)
      })
        .then((r) => {
  
          if(!r.data) 
            return null; 
  
          var response: StorageLocationIncludeBins = {
            code: r.data.code,
            name: r.data.name,
            binCodes: r.data.binCodes ?? []
          };
  
          return response;
        })
        .catch((error) => { throw error; });
    }



}

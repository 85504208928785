import { FormField } from "./FormField";
import { IFormControl } from "../Form";
import { TextInput } from "../text-input/TextInput";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";
import { useMemo } from "react";

interface IFormFieldTextInputProps<T> {
  label?: string;
  formControl: IFormControl<T>;
  icon?: React.ReactNode;
  placeholder?: string;
  className?: string;
  alternativeStyle?: boolean;
  ref?: React.RefObject<HTMLInputElement>;
  removePlaceholder?: boolean; 
  showLoading?: boolean; 

}

export function FormFieldTextInput<T>(props: IFormFieldTextInputProps<T>) {
  const placeholder = useMemo(() => props.removePlaceholder ? "" : props.placeholder ?? translate("COMMON.FORM.DefaultPlaceholder"),
    [props.placeholder]
  );

  return (
    <FormField label={props.label} formControl={props.formControl}>
      <TextInput
        value={props.formControl.value}
        placeholder={placeholder}
        icon={props.icon}
        isDisabled={props.formControl.isDisabled}
        onChange={(val) => props.formControl.setValue(val)}
        hasError={props.formControl.hasErrors}
        className={props.className}
        alternativeStyle={props.alternativeStyle}
        ref={props.ref}
        showLoading={props.showLoading}
      ></TextInput>
    </FormField>
  );
}

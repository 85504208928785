import { useCallback } from "react";
import { useFormControl } from "../../../../lib/components/form/Form";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { validateEmptyStrings } from "../../../../lib/validators/ValidateEmptyStrings";
import { useServiceCallPro2 } from "../../../../lib/hooks/useServiceCall";
import { AdminStorageLocationsService } from "../../../inventory-mock/services/AdminStorageLocationsService";
import { AllValid } from "../../../../common/validators/ValidateFormControls";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";

var svc = new AdminStorageLocationsService();

export interface AddStorageLocationPopupProps {
    locationID: string;
    onCompletedOperations: () => void;
}

export function AddStorageLocationPopup(props: AddStorageLocationPopupProps) {

    const createNotification = useCreateNotification();
    const closePopup = useClosePopup();
    const openPopup = usePopup();


    const [addStorageLocationsCall, addStorageLocationsCallIsLoading] = useServiceCallPro2(svc, svc.addAdminStorageLocation);



    const locSapFormControl = useFormControl<string>({
        validators: [validateEmptyStrings()],
        enableAutoValidate: true,
    });

    const nameFormControl = useFormControl<string>({
        validators: [validateEmptyStrings()],
        enableAutoValidate: true,
    });



    const handleAddButtonClicked = useCallback(() => {

        if (!AllValid(locSapFormControl.validate(), nameFormControl.validate())) return;

        addStorageLocationsCall(props.locationID, { name: nameFormControl.value ?? "", code: locSapFormControl.value ?? "" })
            .then((_) => {

                props.onCompletedOperations();
                closePopup(); 

                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("GLOBALADMIN.STORAGES.Messages.AddStorageSuccess")}
                    ></Notification>
                );
            })
            .catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locSapFormControl.validate, locSapFormControl.value, nameFormControl.validate, nameFormControl.value, closePopup,
    props.onCompletedOperations, createNotification, props.locationID, addStorageLocationsCall, openPopup])



    return (

        <PopupContainer className="popup-add-storage-locations">
            {addStorageLocationsCallIsLoading ? <FullScreenLoader /> : null}

            <PopupHeader
                title={translate("GLOBALADMIN.STORAGES.AddStorageLocation")}
            />
            <PopupContent>
                <FormContainer>
                    <FormFieldTextInput
                        formControl={locSapFormControl}
                        label={translate("GLOBALADMIN.STORAGES.Info.Code")}
                        placeholder="Ex: 5502"
                    />
                    <FormFieldTextInput
                        formControl={nameFormControl}
                        label={translate("GLOBALADMIN.STORAGES.Info.Name")}
                        placeholder={translate("GLOBALADMIN.STORAGES.Info.NamePlaceholder")}
                    />

                </FormContainer>
                <Spacer mode={"vertical"} px="30"></Spacer>
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Create"),
                            type: "primary",
                            onClick: handleAddButtonClicked
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}

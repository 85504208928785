import { InventoryItemStatusEnum } from "../../features/inventory-mock/models/domain/inventory-items/InventoryItemStatusEnum";

export function getInventoryItemStatusCss(statusId: InventoryItemStatusEnum) {
    switch (statusId) {
        case InventoryItemStatusEnum.UNKNOWN:
            return "status-red";
        case InventoryItemStatusEnum.COUNTING:
            return "status-yellow";
        case InventoryItemStatusEnum.READY_TO_BE_VALIDATED:
            return "status-sky-blue";
        case InventoryItemStatusEnum.VALIDATED: //TODO REVIEW THIS
            return "status-deep-blue";
        case InventoryItemStatusEnum.CLOSED:
            return "status-green";
        default:
            return undefined;
    }
}
import "./PdaSimulatorPage.css";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { useNavigate, useParams } from "react-router-dom";

import { ConditionalRender } from '../../../../lib/functional/ConditionalRender';
import { DateTime } from "luxon";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FormFieldTextInput } from '../../../../lib/components/form/form-field/FormFieldTextInput';
import { InventoriesService } from "../../services/InventoriesService";
import { InventoryItemsResponseDTO } from "../../models/dtos/InventoryItemDTOs/InventoryItemsResponseDTO";
import { InventoryItemsService } from "../../services/InventoryItemsService";
import { MobileInventoryService } from "../../services/MobileInventoryService";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { PdaSimulatorMaterialStep } from "./PdaSimulatorMaterialStep";
import { Spacer } from '../../../../lib/components/separator/Spacer';
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from '../../../../lib/components/form/Form';
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useServiceCallPro } from "../../../../services/UseServiceCall";
import { useServiceCallPro2 } from '../../../../lib/hooks/useServiceCall';

const inventorySvc = new InventoriesService();
const inventoryItemsSvc = new InventoryItemsService();
const mobileSvc = new MobileInventoryService();


export function PdaSimulatorPage() {


    const createNotification = useCreateNotification();
    const navigate = useNavigate();
    const openPopup = usePopup();
    const closePopup = useClosePopup();

    const globalLocationId = useGlobalLocation();
    const { id: inventoryId } = useParams();
    const locationId = useMemo(() => `${globalLocationId}`, [globalLocationId]);

    const [getStorageLocationItemsByCode, storageLocationItemsByCodeIsLoading] = useServiceCallPro2(mobileSvc, mobileSvc.getStorageLocationItemsByCode);

    const storageFormControl = useFormControl<string>({});

    const [storageInfo, setStorageInfo] = useState<InventoryItemsResponseDTO>();
    const [storageInfoErrorMessage, setStorageInfoErrorMessage] = useState<string>();

    const [idx, setIdx] = useState(1);


    useEffect(() => {
        if (!locationId || !inventoryId) {
            return;
        }

        const controller = new AbortController();

        if (storageFormControl.value) {
            getStorageLocationItemsByCode(locationId, inventoryId, storageFormControl.value, controller?.signal).then(res => {
                if (res.totalItems) {
                    setStorageInfo(res);
                    setStorageInfoErrorMessage(undefined);
                } else {
                    setStorageInfo(undefined);
                    setStorageInfoErrorMessage("Armazém desconhecido")
                }

            }).catch(_ => {
                setStorageInfo(undefined);
                setStorageInfoErrorMessage("Armazém desconhecido")
            })
        } else {
            setStorageInfo(undefined);
            setStorageInfoErrorMessage(undefined);
        }

        return () => { controller.abort() };
    }, [storageFormControl.value])


    /****************************
    * DATA REQUESTS
    *****************************/




    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/


    /****************************
     * USER ACTIONS
     *****************************/

    const handleOnCountSuccess = useCallback(() => {
        setIdx(idx + 1);

    }, [idx, setIdx])


    const handleCreateMaterial = useCallback(() => {
        if (!locationId || !inventoryId) {
            return;
        }

        if (storageFormControl.value) {
            getStorageLocationItemsByCode(locationId, inventoryId, storageFormControl.value, undefined ).then(res => {
                if (res.totalItems) {
                    setStorageInfo(res);

                }
            })
        }

    }, [locationId, inventoryId])

    /****************************
     * CSS & HTML
     *****************************/





    return (
        <PageLayout
            tabTitle={translate("INVENTORY.PDASimulator")}
            pageTitle={translate("INVENTORY.PDASimulator")}
            className="pda-simulator-page"
            showBreadcrumb
        >
            {/* {uploadFileCall.isLoading || validateItemIsLoading || closeItemIsLoading ? <FullScreenLoader /> : null} */}
            <div className="subtitle">{translate("INVENTORY.Storage")}</div>
            <Spacer mode="vertical" px={16} />

            <FormFieldTextInput formControl={storageFormControl} label={translate("INVENTORY.STORAGE.INFOANDFORM.SAPLoc")} />
            <div>{storageInfoErrorMessage}</div>

            <Spacer mode="vertical" px={16} />
            <ConditionalRender if={!!storageInfo}><div className="storage-items">Items: {storageInfo?.totalItems}</div></ConditionalRender>

            <Spacer mode="vertical" px={20} />
            <ConditionalRender if={!!storageInfo}>
                <div className="subtitle">{translate("INVENTORY.ITEM.INFOANDFORM.Material")}</div>
                <Spacer mode="vertical" px={16} />
                <PdaSimulatorMaterialStep key={idx} locationId={locationId} inventoryId={inventoryId} storageCode={storageFormControl.value} items={storageInfo?.items} onCountSuccess={handleOnCountSuccess} onCreateMaterialSuccess={handleCreateMaterial} />
            </ConditionalRender>

        </PageLayout >
    );
}

import "./SetInventoryDeviationPopup.css";

import { regexDecimalNumber, regexIntegerOrDecimals, validateRegex, validateRegexOrEmpty } from "../../../../common/validators/ValidateRegexOrEmpty";
import { useCallback, useEffect, useState } from "react"
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../common/validators/ValidateFormControls";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { InventoriesService } from "../../services/InventoriesService";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { SetDeviationRequestDTO } from "../../models/dtos/InventoryDTOs/SetDeviationRequestDTO";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../lib/utils/TrimString";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useServiceCallPro2 } from "../../../../lib/hooks/useServiceCall";

import { ConditionalRender } from "../../../../lib/functional/ConditionalRender";
import { Tag } from "../../../../lib/components/tag/Tag";

var svc = new InventoriesService();


export interface SetInventoryDeviationPopupProps {
    locationID: string;
    inventoryID: string;
    inventoryName?: string;
    onCompletedOperations: () => void;
}

function replaceCommaByPoint(value: string | undefined) {
    if (!value) return null;
    if (value?.includes(','))
        return trimString(value.replace(',', '.'));
    return trimString(value);
}



function tryParseFloat(str: string | undefined): number | null {

    if (str === undefined) return null;

    const parsed = parseFloat(str);
    if (isNaN(parsed)) {
        return null;
    }
    return parsed;
}



function validateIntervalOfStrings(min: string, max: string): boolean {

    const num1 = parseFloat(min);
    const num2 = parseFloat(max);

    if (isNaN(num1) || isNaN(num2)) {
        return false;
    }

    return num1 < num2;
}



export function SetInventoryDeviationPopup(props: SetInventoryDeviationPopupProps) {

    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();


    const [setDeviationCall, setDeviationCallIsLoading] = useServiceCallPro2(svc, svc.setInventoryDeviationDefinition);
    const [getDeviationDefinitionCall, getDeviationDefinitionCallIsLoading] = useServiceCallPro2(svc, svc.getInventoryDeviationDefinition);

    const [showQuantityDeviationErrorMessage, setShowQuantityDeviationErrorMessage] = useState<boolean>(false);
    const [showMonetaryDeviationErrorMessage, setShowMonetaryDeviationErrorMessage] = useState<boolean>(false);


    const qtyDifferencePercentageMinControl = useFormControl<string>({
        validators: [validateRegex(regexDecimalNumber, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
        enableAutoValidate: true,
    });

    const qtyDifferencePercentageMaxControl = useFormControl<string>({
        validators: [validateRegex(regexDecimalNumber, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
        enableAutoValidate: true,
    });

    const monetaryDiferenceMinControl = useFormControl<string>({
        validators: [validateRegex(regexDecimalNumber, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
        enableAutoValidate: true,
    });

    const monetaryDiferenceMaxControl = useFormControl<string>({
        validators: [validateRegex(regexDecimalNumber, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
        enableAutoValidate: true,
    });


    useEffect(() => {
        if (!props.locationID || !props.inventoryID) return;

        getDeviationDefinitionCall(props.locationID, props.inventoryID)
            .then((r) => {

                if (r?.id) {
                    qtyDifferencePercentageMinControl.setValue(r.quantityDifferencePercentageMin);
                    qtyDifferencePercentageMaxControl.setValue(r.quantityDifferencePercentageMax);
                    monetaryDiferenceMinControl.setValue(r.monetaryDifferenceMin);
                    monetaryDiferenceMaxControl.setValue(r.monetaryDifferenceMax);
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, []);




    const handleSetDeviationButtonClicked = useCallback(() => {
        if (!props.locationID || !props.inventoryID) return;

        if (!AllValid(qtyDifferencePercentageMinControl.validate(), qtyDifferencePercentageMaxControl.validate(),
            monetaryDiferenceMinControl.validate(), monetaryDiferenceMaxControl.validate())) return;

        var isQtdIntervalValid = validateIntervalOfStrings(qtyDifferencePercentageMinControl.value ?? "", qtyDifferencePercentageMaxControl.value ?? "");
        var isMonetaryInterValid = validateIntervalOfStrings(monetaryDiferenceMinControl.value ?? "", monetaryDiferenceMaxControl.value ?? "");

        if (!isQtdIntervalValid || !isMonetaryInterValid) {
            if (isQtdIntervalValid) setShowQuantityDeviationErrorMessage(false); else setShowQuantityDeviationErrorMessage(true);
            if (isMonetaryInterValid) setShowMonetaryDeviationErrorMessage(false); else setShowMonetaryDeviationErrorMessage(true);
            return;
        }


        var reqDto: SetDeviationRequestDTO = {
            quantityDifferencePercentageMin: replaceCommaByPoint(qtyDifferencePercentageMinControl.value) || "0",
            quantityDifferencePercentageMax: replaceCommaByPoint(qtyDifferencePercentageMaxControl.value) || "0",
            monetaryDifferenceMin: replaceCommaByPoint(monetaryDiferenceMinControl.value) || "0",
            monetaryDifferenceMax: replaceCommaByPoint(monetaryDiferenceMaxControl.value) || "0"
        }

        setDeviationCall(props.locationID, props.inventoryID, reqDto)
            .then((_) => {

                props.onCompletedOperations();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("INVENTORY.DEVIATION.SetDeviationSuccessMessage")}
                    ></Notification>
                );
                closePopup();
            }).catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [setDeviationCall, props.locationID, props.inventoryID,
        qtyDifferencePercentageMinControl.validate, qtyDifferencePercentageMaxControl.validate,
        monetaryDiferenceMinControl.validate, monetaryDiferenceMaxControl.validate,
        qtyDifferencePercentageMinControl.value, qtyDifferencePercentageMaxControl.value,
        monetaryDiferenceMinControl.value, monetaryDiferenceMaxControl.value, props.onCompletedOperations, closePopup,
        setShowQuantityDeviationErrorMessage, createNotification, setShowMonetaryDeviationErrorMessage, openPopup]);





    return (
        <PopupContainer className="popup-set-inventory-deviation">
            {false ? <FullScreenLoader /> : null}
            <PopupHeader title={translate("INVENTORY.DEVIATION.SetInventoryDeviation")} subtitle={props.inventoryName} />
            {setDeviationCallIsLoading ? <FullScreenLoader /> : null}
            <PopupContent isLoading={getDeviationDefinitionCallIsLoading}>


                <FormContainer>
                    <FormSection isInputGap>
                        <FormFieldTextInput
                            formControl={qtyDifferencePercentageMinControl}
                            label={translate("INVENTORY.DEVIATION.INFOANDFORM.PercentageOfMinimumQuantityDifference")}
                            placeholder={"Ex: -10"}
                        />
                        <FormFieldTextInput
                            formControl={qtyDifferencePercentageMaxControl}
                            label={translate("INVENTORY.DEVIATION.INFOANDFORM.PercentageOfMaximumQuantityDifference")}
                            placeholder={"Ex: 10"}
                        />
                    </FormSection>
                    <ConditionalRender if={showQuantityDeviationErrorMessage} >
                        <Tag isTiny backgroundColor="status-red" text={translate("INVENTORY.DEVIATION.INFOANDFORM.QtdDeviationIntervalErrorMessage")} />
                    </ConditionalRender>
                    <FormSection isInputGap>

                        <FormFieldTextInput
                            formControl={monetaryDiferenceMinControl}
                            label={translate("INVENTORY.DEVIATION.INFOANDFORM.MinimumMonetaryDifference")}
                            placeholder={"Ex: -150"}
                        />
                        <FormFieldTextInput
                            formControl={monetaryDiferenceMaxControl}
                            label={translate("INVENTORY.DEVIATION.INFOANDFORM.MaximunMonetaryDifference")}
                            placeholder={"Ex: 250"}
                        />
                    </FormSection>
                    <ConditionalRender if={showMonetaryDeviationErrorMessage} >
                        <Tag isTiny backgroundColor="status-red" text={translate("INVENTORY.DEVIATION.INFOANDFORM.MonetaryDeviationIntervalErrorMessage")} />
                    </ConditionalRender>

                </FormContainer>
                <Spacer mode={"vertical"} px="30"></Spacer>
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Set"),
                            type: "primary",
                            onClick: handleSetDeviationButtonClicked
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
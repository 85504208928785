import "./MainContainer.css";

import { useEffect, useMemo } from "react";
import {
  useSetSideBarButtons,
  useWindowResize,
} from "../../../lib/infrastructure/ui/UIServices";

import { ReactComponent as AdminIcon } from "../../../lib/assets/icons/administracao.svg";
import { ReactComponent as InventoriesIcon } from "../../../lib/assets/icons/inventories.svg";
import { Sidebar } from "../../../lib/components/side-bar/Sidebar";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { Permission } from "../../../models/api/gate/Permission";

interface IMainContainerProps {
  children: React.ReactNode;
}

export const topPermissions = [
  Permission.GLOBAL_ADMIN,
  Permission.LOCAL_ADMIN,
  Permission.FINANCES
];

export function MainContainer(props: IMainContainerProps) {
  const windowResize = useWindowResize();
  const setSideBarButtons = useSetSideBarButtons();


  const sideBarButtons = useMemo(() => [
    {
      icon: <InventoriesIcon />,
      navigateTo: "inventories",
      name: translate("INVENTORY.InventoriesPlural"),
    },
    {
      icon: <AdminIcon />,
      navigateTo: "settings/locations",
      name: translate("GLOBALADMIN.GlobalAdmin"),
      isBottomAligned: true
    },
  ], []);



  useEffect(() => {
    setSideBarButtons(sideBarButtons);
  }, [setSideBarButtons]);



  const renderSideBar = useMemo(() => {
    if (windowResize > 768) return <Sidebar buttons={sideBarButtons} />;
    else return null;
  }, [windowResize]);

  return (
    <div className="main-content-container">
      {renderSideBar}
      {props.children}
    </div>
  );
}

import { DateTime } from "luxon";

export function changeDateMonth(date: DateTime, isNextMonth: boolean): DateTime {
  if (date.month.valueOf() === 0 && !isNextMonth) {
    return date.set({year: date.year.valueOf() - 1, month: 11});
  }

  if (date.month.valueOf() === 11 && isNextMonth) {
    return date.set({year: date.year.valueOf() + 1, month: 0});
  }

  return date.plus({month: isNextMonth ? 1 : -1});
}

export interface ICalendarCell {
  text: string;
  value: DateTime;
}

function getCalendarCells(date: DateTime): ICalendarCell[] {
  const calendarCells: ICalendarCell[] = [];

  const firstDayOfTheMonth = date.startOf('month');
  const weekdayOfFirstDayOfMonth = firstDayOfTheMonth.weekday;
  let datePointer = firstDayOfTheMonth;

  if (weekdayOfFirstDayOfMonth.valueOf() === 0) {
    //If it is sunday we need to go back an entire week/entire row.
    datePointer = datePointer.minus({day: 7})
  } else {
    //Otherwise go back just the needed days.
    datePointer = datePointer.minus({day: weekdayOfFirstDayOfMonth})
  }

  for (let day = 0; day < 42; day++) {
    calendarCells.push(
        {
            text: String(datePointer.day),
            value: datePointer,
          });
        
          datePointer = datePointer.plus({day: 1});
  }

  return calendarCells;
}

export function getCalendarRows(date: DateTime): Array<ICalendarCell[]> {
  const cells = getCalendarCells(date);
  const rows: Array<ICalendarCell[]> = [];

  for (let i = 0; i < cells.length; i += 7) {
    rows.push(cells.slice(i, i + 7));
  }
  
  return rows;
}

import { APIInventoryItemHistoryDetailsToDomainMapper } from "../models/mappers/APIInventoryItemHistoryDetailsToDomainMapper";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { InventoryItemHistoryQueryOptions } from "../models/dtos/InventoryItemHistoryDTOs/InventoryItemHistoryQueryOptions";
import { InventoryItemHistoryResponseDTO } from "../models/dtos/InventoryItemHistoryDTOs/InventoryItemHistoryResponseDTO";
import { InventoryItemHistorySummary } from "../models/domain/inventory-item-history/InventoryItemHistorySummary";
import qs from "qs";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class InventoryItemHistoryService {
    getInventoryItemHistoryLines(locationId: string, inventoryID: string, itemID: string, queryOptions?: InventoryItemHistoryQueryOptions): Promise<InventoryItemHistorySummary> {
        return HttpClient.sessionRequest<InventoryItemHistoryResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryID}/Items/${itemID}/History`),
            params: queryOptions,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {

                return ({
                    historyLines: response.data.historyLines.map(i => APIInventoryItemHistoryDetailsToDomainMapper(i)),
                    totalItems: response.data.totalItems
                })
            })
            .catch((error) => {
                throw error;
            });
    }
}

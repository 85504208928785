import { InventoryItemStatus } from "./InventoryItemStatus";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { ItemStateOption } from "./ItemState";

export enum InventoryItemStatusEnum {
    UNKNOWN = 0,
    REGISTERED = 5,
    COUNTING = 1,
    READY_TO_BE_VALIDATED = 2,
    VALIDATED = 3,
    CLOSED = 4

}



export const InventoryItemStatusTranslationTerms: { [key: string | number]: string } = {
    0: "INVENTORY.ITEM.STATUS.Unknown",
    5: "INVENTORY.ITEM.STATUS.Registered",
    1: "INVENTORY.ITEM.STATUS.Counting",
    2: "INVENTORY.ITEM.STATUS.ReadyToBeValidated",
    3: "INVENTORY.ITEM.STATUS.Validated",
    4: "INVENTORY.ITEM.STATUS.Closed",
}



export const inventoryItemOptions: ItemStateOption[] = [
    { id: "1", description: translate("INVENTORY.ITEM.STATUS.Counting") },
    { id: "5", description: translate("INVENTORY.ITEM.STATUS.Registered") },
    { id: "2", description: translate("INVENTORY.ITEM.STATUS.ReadyToBeValidated") },
    { id: "3", description: translate("INVENTORY.ITEM.STATUS.Validated") },
    { id: "4", description: translate("INVENTORY.ITEM.STATUS.Closed") },
];



export const InventoryItemStatusAsEnum: { [key: number]: InventoryItemStatusEnum } = {
    0: InventoryItemStatusEnum.UNKNOWN,
    1: InventoryItemStatusEnum.COUNTING,
    2: InventoryItemStatusEnum.READY_TO_BE_VALIDATED,
    3: InventoryItemStatusEnum.VALIDATED,
    4: InventoryItemStatusEnum.CLOSED,
    5: InventoryItemStatusEnum.REGISTERED,
};

export function GetInvItemStatus() {

    const invItemStatusArray: InventoryItemStatus[] = Object.values(InventoryItemStatusEnum)
        .filter(value => typeof value === "number")
        .map((enumValue) => {
            var mappedSampleStatus: InventoryItemStatus = {
                id: "" + enumValue,
                description: translate(InventoryItemStatusTranslationTerms[enumValue]) || "-unknown-"
            };
            return mappedSampleStatus;
        });

    return invItemStatusArray;
}

import { useCallback, useState } from 'react';

import { Button } from "../../../../lib/components/buttons/Button";
import { ConditionalRender } from '../../../../lib/functional/ConditionalRender';
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { InventoryItemSummaryDTO } from "../../models/dtos/InventoryItemDTOs/InventoryItemSummaryDTO";
import { MobileInventoryService } from '../../services/MobileInventoryService';
import { Spacer } from '../../../../lib/components/separator/Spacer';
import { useFormControl } from "../../../../lib/components/form/Form";
import { useServiceCallPro2 } from '../../../../lib/hooks/useServiceCall';
import { validateEmptyStrings } from '../../../../lib/validators/ValidateEmptyStrings';

interface IPdaSimulatorStep01Props {

    locationId?: string;
    inventoryId?: string;
    storageCode?: string;
    item?: InventoryItemSummaryDTO;
    onCountSuccess: () => void;

}

const mobileSvc = new MobileInventoryService();


export function PdaSimulatorQuantityStep(props: IPdaSimulatorStep01Props) {

    const { locationId, inventoryId, storageCode, item, onCountSuccess } = props;

    const quantityFormControl = useFormControl<string>({
        validators: [validateEmptyStrings()],
    }); //TODO: validator required
    const rackFormControl = useFormControl<string>({});
    const shelfFormControl = useFormControl<string>({});
    const rackAndShelfFormControl = useFormControl<string>({});
    const commentFormControl = useFormControl<string>({});

    const [createInventoryItemCount, createInventoryItemCountIsLoading] = useServiceCallPro2(mobileSvc, mobileSvc.createInventoryItemCount)

    const [errorMessage, setErrorMessage] = useState<string>();

    const handleSubmit = useCallback(() => {

        if (!locationId || !inventoryId || !item || !quantityFormControl.value) return

        createInventoryItemCount(locationId, inventoryId, "" + item.id, {
            quantity: Number(quantityFormControl.value.replace(",", ".")),
            rackShelf: rackAndShelfFormControl.value || null,
            comment: commentFormControl.value || null,
        }).then(_ => {
            onCountSuccess();
        }).catch(err => alert(err))

    }, [quantityFormControl.value, rackAndShelfFormControl.value, commentFormControl.value, item, setErrorMessage, createInventoryItemCount, onCountSuccess]);


    return (
        <div>
            <FormFieldTextInput formControl={quantityFormControl} label={`Quantity (${item?.units})`} />
            <Spacer mode="vertical" px={16} />
            <FormFieldTextInput formControl={rackAndShelfFormControl} label="Rack/Shelf (Optional)" />
            <Spacer mode="vertical" px={16} />
            <FormFieldTextInput formControl={commentFormControl} label="Comment (Optional)" />

            <Spacer mode="vertical" px={30} />
            <Button text="Registar Contagem" type="secondary" onClick={handleSubmit} />
            <Spacer mode="vertical" px={16} />

            <div className="error-message">{errorMessage}</div>
        </div>
    )
}

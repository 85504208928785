import "./ImportLoader.css";

import { Loader } from "./Loader";

interface IImportLoaderProps {
    text?: string;
}

export function ImportLoader(props: IImportLoaderProps) {

    return (
        <div className="small-copy import-loading"><Loader /> {props.text}</div>
    );
}

import "./LocationsContainer.css";

import { useEffect, useMemo } from "react";

import { ReactComponent as GroupsAccessIcon } from "../../../lib/assets/icons/groups-access.svg";
import { LocationService } from "../../../services/LocationServices";
import { ReactComponent as MaterialsIcon } from "../../../lib/assets/icons/materials-dark.svg";
import { MobileSubSidebar } from "../../../lib/components/side-bar/MobileSubSidebar";
import { ReactComponent as PdaOperatorsIcon } from "../../../lib/assets/icons/pda-users.svg";
import { ReactComponent as StorageMangersIcon } from "../../../lib/assets/icons/storages-managers.svg";
import { ReactComponent as StoragesIcon } from "../../../lib/assets/icons/storages.svg";
import { SubSidebar } from "../../../lib/components/side-bar/SubSidebar";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useParams } from "react-router-dom";
import { useUpdatePageData } from '../../../lib/infrastructure/navigation/hooks/useUpdatePageData';
import { useWindowResize } from "../../../lib/infrastructure/ui/UIServices";

var locationService = new LocationService();
interface ILocationsContainerProps {
  children: React.ReactNode;
}

export function LocationsContainer(props: ILocationsContainerProps) {
  const windowResize = useWindowResize();

  const subSideBarButtons = useMemo(() => [
    {
      icon: <GroupsAccessIcon />,
      navigateTo: "groupaccess",
      name: translate("GLOBALADMIN.LOCATIONS.AccessGroups"),
    },
    {
      icon: <StoragesIcon />,
      navigateTo: "storages",
      name: translate("INVENTORY.StoragePlural")
    },
    {
      icon: <StorageMangersIcon />,
      navigateTo: "storage-managers",
      name: translate("GLOBALADMIN.STORAGEMANAGERS.StorageManagers"),
    },
    {
      icon: <MaterialsIcon />,
      navigateTo: "materials",
      name: translate("GLOBALADMIN.MATERIALS.Materials"),
    },
    {
      icon: <PdaOperatorsIcon />,
      navigateTo: "pda-operators",
      name: translate("GLOBALADMIN.OPERATORS.PdaOperators"),
    },

  ], []);


  const renderSubSideBar = useMemo(() => {
    return windowResize > 425 ? <SubSidebar buttons={subSideBarButtons} /> : <MobileSubSidebar buttons={subSideBarButtons} />;
  }, [subSideBarButtons, windowResize]);


  const { id } = useParams();
  const updateRouteData = useUpdatePageData();

  useEffect(() => {
    locationService
      .getLocationById(id || "")
      .then((response) => {
        updateRouteData(["##LOCATION##", response.name]);

      })
      .catch((error) => {
        //TODO: handle error.
      });
  }, [id]);

  return (
    <div className="locations-content-container">
      {renderSubSideBar}
      {props.children}
    </div>
  );
}

import "./EmptyState.css";

import { ReactComponent as NoResultsIcon } from "../../assets/icons/search-empty-icon.svg";
import React from "react";
import { Spacer } from "../separator/Spacer";
import { translate } from "../../infrastructure/i18n/InternationalizationService";

interface IEmptyStateProps {
  title?: string;
  subtitle?: string;
  hideSubtitle?: boolean;
  subtitleMessage?: string;
  children?: React.ReactNode;
}

export function EmptyState(props: IEmptyStateProps) {
  return (
    <div className="no-results">
      <NoResultsIcon />
      {props.hideSubtitle ? <Spacer mode="vertical" px="26" /> :
        <div className="subtitle no-results-title">
          {props.title ? props.title : translate("COMMON.TABLE.NoResults")}
        </div>}
      <div className="small-copy no-results-subtitle">
        {props.subtitle
          ? props.subtitle
          : (props.subtitleMessage ? props.subtitleMessage : translate("COMMON.TABLE.NoResultsText"))}
      </div>
      {props.children}
    </div>
  );
}

import * as qs from "qs";

import { APIUserToDomainMapper } from "../../../models/api/users/APIUserToDomainMapper";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { InventoryItemComment } from "../models/domain/inventory-items/InventoryItemComment";
import { InventoryItemCommentCreateDTO } from "../models/api/InventoryItemCommentCreateDTO";
import { InventoryItemCommentsResponseDTO } from "../models/api/InventoryItemCommentsResponseDTO";
import { InventoryItemCountCommentCreateDTO } from "../models/dtos/InventoryItemCountDTOs/InventoryItemCountCommentCreateDTO";
import { InventoryItemCountCommentsResponseDTO } from "../models/dtos/InventoryItemCountDTOs/InventoryItemCountCommentsResponseDTO";
import { InventoryItemCreateDTO } from "../models/dtos/InventoryItemDTOs/InventoryItemCreateDTO";
import { InventoryItemDetails } from "../models/domain/inventory-items/InventoryItemDetails";
import { InventoryItemDetailsDTO } from "../models/dtos/InventoryItemDTOs/InventoryItemDetailsDTO";
import { InventoryItemDetailsFromXLSXResponse } from "../models/dtos/InventoryItemDTOs/InventoryItemImportFromXLSXResponse";
import { InventoryItemSiblingsResponseDTO } from "../models/dtos/InventoryItemDTOs/InventoryItemSiblingsResponseDTO";
import { InventoryItemState } from "../models/dtos/InventoryItemDTOs/InventoryItemState";
import { InventoryItemStateUpdateDTO } from "../models/dtos/InventoryItemDTOs/InventoryItemStateUpdateDTO";
import { InventoryItemSummary } from "../models/domain/inventory-items/InventoryItemSummary";
import { InventoryItemTotalItems } from "../models/domain/inventory-items/InventoryItemTotalItems";
import { InventoryItemUpdateDTO } from "../models/dtos/InventoryItemDTOs/InventoryItemUpdateDTO";
import { InventoryItemsQueryOptions } from "../models/dtos/InventoryItemDTOs/InventoryItemsQueryOptions";
import { InventoryItemsResponse } from "../models/domain/responses/InventoryItemsResponse";
import { InventoryItemsResponseDTO } from "../models/dtos/InventoryItemDTOs/InventoryItemsResponseDTO";
import { InventoryStateUpdateDTO } from "../models/dtos/InventoryDTOs/InventoryStateUpdateDTO";
import { MoveItemsQueryOptions } from "../models/dtos/InventoryStorageLocationDTOs/MoveItemsQueryOptions";
import { QueryOptions } from "../../../models/domain/materials/QueryOptions";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class InventoryItemsService {


    getInventoryItems(locationId: string, inventoryID: string, abortSignal: AbortSignal | undefined, options?: InventoryItemsQueryOptions): Promise<InventoryItemsResponse> {

        return HttpClient.sessionRequest<InventoryItemsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryID}/Items`),
            params: options,
            signal: abortSignal,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
              },
        })
            .then((response) => {

                if (response) {
                    var mappedItems: InventoryItemSummary[] = response.data.items.map(ob => ({
                        id: ob.id,
                        materialNumber: ob.materialNumber,
                        materialDescription: ob.materialDescription,
                        batch: ob.batch,
                        storage: ob.storage,
                        storageName: ob.storageName ? ob.storageName : null,
                        units: ob.units,
                        quantitySAP: ob.quantitySAP,
                        quantityCounted: ob.quantityCounted,
                        status: ob.status,
                        isNew: ob.isNew,
                        bin: ob.bin,
                        isProblematic: ob.isProblematic,
                        monetaryDeviationValue: ob.monetaryDeviationValue,
                        monetarySAP: ob.monetarySAP,
                        quantityDeviationPercentage: ob.quantityDeviationPercentage,
                        specialSAP: ob.specialSAP,
                        typeSAP: ob.typeSAP,
                        permissions: ob.permissions,
                        hasComments: ob.hasComments,
                    }));

                    return {
                        items: mappedItems,
                        totalItems: response.data.totalItems
                    }
                }

                return {
                    items: [],
                    totalItems: 0
                }
            })
            .catch((error) => { throw error; });
    }


    getInventoryItemById(locationId: string, inventoryID: string, itemId: string): Promise<InventoryItemDetails> {
        return HttpClient.sessionRequest<InventoryItemDetailsDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryID}/Items/${itemId}`)
        })
            .then((r) => {

                var mappedItem: InventoryItemDetails = {
                    id: r.data.id,
                    sapInventoryDocId: r.data.sapInventoryDocId,
                    materialNumber: r.data.materialNumber,
                    materialDescription: r.data.materialDescription,
                    batch: r.data.batch,
                    storage: r.data.storage,
                    storageName: r.data.storageName ?? null,
                    units: r.data.units,
                    quantitySAP: r.data.quantitySAP,
                    monetarySAP: r.data.monetarySAP,
                    typeSAP: r.data.typeSAP,
                    specialSAP: r.data.specialSAP,
                    state: r.data.state,
                    bin: r.data.bin,
                    isNew: r.data.isNew,

                    createdBy: APIUserToDomainMapper(r.data.createdBy),
                    createdDate: r.data.createdDate,
                    modifiedBy: APIUserToDomainMapper(r.data.modifiedBy),
                    modifiedDate: r.data.modifiedDate,


                    quantityCounted: r.data.quantityCounted,
                    monetaryValueCounted: r.data.monetaryValueCounted,
                    quantityDeviationValue: r.data.quantityDeviationValue,

                    quantityDeviationPercentage: r.data.quantityDeviationPercentage,
                    monetaryDeviationValue: r.data.monetaryDeviationValue,
                    monetaryDeviationPercentage: r.data.monetaryDeviationPercentage,

                    isProblematic: r.data.isProblematic,

                    unitPrice: r.data.unitPrice,

                    permissions: r.data.permissions
                };

                if (r.data.lastComment) {
                    mappedItem.lastComment = {
                        id: r.data.lastComment.id,
                        comment: r.data.lastComment.comment,
                        createdBy: r.data.lastComment.createdBy,
                        createdDate: r.data.lastComment.createdDate
                    }
                }

                return mappedItem;
            })
            .catch((error) => { throw error; });
    }


    getInventoryItemSiblings(locationId: string, inventoryID: string, itemId: string, opts?: MoveItemsQueryOptions): Promise<InventoryItemTotalItems> {
        return HttpClient.sessionRequest<InventoryItemSiblingsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryID}/Items/${itemId}/Siblings`),
            params: opts,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {

                if (response) {
                    var mappedItems: InventoryItemDetails[] = response.data.siblingItems.map(r => ({
                        id: r.id,
                        storage: r.storage,
                        sapInventoryDocId: r.sapInventoryDocId,
                        materialNumber: r.materialNumber,
                        materialDescription: r.materialDescription,
                        batch: r.storage,
                        storageName: r.storageName ?? null,
                        units: r.units,
                        quantitySAP: r.quantitySAP,
                        monetarySAP: r.monetarySAP,
                        typeSAP: r.typeSAP,
                        specialSAP: r.specialSAP,
                        state: r.state,
                        bin: r.bin,
                        isNew: r.isNew,

                        createdBy: APIUserToDomainMapper(r.createdBy),
                        createdDate: r.createdDate,
                        modifiedBy: APIUserToDomainMapper(r.modifiedBy),
                        modifiedDate: r.modifiedDate,

                        quantityCounted: r.quantityCounted,
                        monetaryValueCounted: r.monetaryValueCounted,
                        quantityDeviationValue: r.quantityDeviationValue,

                        unitPrice: r.unitPrice,

                        quantityDeviationPercentage: r.quantityDeviationPercentage,
                        monetaryDeviationValue: r.monetaryDeviationValue,
                        monetaryDeviationPercentage: r.monetaryDeviationPercentage,
                        isProblematic: r.isProblematic
                    }));

                    return ({
                        items: mappedItems,
                        totalItems: response.data.totalItems
                    })
                }
                return ({
                    items: [],
                    totalItems: 0
                });


            })
            .catch((error) => { throw error; });
    }



    addInventoryItem(locationId: string, inventoryID: string, requestDto: InventoryItemCreateDTO): Promise<InventoryItemDetailsDTO> {

        return HttpClient.sessionRequest<InventoryItemDetailsDTO>({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryID}/Items`),
            data: requestDto
        })
            .then((res) => { return res.data })
            .catch((error) => {
                throw error;
            });
    }


    uploadInventoryItemsFromXLSX(locationId: string, inventoryId: string, file: File): Promise<InventoryItemDetailsFromXLSXResponse> {

        var formData = new FormData();
        formData.append("file", file)

        return HttpClient.sessionRequest<InventoryItemDetailsFromXLSXResponse>({
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
            },
            url: Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryId}/Items/FromXLSX`),
            data: formData
        })
            .then((res) => {
                return res.data
            })
            .catch((error) => {
                throw error;
            });
    }






    deleteInventoryItem(locationId: string, inventoryId: string, inventoryItemId: string): Promise<void> {
        return HttpClient.sessionRequest({
            method: "DELETE",
            url: Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryId}/Items/${inventoryItemId}`),
        })
            .then((_) => { })
            .catch((error) => { throw error; });
    }

    updateInventoryItem(locationId: string, inventoryId: string, inventoryItemId: string, requestDto: InventoryItemUpdateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryId}/Items/${inventoryItemId}`),
            data: requestDto
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    updateInventoryItemState(locationId: string, inventoryId: string, inventoryItemId: string, requestDto: InventoryItemStateUpdateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryId}/Items/${inventoryItemId}/State`),
            data: requestDto
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    reopenInventoryItem(locationId: string, inventoryId: string, inventoryItemId: string) {
        return this.updateInventoryItemState(locationId, inventoryId, inventoryItemId, { state: InventoryItemState.COUNTING })
    }

    closeInventoryItem(locationId: string, inventoryId: string, inventoryItemId: string) {
        return this.updateInventoryItemState(locationId, inventoryId, inventoryItemId, { state: InventoryItemState.CLOSED })
    }


    addInventoryItemComment(locationID: string, inventoryID: string, itemID: string, requestDto: InventoryItemCommentCreateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationID}/Inventories/${inventoryID}/Items/${itemID}/Comments`),
            data: requestDto
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }


    getInventoryItemComments(locationId: string, inventoryId: string, inventoryItemId: string,): Promise<InventoryItemComment[]> {
        return HttpClient.sessionRequest<InventoryItemCommentsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryId}/Items/${inventoryItemId}/Comments`),
        })
            .then((response) => {

                var mappedComments: InventoryItemComment[] = response.data.comments.map(ob => ({
                    id: ob.id,
                    createdBy: ob.createdBy,
                    createdDate: ob.createdDate,
                    comment: ob.comment
                }));

                return mappedComments;

            })
            .catch((error) => {
                throw error;
            });
    }
}

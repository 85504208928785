import "./MessageBar.css";

import { CssClassnameBuilder } from "../../utils/CssClassnameBuilder";
import { ReactComponent as ErrorIcon } from "../../../lib/assets/icons/error-icon-v2.svg";
import { ReactComponent as SuccessIcon } from "../../../lib/assets/icons/success-icon-v2.svg";
import { ReactComponent as WarningIcon } from "../../../lib/assets/icons/warning-icon-v2.svg";
import { useMemo } from "react";

interface IMessageBarProps {
    text: string;
    type?: "success" | "error" | "warning";
    icon?: React.ReactNode;
}

export function MessageBar(props: IMessageBarProps) {

    const messageBarCss = useMemo(() => {
        return CssClassnameBuilder.new()
            .add("message-bar")
            .addConditional(props.type, props.type)
            .build();
    }, [props.type]);

    const renderIcon = useMemo(() => {
        switch (props.type) {
            case "success":
                return <div className="message-bar-icon"><SuccessIcon /></div>;
            case "error":
                return <div className="message-bar-icon"><ErrorIcon /></div>;
            case "warning":
                return <div className="message-bar-icon"><WarningIcon /></div>;
            default:
                return props.icon ? <div className="message-bar-icon">{props.icon}</div> : null;
        }
    }, [props.type, props.icon]);

    return <div className={messageBarCss}>
        {renderIcon}
        <div className="small-copy message-bar-text">{props.text}</div>
    </div>;

}
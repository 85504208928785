import "./InventoryItemCountsPage.css";

import { InventoryItemStatusEnum, InventoryItemStatusTranslationTerms } from '../../models/domain/inventory-items/InventoryItemStatusEnum';
import { formatNonIntegerNumber, formatRoundNumber } from "../../../../common/helpers/RoundNumber";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";

import { AddInvItemCommentPopup } from "./popups/AddInvItemCommentPopup";
import { AddInventoryItemCountCommentPopup } from "./popups/AddInventoryItemCountCommentPopup";
import { Button } from "../../../../lib/components/buttons/Button";
import { CardContainer } from "../../../../lib/layouts/containers/card/CardContainer";
import { ReactComponent as CloseIcon } from "../../../../lib/assets/icons/lock-btn.svg";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { ReactComponent as CommentsOutlineIcon } from "../../../../lib/assets/icons/comment.svg";
import { ConditionalRender } from '../../../../lib/functional/ConditionalRender';
import { ConsultHistoryLinesPopup } from "./popups/ConsultHistoryLinesPopup";
import { ContextualMenu } from "../../../../lib/components/contextual-menu/ContextualMenu";
import { ReactComponent as EditIcon } from "../../../../lib/assets/icons/editar.svg";
import { EditInventoryDocItemQuantityPopup } from "./popups/EditInventoryDocItemQuantityPopup";
import { EditInventoryDocumentItemIDPopup } from "./popups/EditInventoryDocumentItemIDPopup";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FormSection } from "../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { ReactComponent as HistoryIcon } from "../../../../lib/assets/icons/history.svg";
import { IconButton } from "../../../../lib/components/buttons/IconButton";
import { InfoBlock } from "../../../../lib/components/info-block/InfoBlock";
import { InfoBlockComments } from "./components/InfoBlockComments";
import { InventoryItemCountSummary } from "../../models/domain/inventory-item-counts/InventoryItemCountSummary";
import { InventoryItemCountsService } from "../../services/InventoryItemCountsService";
import { InventoryItemDetails } from "../../models/domain/inventory-items/InventoryItemDetails";
import { InventoryItemState } from "../../models/dtos/InventoryItemDTOs/InventoryItemState";
import { InventoryItemsService } from "../../services/InventoryItemsService";
import { ReactComponent as MoreActionsIcon } from "../../../../lib/assets/icons/more-actions.svg";
import { ReactComponent as MoveIcon } from "../../../../lib/assets/icons/drag.svg";
import { MoveItemCountPopup } from "./popups/MoveItemCountPopup";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { Permission } from "../../../../models/api/gate/Permission";
import { QueryOptions } from "../../../../models/domain/materials/QueryOptions";
import { ReactComponent as RemoveIcon } from "../../../../lib/assets/icons/remover-outline.svg";
import { ReactComponent as ReopenIcon } from "../../../../lib/assets/icons/reopen-inventory.svg";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../../lib/components/buttons/ScalableIconButton";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { Tag } from "../../../../lib/components/tag/Tag";
import { ReactComponent as ValidateIcon } from "../../../../lib/assets/icons/check-btn.svg";
import { ValidateInvDocItemCountPopup } from "./popups/ValidateInvDocItemCountPopup";
import { ReactComponent as WarningIcon } from "../../../../lib/assets/icons/warning-page-icon.svg";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import { getInventoryItemStatusCss } from "../../../../common/helpers/GetInventoryItemStatusCss";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useInventoryStatus } from "../../../../lib/infrastructure/inventory-status/useInventoryStatus";
import { useParams } from "react-router-dom";
import { useServiceCallPro2 } from '../../../../lib/hooks/useServiceCall';
import { useUpdatePageData } from "../../../../lib/infrastructure/navigation/hooks/useUpdatePageData";

var inventoryItemCountsSvc = new InventoryItemCountsService();
var inventoryItemsSvc = new InventoryItemsService();



export const topPermissions = [
    Permission.GLOBAL_ADMIN,
    Permission.LOCAL_ADMIN,
    Permission.FINANCES
];


export function InventoryItemCountsPage() {
    const updateRouteData = useUpdatePageData();
    const createNotification = useCreateNotification();
    const locationID = useGlobalLocation();
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const windowResize = useWindowResize();
    const inventoryStatus = useInventoryStatus();

    const { id: inventoryID, inventoryItemID: itemID } = useParams();

    const [inventoryItemCounts, setInventoryItemCounts] = useState<InventoryItemCountSummary[]>();
    const [inventoryItemSiblings, setInventoryItemSiblings] = useState<InventoryItemDetails[]>();

    const [totalCounts, setTotalCounts] = useState<number>(0);
    const [inventoryItem, setInventoryItem] = useState<InventoryItemDetails>();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [tableQueryOptions, setTableQueryOptions] = useState<QueryOptions>({ page: 0, pageLength: 10 });

    const { isLoadingPermissions, hasPermission: hasAdminPermission } = useHasPermissions(topPermissions);


    const [validateItem, validateItemIsLoading] = useServiceCallPro2(inventoryItemsSvc, inventoryItemsSvc.updateInventoryItemState);
    const [reopenItem, reopenItemIsLoading] = useServiceCallPro2(inventoryItemsSvc, inventoryItemsSvc.reopenInventoryItem);
    const [closeItem, closeItemIsLoading] = useServiceCallPro2(inventoryItemsSvc, inventoryItemsSvc.closeInventoryItem);
    const [removeItemCount, removeCountIsLoading] = useServiceCallPro2(inventoryItemCountsSvc, inventoryItemCountsSvc.deleteInventoryItemCount);
    const [getItemSiblingsCall, getItemSiblingsCallIsLoading] = useServiceCallPro2(inventoryItemsSvc, inventoryItemsSvc.getInventoryItemSiblings)
    const [getItemCall, getItemIsLoading] = useServiceCallPro2(inventoryItemsSvc, inventoryItemsSvc.getInventoryItemById)



    useEffect(() => {

        getInventoryItemCounts();
        getInventoryItemById();
        getInventoryItemSiblings();

    }, []);


    useEffect(() => {
        getInventoryItemCounts();
    }, [tableQueryOptions]);



    const getInventoryItemById = useCallback(() => {

        if (!locationID || !inventoryID || !itemID) return;
        setIsLoading(true);

        getItemCall(`${locationID}`, inventoryID, itemID)
            .then((data) => {
                updateRouteData(["##STORAGE##", data.storageName ? data.storage + " - " + data.storageName : data.storage ?? "-"],
                    ["##INVENTORYITEM##", data.materialNumber + " - <" + data.materialDescription + ">" ?? data.materialDescription]);
                setInventoryItem(data);
                setIsLoading(false);
            }).catch((error) => {

                setIsLoading(false);
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationID, inventoryID, itemID, setInventoryItem, getItemCall, setIsLoading, openPopup, updateRouteData]);


    const getInventoryItemSiblings = useCallback(() => {

        if (!locationID || !inventoryID || !itemID) return;

        getItemSiblingsCall(`${locationID}`, inventoryID, itemID)
            .then((data) => {
                setInventoryItemSiblings(data.items);
            }).catch((error) => {

                setIsLoading(false);
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationID, inventoryID, itemID, getItemSiblingsCall, setInventoryItemSiblings, openPopup]);




    const getInventoryItemCounts = useCallback(() => {

        if (!locationID || !inventoryID || !itemID) return;
        setIsLoading(true);

        inventoryItemCountsSvc.getInventoryItemCounts(`${locationID}`, inventoryID, itemID, tableQueryOptions)
            .then((data) => {
                setInventoryItemCounts(data.items);
                setTotalCounts(data.totalItems);
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationID, inventoryID, itemID, setInventoryItemCounts, setIsLoading, openPopup, setTotalCounts, tableQueryOptions]);



    const handleOnValidateItemClicked = useCallback(() => {
        if (!locationID || !inventoryID || !itemID) return;

        validateItem(`${locationID}`, inventoryID, itemID, { state: InventoryItemState.VALIDATED }).then(_ => {
            getInventoryItemCounts();
            getInventoryItemById();
            createNotification(
                <Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("INVENTORY.ITEM.MESSAGES.ValidateItemSuccessMessage")}
                ></Notification>
            );
        }).catch((error) => {
            setIsLoading(false);
            if (!error) return;
            openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }, [locationID, inventoryID, itemID, validateItem, getInventoryItemCounts, getInventoryItemById, createNotification, openPopup]);



    const handleOnReopenItemClicked = useCallback(() => {
        if (!locationID || !inventoryID || !itemID) return;

        reopenItem(`${locationID}`, inventoryID, itemID).then(_ => {
            getInventoryItemCounts();
            getInventoryItemById();
            createNotification(
                <Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("INVENTORY.ITEM.MESSAGES.ReopenItemSuccessMessage")}
                ></Notification>
            );
        }).catch((error) => {
            setIsLoading(false);
            if (!error) return;
            openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }, [locationID, inventoryID, itemID, reopenItem, getInventoryItemCounts, getInventoryItemById, createNotification, openPopup]);


    const handleOnCloseItemClicked = useCallback(() => {
        if (!locationID || !inventoryID || !itemID) return;

        closeItem(`${locationID}`, inventoryID, itemID).then(_ => {
            getInventoryItemCounts();
            getInventoryItemById();
            createNotification(
                <Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("INVENTORY.ITEM.MESSAGES.CloseItemSuccessMessage")}
                ></Notification>
            );
        }).catch((error) => {
            setIsLoading(false);
            if (!error) return;
            openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }, [locationID, inventoryID, itemID, closeItem, getInventoryItemCounts, getInventoryItemById, createNotification, openPopup]);


    const handleRemoveItemCountClicked = useCallback((itemCountId: number) => {
        if (!locationID || !inventoryID || !itemID || !itemCountId) return;

        removeItemCount(`${locationID}`, inventoryID, itemID, `${itemCountId}`)
            .then((_) => {
                getInventoryItemCounts();
                getInventoryItemById();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("INVENTORY.ITEM.MESSAGES.RemoveItemCountSuccessMessage")}
                    ></Notification>
                );
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationID, inventoryID, itemID, getInventoryItemCounts, getInventoryItemById, openPopup, removeItemCount, createNotification]);




    const isInventoryInProgress = useMemo(() => {
        return !inventoryStatus.isLoadingInventoryStatus && !inventoryStatus.isInventoryClosed;
    }, [inventoryStatus.isLoadingInventoryStatus, inventoryStatus.isInventoryClosed])


    const canMoveItemCount = useMemo(() => {
        return (!getItemSiblingsCallIsLoading && inventoryItemSiblings && inventoryItemSiblings?.length > 0)
            && isInventoryInProgress
            && (inventoryItem?.state === InventoryItemStatusEnum.COUNTING || inventoryItem?.state === InventoryItemStatusEnum.READY_TO_BE_VALIDATED)
            && ((!isLoadingPermissions && hasAdminPermission) || inventoryItem.permissions?.canManageItem);
    }, [inventoryItemSiblings, getItemSiblingsCallIsLoading, isInventoryInProgress,
        inventoryItem?.state, isLoadingPermissions, hasAdminPermission, inventoryItem?.permissions?.canManageItem])



    const canAddNewComments = useMemo(() => {
        if (!inventoryItem || isLoadingPermissions || inventoryStatus.isInventoryClosed)
            return false;


        if (inventoryItem.state === InventoryItemStatusEnum.CLOSED)
            return false;


        if (hasAdminPermission || inventoryItem.permissions?.canManageItem)
            return true;


        return false;

    }, [inventoryItem, isLoadingPermissions, inventoryStatus, hasAdminPermission]);




    const columns: ColumnDefinition<InventoryItemCountSummary>[] = useMemo(() => {



        const itemCountMeasures: ColumnDefinition<InventoryItemCountSummary> = {
            cellRenderProp: (v) => v.length && v.width ? v.length+ "m x " + v.width + "m" : null,
            headerRender: <>{translate("INVENTORY.ITEM.INFOANDFORM.Measures") + " ("+ translate("INVENTORY.ITEM.INFOANDFORM.LengthShort") + " x " + translate("INVENTORY.ITEM.INFOANDFORM.WidthShort") + ")"} </>,
            width: "20%"
        };

        const cols: ColumnDefinition<InventoryItemCountSummary>[] = [
            {
                cellRenderProp: (v: InventoryItemCountSummary) => <>{formatNonIntegerNumber(v.quantity, 3)}<ConditionalRender if={!!inventoryItem?.units}>&nbsp;{inventoryItem?.units}</ConditionalRender></>,
                headerRender: translate("INVENTORY.ITEM.INFOANDFORM.Quantity"),
                isMobilePrimaryCell: true
            },
            ...(inventoryItem?.units.toLowerCase() === "m2" ? [itemCountMeasures] : []),
            {
                cellRenderProp: (v: InventoryItemCountSummary) => v.rackShelf,
                headerRender: translate("INVENTORY.ITEM.INFOANDFORM.Rack") + "/" + translate("INVENTORY.ITEM.INFOANDFORM.Shelf"),
            },
            {
                cellRenderProp: (v: InventoryItemCountSummary) => v.operator === null ? "" : v.operator,
                headerRender: translate("INVENTORY.ITEM.INFOANDFORM.Operator"),
            },
            {
                cellRenderProp: (v: InventoryItemCountSummary) => {
                    if (v.comments && v.comments.length > 0)
                        return <InfoBlockComments
                            label={""}
                            value={v.comments.length > 0 ? "" + v.comments.at(-1)?.comment : ""}
                            onClick={() => {
                                if (!inventoryItem?.id || !locationID || !inventoryID) return;
                                openPopup(<AddInventoryItemCountCommentPopup
                                    canAddComment={canAddNewComments}
                                    inventoryItemCountID={"" + v.id}
                                    locationID={"" + locationID}
                                    inventoryID={"" + inventoryID}
                                    inventoryItemID={"" + inventoryItem.id}
                                    onCompletedOperations={getInventoryItemCounts} />)
                            }}
                        />
                },
                headerRender: translate("INVENTORY.ITEM.INFOANDFORM.LastComment"),
            },
            {
                cellRenderProp: (v) => {
                    if (inventoryItem?.state === InventoryItemStatusEnum.CLOSED || inventoryItem?.state === InventoryItemStatusEnum.VALIDATED || getItemIsLoading) return;
                    if (!hasAdminPermission && !inventoryItem?.permissions?.canManageItem) return;

                    return (<ContextualMenu
                        items={[
                            {
                                label: translate("INVENTORY.ITEM.POPUP.EditQuantity"),
                                icon: <EditIcon />,
                                isVisible: isInventoryInProgress,
                                navigateTo: "",
                                onClick: () => {
                                    if (!inventoryItem?.id || !locationID || !inventoryID) return;
                                    openPopup(
                                        <EditInventoryDocItemQuantityPopup
                                            itemUnit={inventoryItem.units}
                                            locationID={"" + locationID}
                                            inventoryID={"" + inventoryID}
                                            inventoryItemID={"" + inventoryItem.id}
                                            inventoryItemCountId={"" + v.id}
                                            onCompletedOperations={() => { getInventoryItemCounts(); getInventoryItemById(); }}
                                        />
                                    );
                                }
                            },
                            {
                                label: translate("INVENTORY.ITEMCOUNTS.MoveCount"),
                                icon: <MoveIcon />,
                                navigateTo: "",
                                isVisible: canMoveItemCount,
                                onClick: () => {
                                    if (!inventoryItem?.id || !locationID || !inventoryID || !v.id) return;
                                    openPopup(<MoveItemCountPopup
                                        locationID={"" + locationID}
                                        inventoryID={"" + inventoryID}
                                        itemID={"" + itemID}
                                        itemCountID={"" + v.id}
                                        onCompletedOperations={() => { getInventoryItemCounts(); getInventoryItemById(); }} />)
                                }
                            },
                            {
                                label: translate("INVENTORY.ITEM.AddComment"),
                                icon: <CommentsOutlineIcon />,
                                isVisible: isInventoryInProgress && (hasAdminPermission || inventoryItem?.permissions?.canManageItem),
                                navigateTo: "",
                                onClick: () => {
                                    if (!locationID || !inventoryID || !inventoryItem?.id || !v.id) return;
                                    openPopup(
                                        <AddInventoryItemCountCommentPopup
                                            canAddComment={canAddNewComments}
                                            locationID={"" + locationID}
                                            inventoryID={"" + inventoryID}
                                            inventoryItemID={"" + inventoryItem.id}
                                            inventoryItemCountID={"" + v.id}
                                            onCompletedOperations={() => { getInventoryItemCounts(); getInventoryItemById() }}
                                        />
                                    );
                                }
                            },
                            {
                                label: translate("INVENTORY.ITEM.Remove"),
                                icon: <RemoveIcon />,
                                isVisible: isInventoryInProgress && (inventoryItem?.permissions?.canManageItem),
                                navigateTo: "",
                                onClick: () => {
                                    openPopup(
                                        <WarningPopup
                                            className="button-red"
                                            onDismissClicked={() => { closePopup(); }}
                                            onConfirmClicked={() => { handleRemoveItemCountClicked(v.id) }}
                                            dismissText={translate("COMMON.Cancel")}
                                            confirmText={translate("COMMON.Remove")}
                                        >
                                            {translate("INVENTORY.ITEM.MESSAGES.RemoveItemCountConfirmText")}
                                        </WarningPopup>
                                    );
                                }
                            }
                        ]}
                    >
                        <ScalableIconButton size={24} icon={<MoreActionsIcon />} />
                    </ContextualMenu>)
                },
                columnKey: "moreActions",
                width: "24px",
                isMobileHeaderIcon: true
            },
        ];
        return cols;
    }, [inventoryItem, getInventoryItemCounts, getInventoryItemById,
        inventoryID, locationID, inventoryItemSiblings, inventoryStatus.isInventoryClosed,
        inventoryStatus.isLoadingInventoryStatus, hasAdminPermission, canAddNewComments,
        handleRemoveItemCountClicked, openPopup, closePopup,
        inventoryItem?.units,
        getItemSiblingsCallIsLoading, itemID, inventoryItem?.state, isInventoryInProgress, canMoveItemCount, getItemIsLoading]);




    const renderItemDetailsHeader = useMemo(() => {
        return (
            <div className="item-details-header">
                <div className="title">{translate("INVENTORY.MaterialData")}</div>
                <div className="item-header-tags">
                    {inventoryItem?.isProblematic && inventoryItem.state !== InventoryItemStatusEnum.CLOSED ?
                        <Tag
                            icon={<WarningIcon />}
                            className="pending-item-status"
                            text={translate("INVENTORY.ITEM.INFOANDFORM.Problematic")}
                            backgroundColor={"status-red"}
                            isTiny={windowResize < 1024}
                        /> : null}
                    {inventoryItem?.state ?
                        <Tag
                            text={inventoryItem?.state ? translate(InventoryItemStatusTranslationTerms[inventoryItem?.state]) : ""}
                            backgroundColor={inventoryItem?.state ? getInventoryItemStatusCss(inventoryItem?.state) : undefined}
                            isTiny={windowResize < 1024}
                        /> : null}
                </div>
            </div>);
    }, [inventoryItem, windowResize]);







    const renderCardContainerInfo = useMemo(() => {
        return <FormSection unsetFlexGrow>
            <InfoBlock
                label={"Doc. ID"}
                value={inventoryItem?.sapInventoryDocId ? inventoryItem?.sapInventoryDocId : "-"}
            />
            <InfoBlock
                label={translate("INVENTORY.ITEM.INFOANDFORM.Material")}
                value={inventoryItem?.materialNumber ? inventoryItem?.materialNumber : "-"}
            />
            <InfoBlock
                label={translate("INVENTORY.ITEM.INFOANDFORM.Description")}
                value={inventoryItem?.materialDescription ? inventoryItem.materialDescription : "-"}
            />
            <InfoBlock
                label={translate("INVENTORY.ITEM.INFOANDFORM.Batch")}
                value={inventoryItem?.batch ? inventoryItem.batch : "-"}
            />
            <InfoBlock label="Special"
                value={inventoryItem?.specialSAP ? inventoryItem?.specialSAP : "-"}
            />
            <InfoBlock label={translate("INVENTORY.ITEM.INFOANDFORM.TypeSAP")}
                value={inventoryItem?.typeSAP ? "" + inventoryItem.typeSAP : "-"}
            />
            <InfoBlock
                label={translate("INVENTORY.ITEM.INFOANDFORM.Storage")}
                value={inventoryItem?.storage ? inventoryItem.storage + (inventoryItem.storageName ? " - " + inventoryItem.storageName : "")
                    + (inventoryItem.bin ? " <" + inventoryItem.bin + ">" : "") : "-"}
            />
            <InfoBlock
                label={translate("INVENTORY.ITEM.INFOANDFORM.CountedQuantity")}
                value={inventoryItem?.quantityCounted ? (formatNonIntegerNumber(inventoryItem?.quantityCounted, 3) ?? "-") + ` ${inventoryItem?.units ?? ""}` : "0"}
            />
            <InfoBlock
                label={translate("INVENTORY.ITEM.INFOANDFORM.SAPQuantity")}
                value={(formatNonIntegerNumber(inventoryItem?.quantitySAP, 3) ?? "-") + ` ${inventoryItem?.units ?? ""}`}
            />


            <InfoBlock
                label={translate("INVENTORY.ITEM.INFOANDFORM.UnitPriceMaterialShort")}
                value={inventoryItem?.unitPrice ? (formatRoundNumber(inventoryItem?.unitPrice, 2) ?? "-") + " €" : "-"}
            />



            <InfoBlock
                label={translate("INVENTORY.ITEM.INFOANDFORM.MonetaryValue")}
                value={inventoryItem?.monetaryValueCounted ? (formatRoundNumber(inventoryItem?.monetaryValueCounted, 2) ?? "-") + " €" : "-"}

            />
            <InfoBlock
                label={translate("INVENTORY.ITEM.INFOANDFORM.SapValue")}
                value={inventoryItem?.monetarySAP ? (formatRoundNumber(inventoryItem?.monetarySAP, 2) ?? "-") + " €" : "-"}
            />

            <InfoBlock
                backgroundColor={inventoryItem?.isProblematic ? "status-red" : undefined}
                label={translate("INVENTORY.ITEM.INFOANDFORM.QuantityDeviationPercentage")}
                value={formatNonIntegerNumber(inventoryItem?.quantityDeviationPercentage, 3) + " %" ?? "-"}
            />
            <InfoBlock
                backgroundColor={inventoryItem?.isProblematic ? "status-red" : undefined}
                label={translate("INVENTORY.ITEM.INFOANDFORM.MonetaryDeviation")}
                value={formatRoundNumber(inventoryItem?.monetaryDeviationValue, 2) + " €" ?? "-"}
            />

            {inventoryItem?.lastComment ?
                <InfoBlockComments
                    label={translate("INVENTORY.ITEM.INFOANDFORM.LastComment")}
                    value={inventoryItem?.lastComment ? inventoryItem.lastComment.comment : ""}
                    onClick={() => {
                        if (!inventoryItem?.id || !locationID || !inventoryID) return;
                        openPopup(<AddInvItemCommentPopup
                            canAddComment={canAddNewComments}
                            locationID={"" + locationID} inventoryID={"" + inventoryID} inventoryItemID={"" + inventoryItem.id} onCompletedOperations={getInventoryItemById} />)
                    }}
                />
                : null}
        </FormSection>
    }, [inventoryItem, getInventoryItemById, inventoryID, locationID, openPopup, canAddNewComments]);


    const canClose = useMemo(() => {
        return (isInventoryInProgress && inventoryItem?.permissions?.canClose) ?? false;
    }, [isInventoryInProgress, inventoryItem?.permissions]);


    const canValidate = useMemo(() => {
        return (isInventoryInProgress && inventoryItem?.permissions?.canValidate) ?? false;
    }, [isInventoryInProgress, inventoryItem?.permissions]);


    const canReopen = useMemo(() => {
        return (inventoryItem?.permissions?.canReopenCount && isInventoryInProgress) ?? false;
    }, [inventoryItem?.permissions, isInventoryInProgress]);


    const showFullScreenLoader = useMemo(() => {

        return validateItemIsLoading || reopenItemIsLoading || closeItemIsLoading || removeCountIsLoading;

    }, [validateItemIsLoading, reopenItemIsLoading, closeItemIsLoading, removeCountIsLoading]);




    const onPageAndItemsChanged = useCallback((rowsPerPage: number, newPage: number) => {
        setTableQueryOptions({
            page: newPage,
            pageLength: rowsPerPage
        });
    }, [setTableQueryOptions]);




    const handleClickEditDocSapId = useCallback(() => {
        if (!inventoryItem?.id || !locationID || !inventoryID) return;
        openPopup(
            <EditInventoryDocumentItemIDPopup
                locationID={"" + locationID}
                inventoryID={"" + inventoryID}
                inventoryItemID={"" + inventoryItem.id}
                onCompletedOperations={getInventoryItemById}
            />
        );
    }, [getInventoryItemById, inventoryID, inventoryItem?.id, locationID, openPopup])



    const handleClickAddComment = useCallback(() => {

        if (!inventoryItem?.id || !locationID || !inventoryID) return;

        openPopup(<AddInvItemCommentPopup canAddComment={canAddNewComments} locationID={"" + locationID} inventoryID={"" + inventoryID} inventoryItemID={"" + inventoryItem.id} onCompletedOperations={getInventoryItemById} />);

    }, [getInventoryItemById, inventoryID, inventoryItem?.id, locationID, openPopup, canAddNewComments]);




    const handleClickReopenCounts = useCallback(() => {
        openPopup(
            <WarningPopup
                onDismissClicked={() => { closePopup(); }}
                onConfirmClicked={handleOnReopenItemClicked}
                dismissText={translate("COMMON.Cancel")}
                confirmText={translate("INVENTORY.ITEM.Reopen")}
            >
                {translate("INVENTORY.ITEM.MESSAGES.ReopenItemConfirmText")}
            </WarningPopup>
        );
    }, [openPopup, closePopup, handleOnReopenItemClicked])




    const handleClickValidateCounts = useCallback(() => {
        if (!inventoryItem?.id || !locationID || !inventoryID) return;

        if (inventoryItem.isProblematic || inventoryItem.quantityCounted === 0)
            openPopup(<ValidateInvDocItemCountPopup
                locationID={"" + locationID}
                inventoryID={"" + inventoryID}
                inventoryItemID={"" + inventoryItem.id}
                hasZeroQuantity={inventoryItem.quantityCounted === 0}
                onCompletedOperations={() => {
                    getInventoryItemCounts();
                    getInventoryItemById();
                }} />);
        else
            openPopup(
                <WarningPopup
                    onDismissClicked={() => { closePopup(); }}
                    onConfirmClicked={handleOnValidateItemClicked}
                    dismissText={translate("COMMON.Cancel")}
                    confirmText={translate("INVENTORY.ITEM.Validate")}
                >
                    {translate("INVENTORY.ITEM.MESSAGES.ValidateItemConfirmText")}
                </WarningPopup>
            );
    }, [getInventoryItemById, getInventoryItemCounts, handleOnValidateItemClicked, inventoryID, locationID, inventoryItem?.id, inventoryItem?.isProblematic, inventoryItem?.quantityCounted, openPopup, closePopup])





    const handleClickCloseItem = useCallback(() => {
        openPopup(
            <WarningPopup
                onDismissClicked={() => { closePopup(); }}
                onConfirmClicked={handleOnCloseItemClicked}
                dismissText={translate("COMMON.Cancel")}
                confirmText={translate("INVENTORY.ITEM.Close")}
            >
                {translate("INVENTORY.ITEM.MESSAGES.CloseItemConfirmText")}
            </WarningPopup>
        );
    }, [handleOnCloseItemClicked, openPopup, closePopup])



    /*     const renderActionButtons = useMemo(() => {
            return windowResize > 768 ?
                <>
                    {((!inventoryStatus.isLoadingInventoryStatus && inventoryStatus.isInventoryClosed)) ?
                        <>
                            <ContextualMenu
                                items={[
                                    {
                                        label: translate("INVENTORY.POPUP.History"),
                                        navigateTo: "",
                                        icon: <HistoryIcon />, onClick: () => {
                                            openPopup(
                                                <ConsultHistoryLinesPopup
                                                    locationID={"" + locationID}
                                                    inventoryID={"" + inventoryID}
                                                    inventoryItemID={"" + inventoryItem?.id}
                                                    onCompletedOperations={getInventoryItemById}
                                                />
                                            );
                                        }
                                    }
                                ]}
                            >
                                <ScalableIconButton size={40} icon={<MoreActionsIcon />} />
                            </ContextualMenu>
                        </> : null
                    }
                    {(!inventoryStatus.isLoadingInventoryStatus && !inventoryStatus.isInventoryClosed) || (isInventoryInProgress && inventoryItem?.permissions?.canManageItem) ?
                        <>
                            <ContextualMenu
                                items={[
                                    {
                                        label: translate("INVENTORY.POPUP.EditDocumentSapId"),
                                        icon: <EditIcon />,
                                        navigateTo: "",
                                        isVisible: (inventoryItem?.isNew && !inventoryStatus.isLoadingInventoryStatus && !inventoryStatus.isInventoryClosed && hasAdminPermission),
                                        onClick: handleClickEditDocSapId
                                    },
                                    {
                                        label: translate("INVENTORY.ITEM.AddComment"),
                                        icon: <CommentsOutlineIcon />,
                                        navigateTo: "",
                                        isVisible: canAddNewComments,
                                        onClick: handleClickAddComment
                                    },
                                    {
    
                                        label: translate("INVENTORY.POPUP.History"),
                                        navigateTo: "",
                                        icon: <HistoryIcon />, onClick: () => {
                                            openPopup(
                                                <ConsultHistoryLinesPopup
                                                    locationID={"" + locationID}
                                                    inventoryID={"" + inventoryID}
                                                    inventoryItemID={"" + inventoryItem?.id}
                                                    onCompletedOperations={getInventoryItemById}
                                                />
                                            );
                                        }
                                    }
                                ]}
                            >
                                <ScalableIconButton size={40} icon={<MoreActionsIcon />} />
                            </ContextualMenu>
                        </> : null
                    }
                    <ConditionalRender if={canReopen}>
                        <Button type="secondary" className="button-yellow" text={translate("INVENTORY.ITEM.ReopenCounts")} onClick={handleClickReopenCounts} />
                    </ConditionalRender>
                    <ConditionalRender if={canValidate}>
                        <Button type="primary" text={translate("INVENTORY.ITEM.ValidateCounts")} onClick={handleClickValidateCounts} />
                    </ConditionalRender>
                    <ConditionalRender if={canClose}>
                        <Button type="primary" text={translate("INVENTORY.ITEM.CloseItem")}
                            onClick={handleClickCloseItem} />
                    </ConditionalRender>
                </> : <>
    
                    {(inventoryItem?.isNew && !inventoryStatus.isLoadingInventoryStatus && !inventoryStatus.isInventoryClosed) || (isInventoryInProgress && inventoryItem?.permissions?.canManageItem) ?
                        <>   <ContextualMenu
                            items={[
                                {
                                    label: translate("INVENTORY.POPUP.EditDocumentSapId"),
                                    icon: <EditIcon />,
                                    navigateTo: "",
                                    isVisible: (inventoryItem?.isNew && !inventoryStatus.isLoadingInventoryStatus && !inventoryStatus.isInventoryClosed),
                                    onClick: handleClickEditDocSapId
                                },
                                {
                                    label: translate("INVENTORY.ITEM.AddComment"),
                                    icon: <CommentsOutlineIcon />,
                                    navigateTo: "",
                                    isVisible: canAddNewComments,
                                    onClick: handleClickAddComment
                                }
                            ]}
                        >
                            <ScalableIconButton size={30} icon={<MoreActionsIcon />} />
                        </ContextualMenu>  </> : null}
                    <ConditionalRender if={canReopen}>
                        <IconButton type="secondary" className="button-yellow" icon={<ReopenIcon />}
                            onClick={handleClickReopenCounts} />
                    </ConditionalRender>
                    <ConditionalRender if={canValidate}>
                        <IconButton type="primary" icon={<ValidateIcon />}
                            onClick={handleClickValidateCounts} />
                    </ConditionalRender>
                    <ConditionalRender if={canClose}>
                        <IconButton type="primary" icon={<CloseIcon />}
                            onClick={handleClickCloseItem} />
                    </ConditionalRender>
                </>
        }, [canClose, canReopen, canValidate,
            handleClickCloseItem, handleClickValidateCounts,
            handleClickAddComment, handleClickEditDocSapId,
            handleClickReopenCounts,
            inventoryItem?.isNew, windowResize,
            inventoryStatus.isInventoryClosed, inventoryStatus.isLoadingInventoryStatus, isInventoryInProgress,
            inventoryItem?.permissions, getInventoryItemById, locationID, inventoryID, inventoryItem?.id, openPopup, canAddNewComments]); */



    const renderActionButtons = useMemo(() => {

        const isInventoryLoading = inventoryStatus.isLoadingInventoryStatus;
        const isInventoryClosed = inventoryStatus.isInventoryClosed;
        const canManageItem = inventoryItem?.permissions?.canManageItem;
        const isNewItem = inventoryItem?.isNew;

        const historyMenuItem = {
            label: translate("INVENTORY.POPUP.History"),
            navigateTo: "",
            icon: <HistoryIcon />,
            onClick: () => {
                openPopup(<ConsultHistoryLinesPopup locationID={"" + locationID} inventoryID={"" + inventoryID} inventoryItemID={"" + inventoryItem?.id} onCompletedOperations={getInventoryItemById} />);
            }
        };

        const editDocSapIdMenuItem = {
            label: translate("INVENTORY.POPUP.EditDocumentSapId"),
            icon: <EditIcon />,
            navigateTo: "",
            isVisible: isNewItem && !isInventoryLoading && !isInventoryClosed && hasAdminPermission,
            onClick: handleClickEditDocSapId
        };

        const addCommentMenuItem = {
            label: translate("INVENTORY.ITEM.AddComment"),
            icon: <CommentsOutlineIcon />,
            navigateTo: "",
            isVisible: canAddNewComments,
            onClick: handleClickAddComment
        };

        const menuItems = [editDocSapIdMenuItem, addCommentMenuItem, historyMenuItem];

        const renderMenu = (size: number) => (
            <ContextualMenu items={menuItems}>
                <ScalableIconButton size={size} icon={<MoreActionsIcon />} />
            </ContextualMenu>
        );

        const renderButtons = () => (
            <>
                <ConditionalRender if={canReopen}>
                    <Button type="secondary" className="button-yellow" text={translate("INVENTORY.ITEM.ReopenCounts")} onClick={handleClickReopenCounts} />
                </ConditionalRender>
                <ConditionalRender if={canValidate}>
                    <Button type="primary" text={translate("INVENTORY.ITEM.ValidateCounts")} onClick={handleClickValidateCounts} />
                </ConditionalRender>
                <ConditionalRender if={canClose}>
                    <Button type="primary" text={translate("INVENTORY.ITEM.CloseItem")} onClick={handleClickCloseItem} />
                </ConditionalRender>
            </>
        );

        const renderIcons = () => (
            <>
                <ConditionalRender if={canReopen}>
                    <IconButton type="secondary" className="button-yellow" icon={<ReopenIcon />} onClick={handleClickReopenCounts} />
                </ConditionalRender>
                <ConditionalRender if={canValidate}>
                    <IconButton type="primary" icon={<ValidateIcon />} onClick={handleClickValidateCounts} />
                </ConditionalRender>
                <ConditionalRender if={canClose}>
                    <IconButton type="primary" icon={<CloseIcon />} onClick={handleClickCloseItem} />
                </ConditionalRender>
            </>
        );

        return windowResize > 768 ?
            <>
                {(!isInventoryLoading && isInventoryClosed) ? renderMenu(40) : null}
                {((!isInventoryLoading && !isInventoryClosed) || (isInventoryInProgress && canManageItem)) ? renderMenu(40) : null}
                {renderButtons()}
            </>
            :
            <>
                {((isNewItem && !isInventoryLoading && !isInventoryClosed) || (isInventoryInProgress && canManageItem)) ? renderMenu(30) : null}
                {renderIcons()}
            </>;

    }, [canClose, canReopen, canValidate, handleClickCloseItem, handleClickValidateCounts,
        handleClickAddComment, handleClickEditDocSapId, handleClickReopenCounts, inventoryItem, windowResize,
        inventoryStatus, isInventoryInProgress, getInventoryItemById, locationID, inventoryID,
        openPopup, canAddNewComments, hasAdminPermission]);


    return (
        <PageLayout
            tabTitle={"Valmet - " + translate("INVENTORY.Inventory")}
            pageTitle={translate("INVENTORY.ITEM.InventoryItemCounts")}
            className="inventory-item-counts"
            showBreadcrumb
            actions={renderActionButtons}
        >

            {showFullScreenLoader ? <FullScreenLoader /> : null}

            <div className="item-info">
                <CardContainer className="item-data">
                    {renderItemDetailsHeader}
                    <Spacer mode="vertical" px="12" />
                    {renderCardContainerInfo}
                </CardContainer>
            </div>

            <Spacer mode="vertical" px="20" />
            <div className="subtitle">{translate("INVENTORY.ITEM.INFOANDFORM.Counts")}</div>
            <Spacer mode="vertical" px="12" />

            <ResponsiveDataTable
                className="counts-table"
                columnDefinitions={columns}
                items={inventoryItemCounts || []}
                totalitems={totalCounts || 0}
                paginationOptions={{ itemsPerPage: tableQueryOptions.pageLength ?? 0, itemsPerPageOptions: [10, 20, 30, 40, 50] }}
                currentpage={tableQueryOptions.page}
                isLoading={isLoading}
                onPageAndItemsChanged={onPageAndItemsChanged}
            ></ResponsiveDataTable>
        </PageLayout >
    );
}

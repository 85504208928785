import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { EditLocationGroupAccessPage } from "../features/admin/location-group-accesses/EditLocationGroupAccessPage";
import { FacilitiesHomePage } from "../features/admin/facilities/FacilitiesHomePage";
import { LocationsContainer } from "../features/admin/layout/LocationsContainer";
import { LocationsHome } from "../features/admin/locations/LocationsHomePage";
import { ModuleDefinition } from "../lib/infrastructure/modules/ModuleDefinition";
import { Permission } from '../models/api/gate/Permission';
import { RouteGuard } from "../lib/infrastructure/authorization/RouteGuard";
import { translate } from "../lib/infrastructure/i18n/InternationalizationService";
import { useEffect } from "react";
import { useRegisterBreadcrumbRoutes } from "../lib/infrastructure/navigation/hooks/useBreadcrumb";
import { AdminStorageLocationsPage } from "../features/admin/storages/AdminStorageLocationsPage";
import { MaterialsHomePage } from "../features/admin/materials/MaterialsHomePage";
import { PdaOperatorsHomePage } from "../features/admin/pda-storages/pages/PdaOperatorsHomePage";
import { StorageManagersPage } from "../features/admin/storage-managers/StorageManagersPage";
import { AdminStorageLocationDetailsPage } from "../features/admin/storages/AdminStorageLocationDetailsPage";
import { StorageManagerDetailsPage } from "../features/admin/storage-managers/StorageManagerDetailsPage";

const moduleDefinition: ModuleDefinition = {
  basePath: "/settings/locations",
  routes: [
    {
      name: () => translate("GLOBALADMIN.LOCATIONS.Locations"),
      pathPattern: "/",
    },
    {
      name: () => "##LOCATION##",
      pathPattern: "/:id",
    },
    {
      name: () => translate("GLOBALADMIN.FACILITIES.Facilities"),
      pathPattern: "/:id/facilities",
    },
    {
      name: () => translate("GLOBALADMIN.MATERIALS.Materials"),
      pathPattern: "/:id/materials",
    },
    {
      name: () => translate("GLOBALADMIN.OPERATORS.PdaOperators"),
      pathPattern: "/:id/pda-operators",
    },
    {
      name: () => translate("GLOBALADMIN.ACCESSGROUPS.AccessGroups"),
      pathPattern: "/:id/groupaccess",
    },
    {
      name: () => translate("INVENTORY.StoragePlural"),
      pathPattern: "/:id/storages",
    },
    {
      name: () => translate("GLOBALADMIN.STORAGEMANAGERS.StorageManagers"),
      pathPattern: "/:id/storage-managers",
    },
    {
      name: () => "##STORAGE##",
      pathPattern: "/:id/storages/:code",
    },
  ],
};

const permissions = [Permission.GLOBAL_ADMIN, Permission.LOCAL_ADMIN];

export function LocationsModule() {
  const registerBreadcrumbRoutes = useRegisterBreadcrumbRoutes();


  useEffect(() => {
    registerBreadcrumbRoutes(moduleDefinition);
  }, []);

  return (
    <RouteGuard permissions={permissions} >
      <Routes>
        <Route index element={<LocationsHome />} />
        <Route
          path=":id/*"
          element={
            <LocationsContainer>
              <Outlet />
            </LocationsContainer>}
        >
          <Route index element={<Navigate to="groupaccess" />} />
          <Route path="groupaccess" element={<EditLocationGroupAccessPage />} />
          <Route path="pda-operators" element={<PdaOperatorsHomePage />} />
          <Route path="storages" element={<AdminStorageLocationsPage />} />
          <Route path="storages/:code" element={<AdminStorageLocationDetailsPage />} />
          <Route path="facilities" element={<FacilitiesHomePage />} />
          <Route path="materials" element={<MaterialsHomePage />} />
          <Route path="storage-managers" element={<StorageManagersPage />} />
          <Route path="storage-managers/:email" element={<StorageManagerDetailsPage />} />

        </Route>

      </Routes>
    </RouteGuard>
  );
}

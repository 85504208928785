import { Optional, ValidatorFunction } from "../../lib/components/form/Form";
import { translate } from "../../lib/infrastructure/i18n/InternationalizationService";

export function validateBinFromStorageLocation(bins?: string[]): ValidatorFunction<Optional<string>> {
    return (value: string | undefined) => {

        if(bins?.length === 0) return null; 
    

        if (!value || value?.trim() === "")
            return translate("COMMON.FORM.VALIDATIONS.RequiredField");


        if(!bins?.map(item => item.toLowerCase()).includes(value.toLowerCase()))
            return translate("INVENTORY.ITEM.MESSAGES.InexistingStorageBin"); 
        
        return null;
    };
}


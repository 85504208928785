import { Optional, ValidatorFunction } from "../../lib/components/form/Form";

import { translate } from "../../lib/infrastructure/i18n/InternationalizationService";

export function validateRegex(regex: RegExp, translation: string): ValidatorFunction<Optional<string>> {

    return (value: string | undefined) => {
        if (!regex.test(value || "")) {
            return translate(translation);
        }
        return null;
    }
}


export function validateRegexOrEmpty(regex: RegExp, allowNull: boolean, translation: string): ValidatorFunction<Optional<string>> {

    return (value: string | undefined) => {
        if (allowNull && !value)
            return null;
        if (!regex.test(value || "")) {
            return translate(translation);
        }
        return null;
    }
}


/***************
 * Regex expressions
 **************/

export const regexOnlyPositiveIntegers = /^$|^[0-9]\d*$/;
export const regexDecimalNumber = /^-?\d+([\.,]\d+)?$/;
export const regexIntegerOrDecimals = /^(?!0+([.,]0+)?$)\d+([.,]\d+)?$/; 

export const positiveDecimalNumber = /^\d+([\.,]\d+)?$/;


import "./PdaOperatorsHomePage.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { AddPdaOperatorPopup } from "../popups/AddPdaOperatorPopup";
import { Button } from "../../../../lib/components/buttons/Button";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { DateTime } from "luxon";
import { ReactComponent as DeleteIcon } from "../../../../lib/assets/icons/remover.svg";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { ImportLoader } from "../../../../lib/components/loader/ImportLoader";
import { ImportPopup } from "../../../../lib/components/popup/ImportPopup";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { PdaOperator } from "../models/domain/PdaOperator";
import { PdaOperatorService } from "../services/PdaOperatorService";
import { PdaOperatorsQueryOptions } from "../models/api/PdaOperatorsQueryOptions";
import { ReactComponent as ResetPasswordIcon } from "../../../../lib/assets/icons/reset-password.svg";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../../lib/components/buttons/ScalableIconButton";
/* Page Icons*/
import { ReactComponent as SearchIcon } from "../../../../lib/assets/icons/search-icon.svg";
import { ReactComponent as AssociateManagerIcon } from "../../../../lib/assets/icons/correlation.svg";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useParams } from "react-router-dom";
import { useServiceCallPro2 } from "../../../../lib/hooks/useServiceCall";
import { ConfigurationProvider } from "../../../../lib/infrastructure/configuration/ConfigurationProvider";
import { ReactComponent as RemoveManagerAssociationIcon } from "../../../../lib/assets/icons/remove-correlation.svg";
import { Tooltip } from "../../../../lib/components/tooltip/Tooltip";
import { AssociateManagerToPdaOperatorPopup } from "../popups/AssociateManagerToPdaOperatorPopup";

const svc = new PdaOperatorService();


export function PdaOperatorsHomePage() {

    const { id: locationID } = useParams();

    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();

    const [uploadFileCall, uploadFileCallIsLoading] = useServiceCallPro2(svc, svc.createOperatosAsCSV);
    const [getPdaOperatorsCall, getPdaOperatorsCallIsLoading] = useServiceCallPro2(svc, svc.getPdaOperators);
    const [deletePdaOperatorCall, deletePdaOperatorCallIsLoading] = useServiceCallPro2(svc, svc.deleteOperator);
    const [resetPdaOperatorPassCall, resetPdaOperatorPassCallIsLoading] = useServiceCallPro2(svc, svc.resetPdaOperatorPassword);
    const [removeManagerAssociationCall, removeManagerAssociationCallIsLoading] = useServiceCallPro2(svc, svc.removeManagerAssociation);
    const [isLoadingImport, setIsLoadingImport] = useState(false);
    const [pdaOperators, setPdaOperators] = useState<PdaOperator[]>([]);
    const [totalItems, setTotalItems] = useState<number>(0);

    var searchFormControl = useFormControl<string>({});

    const [timer, setTimer] = useState<NodeJS.Timeout>();
    const [searchWord, setSearchWord] = useState<string>();


    const [currentpage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);


    useEffect(() => {
        getPdaOperators();
    }, [locationID, currentpage, itemsPerPage, searchWord]);




    // Timer para chamar a API, sempre que há uma mudança na search-box.
    useEffect(() => {
        if (timer) {
            clearTimeout(timer);
        }
        var responseTimeOut = setTimeout(() => {
            if (timer) {
                setCurrentPage(0);
                setSearchWord(searchFormControl.value);
            }
        }, 1000); // tempo de espera em milisegundos, antes de lançar o pedido à API.

        setTimer(responseTimeOut);
    }, [searchFormControl.value]);




    const getPdaOperators = useCallback(() => {

        if (!locationID) return;

        var queryParams: PdaOperatorsQueryOptions = {
            page: currentpage,
            pageLength: itemsPerPage,
            searchWord: searchWord
        }

        getPdaOperatorsCall(locationID, queryParams)
            .then((data) => {
                setPdaOperators(data.operators);
                setTotalItems(data.total);
            })
            .catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [getPdaOperatorsCall, locationID, openPopup, currentpage, itemsPerPage, searchWord, setPdaOperators, setTotalItems]);



    const handleFileImport = useCallback((file: File) => {
        if (!locationID) return;

        setIsLoadingImport(true);
        uploadFileCall(`${locationID}`, file)
            .then((_) => {
                setIsLoadingImport(false);
                getPdaOperators();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate(
                            "GLOBALADMIN.OPERATORS.ImportPdaOperatorsSuccess"
                        )}
                    />
                );
            })
            .catch(error => {
                setIsLoadingImport(false);
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationID, uploadFileCall, openPopup, getPdaOperators, createNotification]);



    const handleDeletePdaOperatorBtnClicked = useCallback((pdaOperatorId: number) => {

        if (!locationID) return;

        deletePdaOperatorCall(locationID, "" + pdaOperatorId)
            .then((_) => {
                closePopup();
                getPdaOperators();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("GLOBALADMIN.OPERATORS.NOTIFICATIONS.DeleteOperatorSuccessMessage")}
                    ></Notification>
                );
            })
            .catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationID, openPopup, createNotification, closePopup, deletePdaOperatorCall, getPdaOperators]);



    const handleResetPasswordBtnClicked = useCallback((pdaOperatorId: number) => {

        if (!locationID) return;

        resetPdaOperatorPassCall(locationID, "" + pdaOperatorId)
            .then((_) => {
                closePopup();
                getPdaOperators();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("GLOBALADMIN.OPERATORS.NOTIFICATIONS.ResetPasswordSuccessMessage")}
                    ></Notification>
                );
            })
            .catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationID, openPopup, createNotification, closePopup, resetPdaOperatorPassCall, getPdaOperators]);


    const handleRemoveManagerAssociationBtnClicked = useCallback((pdaOperatorId: number) => {

        if (!locationID) return;

        removeManagerAssociationCall(locationID, "" + pdaOperatorId)
            .then((_) => {
                closePopup();
                getPdaOperators();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("GLOBALADMIN.OPERATORS.NOTIFICATIONS.RemoveManagerAssociationSuccess")}
                    ></Notification>
                );
            })
            .catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationID, openPopup, createNotification, closePopup, removeManagerAssociationCall, getPdaOperators]);


    const handleOnPageAndItemsChanged = useCallback(
        (items: number, currentPage: number) => {
            setItemsPerPage(items);
            setCurrentPage(currentPage);
        },
        [setItemsPerPage, setCurrentPage]
    );



    const actionsButtons = useMemo(() => {
        return (
            <>
                <Button
                    text={translate("COMMON.Import")}
                    type="secondary"
                    onClick={() => openPopup(<ImportPopup onClickDownloadTemplateBtn={() =>

                        window.open(ConfigurationProvider.getConfiguration().App.BackendUrl + "/templates/VLMT_Inventory_PDA_Operators_Template_V2.xlsx", "_blank")}

                        onClickImportBtn={handleFileImport} />)}
                    className="import-pda-operators"
                />
                <Button
                    text={translate("COMMON.Create")}
                    type="primary"
                    onClick={() => {
                        if (locationID) {
                            openPopup(<AddPdaOperatorPopup locationID={locationID} onCompletedOperations={getPdaOperators} />);
                        }
                    }}
                    className="create-pda-operator"
                ></Button>
            </>);
    }, [handleFileImport, openPopup, locationID, getPdaOperators]);





    const columns: ColumnDefinition<PdaOperator>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => v.name,
                width: "25%",
                headerRender: (<>{translate("GLOBALADMIN.OPERATORS.INFOANDFORM.Name")}</>),
                columnKey: "loc-sap",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => v.storageManagerName,
                headerRender: (<>{translate("GLOBALADMIN.OPERATORS.INFOANDFORM.StorageManager")}</>),
                columnKey: "description",
                width: "25%",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => v.pass,
                headerRender: (<>{translate("GLOBALADMIN.OPERATORS.INFOANDFORM.Pass")}</>),
                columnKey: "description",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => v.lastPasswordResetDate ? DateTime.fromISO(v.lastPasswordResetDate).toFormat("dd/MM/yyyy HH'h'mm") : "-",
                headerRender: (<>{translate("GLOBALADMIN.OPERATORS.INFOANDFORM.LastPasswordReset")}</>),
                columnKey: "description",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => (
                    <Tooltip text={translate("GLOBALADMIN.OPERATORS.INFOANDFORM.ResetPdaOperatorPasswordTooltip")} >
                        <ScalableIconButton
                            icon={<ResetPasswordIcon />}
                            onClick={() => {
                                openPopup(
                                    <WarningPopup

                                        onDismissClicked={() => { closePopup(); }}
                                        onConfirmClicked={() => { handleResetPasswordBtnClicked(v.pdaOperatorId) }}
                                        dismissText={translate("COMMON.Cancel")}
                                        confirmText={translate("COMMON.Reset")}
                                    >
                                        {translate("GLOBALADMIN.OPERATORS.NOTIFICATIONS.ResetPasswordConfirmText", v.name ? v.name : "-")}
                                    </WarningPopup>
                                );
                            }}
                            size={30}
                        />
                    </Tooltip>
                ),
                width: "3%",
                columnKey: "stats",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => {
                    if (!v.storageManagerId) {
                        return (<Tooltip text={translate("GLOBALADMIN.OPERATORS.INFOANDFORM.AssociateStorageManagerTooltip")} >
                            <ScalableIconButton
                                icon={<AssociateManagerIcon />}
                                onClick={() => {
                                    openPopup(<AssociateManagerToPdaOperatorPopup
                                        locationID={"" + locationID}
                                        pdaOperatorID={v.pdaOperatorId}
                                        pdaOperatorName={v.name}
                                        onCompletedOperations={getPdaOperators}
                                    />
                                    );
                                }}
                                size={30}
                            />
                        </Tooltip>
                        )
                    }
                    else return (<Tooltip text={translate("GLOBALADMIN.OPERATORS.INFOANDFORM.RemoveManagerAssociationTooltip")} >
                        <ScalableIconButton
                            className="remove-manager-operator-association-icon"
                            icon={<RemoveManagerAssociationIcon />}
                            onClick={() => {
                                openPopup(
                                    <WarningPopup
                                        className="button-red"
                                        onDismissClicked={() => { closePopup(); }}
                                        onConfirmClicked={() => { handleRemoveManagerAssociationBtnClicked(v.pdaOperatorId) }}
                                        dismissText={translate("COMMON.Cancel")}
                                        confirmText={translate("COMMON.Remove")}
                                    >
                                        {translate("GLOBALADMIN.OPERATORS.NOTIFICATIONS.RemoveManagerAssociationConfirmText", v.name ? v.name : "-")}
                                    </WarningPopup>
                                );
                            }}
                            size={30}
                        />
                    </Tooltip>
                    )
                },
                width: "3%",
                columnKey: "stats",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => (
                    <Tooltip text={translate("GLOBALADMIN.OPERATORS.INFOANDFORM.RemovePdaOperatorTooltip")} >
                        <ScalableIconButton
                            icon={<DeleteIcon />}
                            onClick={() => {
                                openPopup(
                                    <WarningPopup
                                        className="button-red"
                                        onDismissClicked={() => { closePopup(); }}
                                        onConfirmClicked={() => { handleDeletePdaOperatorBtnClicked(v.pdaOperatorId) }}
                                        dismissText={translate("COMMON.Cancel")}
                                        confirmText={translate("COMMON.Remove")}
                                    >
                                        {translate("GLOBALADMIN.OPERATORS.NOTIFICATIONS.DeleteOperatorConfirmText", v.name ? v.name : "-")}
                                    </WarningPopup>
                                );
                            }}
                            size={30}
                        />
                    </Tooltip>
                ),
                width: "3%",
                columnKey: "stats",
                isSortable: false,
            },

        ], [openPopup, closePopup, handleDeletePdaOperatorBtnClicked, handleResetPasswordBtnClicked,
             handleRemoveManagerAssociationBtnClicked, getPdaOperators, locationID]);




    return (
        <PageLayout
            tabTitle={`${translate("INVENTORY.Inventory")} + ${translate("GLOBALADMIN.OPERATORS.PdaOperators")}`}
            pageTitle={translate("GLOBALADMIN.OPERATORS.PdaOperators")}
            className="admin-pda-operators"
            showBreadcrumb
            actions={<>{actionsButtons}</>}
        >

            {uploadFileCallIsLoading || deletePdaOperatorCallIsLoading || resetPdaOperatorPassCallIsLoading || removeManagerAssociationCallIsLoading ? <FullScreenLoader /> : null}

            <div className="command-bar">
                <FormFieldTextInput
                    formControl={searchFormControl}
                    icon={<SearchIcon />}
                    placeholder={translate("COMMON.FORM.SearchPlaceholder")}
                    className="search-box"
                />
                {isLoadingImport ? <ImportLoader text={translate("GLOBALADMIN.OPERATORS.ImportLoading")} /> : null}
            </div>

            <ResponsiveDataTable
                columnDefinitions={columns}
                items={pdaOperators || []}
                totalitems={totalItems}
                paginationOptions={{
                    itemsPerPage: itemsPerPage,
                    itemsPerPageOptions: [10, 20, 30, 40, 50],
                }}
                currentpage={currentpage}
                onPageAndItemsChanged={handleOnPageAndItemsChanged}
                isLoading={getPdaOperatorsCallIsLoading}
            ></ResponsiveDataTable>
        </PageLayout >
    );
}

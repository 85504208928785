

import { Loader } from "./Loader";

export function InputLoader() {

    return (
        <div className="small-copy import-loading"><Loader /> </div>
    );
}

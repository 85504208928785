import { useCallback, useState } from 'react';

import { AllValid } from "../../../../common/validators/ValidateFormControls";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextArea } from "../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { InventoriesService } from '../../services/InventoriesService';
import { InventoryItemCreateDTO } from "../../models/dtos/InventoryItemDTOs/InventoryItemCreateDTO";
import { InventoryItemDetailsDTO } from '../../models/dtos/InventoryItemDTOs/InventoryItemDetailsDTO';
import { InventoryItemsService } from "../../services/InventoryItemsService";
import { MobileInventoryService } from '../../services/MobileInventoryService';
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../lib/utils/TrimString";
import { useClosePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useServiceCallPro2 } from '../../../../lib/hooks/useServiceCall';
import { validateEmptyStrings } from "../../../../lib/validators/ValidateEmptyStrings";

const inventorySvc = new InventoriesService();
const inventoryItemsSvc = new InventoryItemsService();
const mobileSvc = new MobileInventoryService();
function replaceCommaByPoint(value: string | undefined) {
    if (!value) return null;
    if (value?.includes(','))
        return trimString(value.replace(',', '.'));
    return trimString(value);
}

interface INewInventoryItemsPopupProps {
    locationID: string;
    inventoryID: string;
    inventoryName?: string;
    onCompletedOperations: (res: InventoryItemDetailsDTO) => void;

    materialNumber: string;
    materialDesc: string;
    storageCode: string;
    batch?: string;
    bin?: string;
}


export function CreateInventoryItemPopup(props: INewInventoryItemsPopupProps) {

    const closePopup = useClosePopup();
    const [isLoading, setIsLoading] = useState<boolean>(false);


    const [getAllItems, getAllItemsIsLoading] = useServiceCallPro2(inventoryItemsSvc, inventoryItemsSvc.getInventoryItems);


    const unitsControl = useFormControl<string>({
        validators: [validateEmptyStrings()],
        enableAutoValidate: true,
    });

    const commentsControl = useFormControl<string>({});




    const handleCreateClicked = useCallback(() => {

        if (!props.locationID || !props.inventoryID) return;

        if (!AllValid(unitsControl.validate())) return;

        getAllItems(props.locationID, props.inventoryID, undefined).then(res => {
            var target = res.items.find(m => m.materialNumber === props.materialNumber);

            var requestDto: InventoryItemCreateDTO = {
                sAPInventoryDocId: "",
                materialNumber: props.materialNumber,
                materialDescription: target?.materialDescription ?? props.materialDesc,
                batch: props.batch,
                storage:props.storageCode,
                units: unitsControl.value ?? "",
                quantitySAP:0,
                bin: props.bin,
                comments: commentsControl.value ?? ""
            }

            return inventoryItemsSvc.addInventoryItem(props.locationID, props.inventoryID, requestDto)
        }).then((res) => {
            setIsLoading(false);
            props.onCompletedOperations(res);
            closePopup();
        }).catch((error) => {
            //TODO: Handle
        })



    }, [props.locationID, props.inventoryID, unitsControl.validate, unitsControl.value, commentsControl.value,
        setIsLoading, props.onCompletedOperations, closePopup]);



    return (
        <PopupContainer className="popup-add-inventory-item">
            {isLoading ? <FullScreenLoader /> : null}
            <PopupHeader
                title={translate("INVENTORY.ITEM.CreateInventoryItem")}
                subtitle={props.inventoryName ? translate("INVENTORY.Inventory") + ": " + props.inventoryName : ""}
            />
            <PopupContent>
                <FormContainer>


                    <FormSection>


                        <FormFieldTextInput
                            formControl={unitsControl}
                            label={translate("INVENTORY.ITEM.INFOANDFORM.Unit")}
                            placeholder={translate("INVENTORY.ITEM.INFOANDFORM.UnitPlaceholder")}
                        />
                    </FormSection>
                    <FormFieldTextArea
                        formControl={commentsControl}
                        label={translate("INVENTORY.ITEM.INFOANDFORM.Comments")}
                        placeholder={translate("INVENTORY.ITEM.INFOANDFORM.CommentsPlaceholder")}
                    />
                </FormContainer>
                <Spacer mode={"vertical"} px="30"></Spacer>
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Create"),
                            type: "primary",
                            onClick: handleCreateClicked
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
